import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import logo from "../../assets/img/logo.svg";
import './index.css';

const SidebarLink = styled.div`
display: flex;
color: #000;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 55px;
text-decoration: none;
font-size: 18px;
border-left: 4px solid transparent;

&:hover {
	background: var(--secondary-clr);
	border-left: 4px solid #252831;
	cursor: pointer;
    
    .menu-div{
        color: #fff;
    }
}
`;

const SidebarLabel = styled.span`
margin-left: 13px;
font-size:14px;
text-decoration: none;
`;

const DropdownLink = styled.div`
background: #fff;
height: 60px;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
color: #000;
font-size: 18px;

&:hover {
	cursor: pointer;
}
`;

const SubMenu = ({ item, sidebarHandler }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);
    return (
        <>
            <SidebarLink onClick={item.subNav && showSubnav}>
                <div className="menu-div">
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </SidebarLink>
            {subnav &&
                item.subNav.map((item, index) => {
                    return (
                        <DropdownLink onClick={() => sidebarHandler(item.path)} key={index}>
                            {item.icon}
                            <SidebarLabel>{item.title}</SidebarLabel>
                        </DropdownLink>
                    );
                })}
        </>
    );
};
export const SidebarData = [
    {
        title: "Card Dashboard",
        path: "#",
        icon: <AiIcons.AiFillAccountBook />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Group Card Created",
                path: "grouporder",
                //icon: <IoIcons.IoIosPaper />,
            },
            {
                title: "Group Card Received",
                path: "group-card-receive",
                // icon: <IoIcons.IoIosPaper />,
            },
        ],
    },
    // {
    //     title: "Wish Board",
    //     path: "#",
    //     icon: <IoIcons.IoIosClipboard />,
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,
    //     subNav: [
    //         {
    //             title: "Wish Board Orders",
    //             path: "wishboardOrder",
    //             //icon: <IoIcons.IoIosPaper />,
    //             cName: "sub-nav",
    //         },
    //         {
    //             title: "Wish Board Received",
    //             path: "wishboardreceive",
    //             //icon: <IoIcons.IoIosPaper />,
    //             cName: "sub-nav",
    //         },
    //     ],
    // },
    {
        title: "Event Calendar",
        path: "#",
        icon: <FaIcons.FaCalendarAlt />,

        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                title: "All users",
                path: "userlist",
            },
            {
                title: "Add user",
                path: "adduser",
            },
        ],
    },
    {
        title: "Account Settings",
        path: "#",
        icon: <FaIcons.FaUser />,

        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                title: "Profile",
                path: "userprofile",
                //icon: <IoIcons.IoIosPaper />,
            },
            {
                title: "Change Password",
                path: "changepassword",
                //icon: <IoIcons.IoIosPaper />,
            },
            {
                title: "Delete Account",
                path: "deleteaccount",
                // icon: <IoIcons.IoIosPaper />,
            },
        ],
    },
    


];

const SidebarLogo = styled(Link)`
margin-left: 2rem;
font-size: 2rem;
height: 80px;
display: flex;
justify-content: flex-start;
align-items: center;
`;

const SidebarNav = styled.nav`
background: #fff;
width: 300px;
height: 100vh;
display: flex;
justify-content: center;
position: fixed;
top: 0;
left: 0;
transition: all 0.5s ease;
z-index: 10;
padding: 30px 0;
box-shadow: 0 1rem 3rem rgba(0,0,0,0.1);
transform: translateX(-100%);
overflow-y: auto;
`;

const SidebarWrap = styled.div`
width: 100%;
`;

const Sidebar = ({ sidebarHandler, ishow }) => {
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
    return (
        <>
            <IconContext.Provider value={{ color: "#000" }}>
                <SidebarNav sidebar={sidebar} className={`profile_sidebar ${ishow ? 'Show' : ''}`}>
                    <SidebarWrap>
                        <SidebarLogo to="/">
                            <img src={logo} alt="Egroup Greetings" />
                        </SidebarLogo>
                        {SidebarData.map((item, index) => {
                            return <SubMenu sidebarHandler={sidebarHandler} item={item} key={index} />;
                        })}
                        <SidebarLink>
                            <Link to="/contact" className="menu-div d-block">
                                <FaIcons.FaEnvelope />
                                <SidebarLabel>Contact Us</SidebarLabel>
                            </Link>
                        </SidebarLink>
                        <SidebarLink  onClick={() => sidebarHandler('logout')}>
                        <div className="menu-div  d-block">
                                <FaIcons.FaLock />
                                <SidebarLabel>Logout</SidebarLabel>
                            </div>
                        </SidebarLink>
                       
                    </SidebarWrap>
                </SidebarNav>
            </IconContext.Provider>
        </>
    );
};

export default Sidebar;
