import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './index.css';

const PaymentFailed = () => {
    return (
        <div className='message-wrapper payment-parent'>
            <div class="wrapperAlert failed">
                <div class="contentAlert">
                    <div class="topHalf">
                        <p>
                            <svg className='closed-icon' width="100" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M256 0C114.616 0 0 114.614 0 255.995C0 397.386 114.616 512 256 512C397.384 512 512 397.386 512 256.005C512 114.614 397.384 0 256 0ZM365.786 332.599C374.951 341.764 374.951 356.623 365.786 365.788C356.62 374.954 341.761 374.954 332.596 365.788L255.998 289.192L179.401 365.788C170.235 374.954 155.38 374.954 146.214 365.788C137.045 356.623 137.045 341.768 146.214 332.599L222.808 256.003L146.214 179.41C137.049 170.245 137.049 155.386 146.214 146.221C155.38 137.056 170.235 137.056 179.401 146.221L256.001 222.817L332.598 146.221C341.764 137.056 356.623 137.056 365.788 146.221C374.953 155.386 374.954 170.245 365.788 179.41L289.188 256.003L365.786 332.599Z" fill="white" />
                            </svg>
                        </p>
                        <h1>Payment Failed</h1>
                        <ul class="bg-bubbles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentFailed;