import React,{useEffect} from "react";
import { Col, Container, Row, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Testimonial from "../../componants/Testimonial";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BlogCard from "../Blog/BlogCard";
import {Helmet} from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";
import Header from "../../componants/Header";
import Footer from "../../componants/Footer";
import { getWishList } from "../../actions";
import "./index.css"
import ProductCard from "../../componants/ProductCard";
import EmptyState from "../../componants/EmptyState";

const WishList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const WishListPost = useSelector((state) => state.wishlistpost.data);
    useEffect(() => {
        dispatch(getWishList(1));
    }, []);
    
    // const handleCardClick = (id) => {
    //     navigate(`/create/${id}`)
    // }
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.Wishboard_Title}</title>
            </Helmet>
            <section className="category-listing-area">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Row className="g-md-4">
                                {
                                    WishListPost.data?.length > 0 ?(<>
                                    {WishListPost.data.map((elem, index) => (
                                    <Col md={6} lg={3} key={index}>
                                        <ProductCard id={elem.post_id} cardid={elem.card_id} className={'wish-card'} image={elem.image} title={elem.title} description={elem.short_description} category={elem.category} auther={elem.user} link={'/blog-details'} />
                                    </Col>
                                ))}
                                    </>)
                                    :(<EmptyState />)
                                }
                                
                            </Row>
                        </Col>
                        {
                                            WishListPost?.data?.length > 0 && (<>
                                                    {
                                parseInt(WishListPost.total) > parseInt(WishListPost.limit) * parseInt(WishListPost.pageno) ? (
                                    <div className="text-center mt-5">
                                    <Button className="btn btn-lg btn-primary" onClick={()=>dispatch(getWishList(WishListPost.pageno+1))}>Load More</Button>
                                </div>
                                ):null
                            }
                                                    </>
)}
                    </Row>
                </Container>
            </section>
            <Testimonial />

        </>
    );
}

export default WishList;