import { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Modal, Row } from "react-bootstrap";
import "./index.css";
import { toast } from "react-toastify";
import { useRef } from "react";

function ImageCrop(props) {
  const [image, setImage] = useState("#");
  const { setCropData, ...rest } = props;
  const [cropper, setCropper] = useState();
  const [imageload, setImageLoad] = useState(false);
  const [imagetype,setImageType] = useState({name:"none",type:"none"})

 function restrictFileSize(file, restriction) {
    var filesize = (file.size / 1024 / 1024).toFixed(4); // MB
    if (filesize > restriction) {
      return false;
    } else {
      return true;
    }
  }
  const onChange = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {

      let isUploadable = restrictFileSize(e.target.files[0], 1);
      if (isUploadable) {
        const originalFile = e.target.files[0];
      const originalFileName = originalFile.name;
      const originalFileType = originalFile.type;
      setImageType({name:originalFileName,type:originalFileType});
        let files;
        if (e.dataTransfer) {
          files = e.dataTransferfiles;
        } else if (e.target) {
          files = e.target.files;
        }
        const reader = new FileReader(files);
        reader.onload = () => {
        //   console.log(reader.result)
          setImage(reader.result);
          setImageLoad(true);
        };
        reader.readAsDataURL(files[0]);
      } else {
        toast.error("File size Exceeded the limit. The limit for the file size is 1 MB");
      }
    }
  };

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      const croppedCanvas = cropper.getCroppedCanvas();
      
      // Get the original file name and type from the input element
      
  
      // Convert the cropped canvas data to a Blob
      croppedCanvas.toBlob((blob) => {
        if (blob) {
          // Create a File object from the Blob with the original name and type
          const croppedFile = new File([blob], imagetype.name, {
            type: imagetype.type,
          });
    
          // Now you can use croppedFile as your cropped image file
          setCropData(croppedFile);
    
          // Clear the image and close the modal
          setImageLoad(false);
        }
      }, imagetype.type); // Use the original file type
    }
  };

  const closeModal = () => {
    if (imageload) {
      setImageLoad(false);
      setImage("#");
      document.getElementsByName("profilePic").blur();
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };
  const buttonText = imageload ? 'Change Image' : 'Upload Image';

  return (
    <div>
      <Button variant="primary" className="w-100" onClick={handleButtonClick}>
      {buttonText}
      </Button>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/png, image/jpeg"
        type="file"
        onChange={(e) => onChange(e)}
        {...rest}
      />
      {Modal ? (
        <Modal show={imageload} onHide={closeModal} centered size="lg">
 <Modal.Body className="section-title-area">
          <div className="image-cropper-box">
            <Cropper
              style={{ height: "100%", width: "100%" }}
              aspectRatio={3/4}
              initialAspectRatio={3/4}
              preview=".img-preview"
              dragMode={"crop"}
              src={image}
              cropBoxResizable={false}
              background={false}
              autoCropArea={1}
              movable={false}
              rotatable={false}
              zoomable={false}
              zoomOnTouch={false}
              zoomOnWheel={false}
              responsive={false}
              scalable={false}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>
          <center className="info-text">
            <strong>Drag to Move</strong>
          </center>
          </Modal.Body>
          <Modal.Footer>
          <Row className="justify-content-end m-0 mt-2">
      <Button id="crop_image" onClick={getCropData} className={"crop-button"}>CROP IMAGE</Button>
    </Row>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}

export default ImageCrop;
