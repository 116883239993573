import React, { useEffect, useState } from "react";
import "./index.css";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EditCard, getEditCardList } from "../../actions";

import { validateEmail, validateGST } from "../../utilities";
import { toast } from "react-toastify";
import TimezoneSelect from "react-timezone-select";

const EditFullCard = ({ handleEdit, editData }) => {
	const editload = useSelector((state) => state.ordercarddetails.iseditload);
	const editdata = useSelector((state) => state.ordercarddetails.editorder);
	const dispatch = useDispatch();
	const [update, setIsUpdate] = useState(false);
	const [formData, setFormData] = useState({});
	const currentDate = new Date().toISOString().split("T")[0];
	const [selectedTimezone, setSelectedTimezone] = useState(
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	const handleEditForm = (type, data) => {
		// console.log(type, data);
		setFormData({ ...formData, [type]: data });
	};
	const handleSuccess = () => {
		handleEdit();
		//go back to listing page
	};

	const handleFormValues = () => {
		let isnotvalid = false;
		// console.log(formData);
		Object.keys(formData).map((key, index) => {
			let tempData = editData;
			let tempArray = [
				"recieveremail",
				"recieverfname",
				"recieverlname",
				"senderfname",
				"senderlname",
				"deliverytimetype",
			];
			if (tempArray.includes(key)) {
				if (
					formData[key] === null ||
					formData[key] === "" ||
					formData[key] === undefined
				) {
					toast.error(`${key} cannot be empty`);
					isnotvalid = true;
				}
			}
		});
		if (formData["deliverytimetype"] === "1") {
			if (formData["date"] === "NaN-NaN-NaN" || formData["time"] === "" || formData["timezone"] === "") {
				toast.error(`date or time cannot be empty`);
				isnotvalid = true;
			}
		}
		if (isnotvalid) {
			return false;
		} else {
			return true;
		}
	};
	const handleSubmit = () => {
		// console.log("triggered");
		let isFilled = handleFormValues();
		if (isFilled) {
			const emailValid = validateEmail(formData.recieveremail);
			if (emailValid) {
				// console.log(formData.gst_number);
				if (formData.gst_number !== undefined && formData.gst_number !== null) {
					const gstValid = validateGST(formData?.gst_number);
					if (gstValid) {
						setIsUpdate(true);
						dispatch(EditCard(formData, handleSuccess));
					} else {
						toast.error("Not a valid GSTIN");
					}
				} else {
					delete formData.gst_number;
					setIsUpdate(true);
					dispatch(EditCard(formData, handleSuccess));
				}
			} else {
				toast.error("Email not valid");
			}
		}
	};
	useEffect(() => {
		if (editData !== null || editData !== undefined) {
			dispatch(getEditCardList(editData.code, editData.customer_id));
		}
	}, [editData]);
	useEffect(() => {
		if (editdata !== null || editdata !== undefined) {
			setFormData(editdata);
		}
	}, [editdata]);
	if (editload) {
		return <Spinner />;
	} else {
		return (
			<>
				<div className="editcard-area">
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="account-setting account-box-design">
									<div className="d-flex justify-content-end">
										<Button
											type="button"
											className="mt-3 home_btn"
											onClick={() => handleEdit()}
										>
											Back
										</Button>
									</div>
									<h3 class="mb-4 d-block text-center">
										Edit CardInfo - #5613513
									</h3>

									<Form id="AddUserForm" className="row account-form">
										<div className="col-md-12">
											<h5 class="mb-3">Sender Info</h5>
										</div>
										<div className="col-md-6">
											<Form.Group className="mb-3">
												<Form.Label>Sender FirstName</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter Your First Name"
													value={formData?.senderfname}
													onChange={(e) =>
														handleEditForm("senderfname", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										<div className="col-md-6">
											<Form.Group className="mb-3">
												<Form.Label>Sender LastName</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter Your Last Name"
													value={formData?.senderlname}
													onChange={(e) =>
														handleEditForm("senderlname", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										<div className="col-md-12">
											<h5 class="mb-3">Reciver Info</h5>
										</div>
										<div className="col-lg-4">
											<Form.Group className="mb-3">
												<Form.Label>Reciver FirstName</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter Your First Name"
													value={formData?.recieverfname}
													onChange={(e) =>
														handleEditForm("recieverfname", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										<div className="col-lg-4">
											<Form.Group className="mb-3">
												<Form.Label>Reciver LastName</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter Your Last Name"
													value={formData?.recieverlname}
													onChange={(e) =>
														handleEditForm("recieverlname", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										<div className="col-lg-4">
											<Form.Group className="mb-3">
												<Form.Label>Recirver Email</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter Your Email"
													value={formData?.recieveremail}
													onChange={(e) =>
														handleEditForm("recieveremail", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										<div className="col-lg-12">
											<Form.Group className="mb-4 mt-4">
												<Form.Check
													inline
													label="send it instantly"
													name="group1"
													type={"radio"}
													id={`inline-radio-1`}
													value={0}
													checked={formData?.deliverytimetype === "0"}
													onChange={(e) =>
														handleEditForm("deliverytimetype", e.target.value)
													}
												/>
												<Form.Check
													inline
													label="send it later"
													name="group1"
													type={"radio"}
													id={`inline-radio-2`}
													value={1}
													checked={formData?.deliverytimetype === "1"}
													onChange={(e) =>
														handleEditForm("deliverytimetype", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										{formData?.deliverytimetype === "1" && (
											<>
												<div className="col-md-12">
													<h5 className="mb-3">Time Info</h5>
												</div>
												<div className="col-lg-4">
													<Form.Group className="mb-3">
														<Form.Label>Date</Form.Label>
														<Form.Control
															type="date"
															value={formData?.date}
															min={currentDate}
															onChange={(e) =>
																handleEditForm("date", e.target.value)
															}
														/>
													</Form.Group>
												</div>
												<div className="col-lg-4">
													<Form.Group className="mb-3">
														<Form.Label>Time</Form.Label>
														<Form.Control
															type="time"
															placeholder="Enter Time"
															value={formData?.time}
															onChange={(e) =>
																handleEditForm("time", e.target.value)
															}
														/>
													</Form.Group>
												</div>
												<div className="col-lg-4">
													<Form.Group className="mb-3">
														<Form.Label>Select timezone</Form.Label>
														<TimezoneSelect
															value={formData.timezone !== "" ? formData.timezone:selectedTimezone}
															onChange={(e)=>handleEditForm("timezone",e.value)}
														/>
													</Form.Group>
												</div>
											</>
										)}
										<div className="col-md-12">
											<h5 class="mb-3">Tax Info</h5>
										</div>
										<div className="col-md-4">
											<Form.Group className="mb-3">
												<Form.Label>Gst Number (Optional)</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter GST"
													value={formData?.gst_number}
													onChange={(e) =>
														handleEditForm("gst_number", e.target.value)
													}
												/>
											</Form.Group>
										</div>
										<Col lg={12}>
											<div className="d-flex justify-content-center">
												<Button
													type="button"
													className="mt-3 home_btn"
													onClick={() => handleSubmit()}
													disabled={
														JSON.stringify(editdata) ===
														JSON.stringify(formData)
													}
												>
													Update
												</Button>
											</div>
										</Col>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
};

export default EditFullCard;
