import { Button, Col, Row, Container, NavLink } from 'react-bootstrap'
import React, { useEffect } from 'react'
import CardText from '../CreateCard/CardText'
import { useState } from 'react'
import Giphy from '../CreateCard/Giphy'
import GiphySticker from '../CreateCard/GiphySticker'
import GiphyAttr from '../../assets/img/powered_by_giphy.gif';
import { handleSignCard } from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import * as FaIcons from "react-icons/fa";
import logo from "../../assets/img/logo.svg";
import ImageCrop from "../../componants/ImageCropper";
function SignCardEdit({isSender, show, onHide, code,from,id,mail,handleSendCard}) {
    const [media, setMedia] = useState("Message");
    const issignload = useSelector(state =>state.ordercarddetails.isSign);
    const [mediaData, setMediaData] = useState({
        message: "",
        fontsize: 20,
        fontfamily: "Arial, sans-serif",
        color: "#000000",
        media: "none",
        signerName:"",
        signerEmail: ""
    });
    const dispatch = useDispatch();
    const handleMedia = (type) => {
        if (type !== media) {
            // if(mediaData.media !== "none"){
            //     setMediaData({...mediaData,media:"none"})
            // }
            // console.log(typeof(mediaData.media))
            setMedia(type)
        }

    }
    const handleSelect = (type, value) => {
        if (type !== undefined) {
            setMediaData({ ...mediaData, [type]: value })
        }
    }


    useEffect(()=>{
if(isSender && from){
    // console.log("yo",from)
    setMediaData({ ...mediaData, signerName: from,signerEmail:localStorage.getItem("email") })
}
    },[isSender,from])
    const handleImage = (val) =>{
        // console.log(val)
        handleSelect("media",val)
    }
    return (
        // <Modal show={show} size="xl" onHide={onHide} centered>
        //     <Modal.Body className="section-title-area ">
        //         <h2 className="text-center mb-4"><span className="title-design position-relative">Signature</span> Card</h2>
                <Container className='mt-4'>
                    <Row>
                        <Col lg={8}>
                            <ul className='gif-tab-buttons'>
                                <li>
                                    <button type="button" className={`${media === 'Message' && 'active'}`} onClick={() => handleMedia("Message")}>
                                        Your Message
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={`${media === 'Gif' && 'active'}`} onClick={() => handleMedia("Gif")}>
                                        Add Gif
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={`${media === 'Sticker' && 'active'}`} onClick={() => handleMedia("Sticker")}>
                                        Add Sticker
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={`${media === 'Image' && 'active'}`} onClick={() => handleMedia("Image")}>
                                        Add Image
                                    </button>
                                </li>
                            </ul>
                            {/* <NavLink href="https://giphy.com/" target="_blank" className='giphy-attr'><img src={GiphyAttr} alt='Powered By Giphy' /></NavLink> */}

                            {
                                media === "Gif" ? (<Giphy handleSelect={handleSelect} data={mediaData} />) : media === "Message" ? (<CardText handleSelect={handleSelect} data={mediaData} isSender={isSender}/>) : media === "Sticker" ? (<GiphySticker handleSelect={handleSelect} data={mediaData} />):(<>
                                {/* <input type="file" onChange={(e)=>handleSelect("media",e.target.files[0])} /> */}
                                <ImageCrop  cropData={mediaData.media} setCropData={handleImage} id="bannerImage" />
                                </>)
                            }
                        </Col>
                        <Col lg={4}>
                            <h4 className='text-center'>Preview</h4>

                            <div className='image-preview-box'>
                                {mediaData.media !== "none" ? (
                                    <div className='previiew-image-edit'>

                                        <img alt="img" src={typeof(mediaData.media) === 'object' ? URL.createObjectURL(mediaData.media) :mediaData.media} />
                                        <button type="button" className="p-image-delete" onClick={() => handleSelect("media", "none")}>
                                        <FaIcons.FaTrashAlt />
                                        </button>
                                    </div>
                                ):(
                                    <img  alt="def-img" src={logo} />
                                )}
                                <p className='mb-0' style={{ fontFamily: mediaData.fontfamily, fontSize: mediaData.fontsize, color: mediaData.color }}>
                                    {mediaData.message === ""? "Enter your wish" : mediaData.message}
                                </p>
                                <p className='text-end mb-0' style={{ fontFamily: mediaData.fontfamily, fontSize: mediaData.fontsize, color: mediaData.color }}>
                                   ~{mediaData?.signerName === ""? "Enter your name" : mediaData.signerName}
                                </p>
                                {/* <p className='messagecontent'>Happy Birthday Shashi</p> */}
                                {/* <p className='writercontent text-end mb-2'>~ Ravi</p> */}
                                
                            </div>

                        </Col>
                        <Col className='col-12'>
                            <div className='d-flex justify-content-end'>
                                <Button type="button" className="mt-4 btn-danger active" disabled={issignload} onClick={() => handleSendCard(mediaData,code,id,"sign",onHide)}>
                                    {issignload ? "Saving" : "Save"}
                                </Button>
                                {
                                    !isSender && (
                                        <Button type="button" className="mt-4 mx-4 btn-danger" onClick={() => onHide()}>
                                        Back
                                    </Button>
                                    )
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>

        //     </Modal.Body>
        // </Modal>
    )
}

export default SignCardEdit