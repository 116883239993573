import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ReactSelect from "react-select";
// import { Link } from "react-router-dom";
// import CardImg from '../../assets/img/birthday-card.png';
// import { useDispatch, useSelector } from "react-redux";
// import { getAllCountries, getAllTimezones } from "countries-and-timezones";
// import { getTimeZoneList } from "../../actions";
import TimezoneSelect from "react-timezone-select";
import { toast } from "react-toastify";

const StepTwo = ({
	register,
	errors,
	handleStep,
	isLater,
	getValues,
	setError,
    setValue
}) => {
	// console.log(Intl.DateTimeFormat().resolvedOptions().timeZone, "this val")

	const [selectedTimezone, setSelectedTimezone] = useState(
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	const handleTimezone = (value) => {
		// register("timezone", { required: true, value: value.value });
        setValue("timezone",value.value)
        setSelectedTimezone(value.value)
	};
	const currentDate = new Date().toISOString().split("T")[0];

	return (
		<>
			<Form.Group className="mb-4 mt-4 modal-form" defaultChecked={0}>
				<Form.Check
					inline
					label="Send it instantly"
					name="group1"
					type={"radio"}
					id={`inline-radio-1`}
					value={0}
					{...register("deliverytimetype", { required: true })}
					defaultChecked={true}
				/>
				<Form.Check
					inline
					label="Send it later"
					name="group1"
					type={"radio"}
					id={`inline-radio-2`}
					value={1}
					{...register("deliverytimetype", { required: true })}
				/>
				<p className="form-error-message mt-1">
					{errors.deliverytimetype?.message}{" "}
				</p>
			</Form.Group>
			{isLater === "1" && (
				<>
					<Form.Group className="mb-3 modal-form">
						<Form.Label>Select Date</Form.Label>
						<Form.Control
							type="date"
							min={currentDate}
							{...register("date", { required: true })}
						/>
						<p className="form-error-message mt-1">{errors.date?.message} </p>
					</Form.Group>
					<Form.Group className="mb-3 modal-form">
						<Form.Label>Select Time</Form.Label>
						<Form.Control
							type="time"
							placeholder="Enter Your Last Name"
							{...register("time", { required: true })}
						/>
						<p className="form-error-message mt-1">{errors.time?.message} </p>
					</Form.Group>
					<Form.Group className="mb-3 modal-form">
						<Form.Label>Select timezone</Form.Label>
						<TimezoneSelect
							isSearchable={true}
							value={selectedTimezone}
							onChange={handleTimezone}
						/>
						{/* <Form.Select {...register("timezone", { required: true })} >
                                <option>Open this select menu</option>
                                 {TimeZoneList.map((item, index) => {
                                    return (
                                        <option
                                            key={'country' + index}
                                            value={item.id}
                                        // selected={user.country === item.name}
                                        >
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </Form.Select> */}
						<p className="form-error-message mt-1">
							{errors.timezone?.message}{" "}
						</p>
					</Form.Group>
				</>
			)}
			<div className="continue-btn text-center mt-4">
				<Button className="btn btn-primary" onClick={() => handleStep("Step2")}>
					Continue
				</Button>
			</div>
		</>
	);
};

export default StepTwo;
