import { API_ACTIONS } from "../constants/actionConstant";

const initialState = {
    isLoadingBilldesk: false,
    err: null,
    data:null,
    isLoadingStripe:false,
    stripeData:null,
    errData:null
};

const paymentReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getPaymentStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getPaymentSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.ggetPaymentFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
        
            case API_ACTIONS.getStripeDetailsStarted:
            return {
                ...state,
                isLoadingStripe:true
            }
        case API_ACTIONS.getStripeDetailsSuccess:
            return {
                ...state,
                isLoadingStripe:false,
                stripeData:action.data
            }
        case API_ACTIONS.getStripeDetailsFailed:
            return {
                ...state,
                isLoadingStripe:false,
                errData:action.data
            }
            default:
                return state;
    }
};

export default paymentReducer;