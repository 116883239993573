import { API_ACTIONS } from "../constants/actionConstant";

const initialState = {
    isLoading: false,
    err: null,
    data:[],
    faqData:[],
    searchData:[],
    ownedPlans:[],
};

const faqReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.FetchFaqCategoriesStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.FetchFaqCategoriesSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.FetchFaqCategoriesFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }

            case API_ACTIONS.FetchFaqListStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.FetchFaqListSuccess:
            return {
                ...state,
                isLoading:false,
                faqData:action.data
            }
        case API_ACTIONS.FetchFaqListFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
            

            case API_ACTIONS.FetchFaqSearchStarted:
                return {
                    ...state,
                    isLoading:true
                }
            case API_ACTIONS.FetchFaqSearchSuccess:
                return {
                    ...state,
                    isLoading:false,
                    searchData:action.data
                }

                case API_ACTIONS.ClearFaqList:
                    return {
                        ...state,
                        isLoading:false,
                        searchData:[]
                    }

            case API_ACTIONS.FetchFaqSearchFailed:
                return {
                    ...state,
                    isLoading:false,
                    err:action.data
                }

            default:
                return state;
    }

};
export default faqReducer;