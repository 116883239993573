import React, { useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";
import { HashLink } from "react-router-hash-link";

import "./index.css";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { ClearFaqList, FetchFaqCategories, FetchFaqList } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import * as FaIcons from "react-icons/fa";
const Faq = () => {
	const faqCategories = useSelector((state) => state.faq.data);
	const faqList = useSelector((state) => state.faq.faqData);
	const searchList = useSelector((state) => state.faq.searchData);
	const dispatch = useDispatch();
	const [category, setCategory] = useState("");
    const [searchTerm,setSearchTerm] = useState("");
    const [isSearch,setisSearch] = useState(false);
	useEffect(() => {
		dispatch(FetchFaqCategories());
	}, []);
	const scrollWithOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -120;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
	};


	

    const handleOnSearch = (value) => {
        
        setSearchTerm(value);
          
        }
      
        const submitSearch = (value) =>{
          setisSearch(true);
  
          dispatch(FetchFaqList("search", value));
        }
        const handleClearSearch = () =>{
            setisSearch(false);
            setSearchTerm("");
            dispatch(ClearFaqList());
        }

	useEffect(() => {
		if (faqCategories?.categories?.length > 0) {
			const category_id = faqCategories?.categories[0].category_id;
            setCategory(faqCategories?.categories[0].name)
			dispatch(FetchFaqList("category", category_id));
		}
	}, [faqCategories]);

    const handleCategory = (name,id) =>{
        if(isSearch){
            handleClearSearch()
        }
        
        setCategory(name);
        // setCategory(parseInt(id))
         dispatch(FetchFaqList("category", id))
    }

	return (
		<>
			<Helmet>
				<title>{PAGE_TITLE.Faq_Title}</title>
			</Helmet>
			<section className="faq-area">
				<Container>
					<Row className="justify-content-center">
						<Col lg={8}>
							<div className="section-title-area text-center mb-5">
								<h2 className="mb-4">
									Frequently Asked{" "}
									<span className="heading-design position-relative">
										Questions
									</span>
								</h2>
								<p>
									A list of popular inquiries and helpful responses to assist
									users in understanding a product, service, or concept.
								</p>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center mb-5">
						<Col lg={6}>
                        <div className="serch-product-area mb-4">
                        <div className="search-wrapper">
                                    <input type="search" className="form-control" placeholder="search" value={searchTerm}  onChange={(e)=>handleOnSearch(e.target.value)} />
                                    {
                                        isSearch  ? <button className="icon-btn" type="button" onClick={()=>handleClearSearch()}>X</button>:<button type="submit" className="icon-btn" onClick={()=>submitSearch(searchTerm)}><FaIcons.FaSearch /></button>
                                    }
                                    
                                </div>
                                </div>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={4} xl={3}>
							<div className="hash-listing">
                            <ListGroup>
                                                                        
								{faqCategories?.categories?.map((elem) => {
									return (
                                        <ListGroupItem>
										<div className="link-list" onClick={()=>handleCategory(elem.name,elem.category_id)}>
											{elem.name}
                                            </div>
                                            </ListGroupItem>
										
									);
								})}
                                
                                </ListGroup>
							</div>
						</Col>
						<Col lg={8} xl={9}>
							{searchList?.faqs?.length > 0 ? (
								<section id="general" className="hash-section">
									<Row>
                                    <Col lg={12}>
											<h2>
												{searchTerm}
											</h2>
										</Col>
										{searchList?.faqs?.length > 0 ? (
											<>
												{searchList?.faqs?.map((elem) => {
													return (
														<Col lg={12}>
															<h4>{elem.question}?</h4>
															<p>{elem.answer}</p>
														</Col>
													);
												})}
											</>
										) : (
											<div>Search data unavailable</div>
										)}
									</Row>
								</section>
							) : (
								<section id="general" className="hash-section">
									<Row>
										<Col lg={12}>
											<h2>
												{category}
											</h2>
										</Col>
										{faqList?.faqs?.length > 0 ? (
											<>
												{faqList?.faqs?.map((elem) => {
													return (
														<Col lg={12}>
															<h4>{elem.question}?</h4>
															<p>{elem.answer}</p>
														</Col>
													);
												})}
											</>
										) : (
											<div>no dataavailable</div>
										)}
									</Row>
								</section>
							)}
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default Faq;
