import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SvgIcon from "./SvgIcon";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";

import shape from '../assets/img/light-grey-curve-line.svg';

const WhyUs = () => {
    return (
        <>
            <section className="whyus-area  position-relative">
                <div className="light-grey-wave-top"><img src={shape} alt="shape" /></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="section-title-area text-center mb-5">
                                <h2 className="mb-4">Why <span className="title-design position-relative">eGroup</span>  Greeting </h2>
                                {/* <p>eGroup Greeting may use one-line wish titles and content for formatting, aesthetics, and space limitations.</p> */}
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-lg-5">
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <AiIcons.AiOutlineFieldTime />
                                </div>
                                <h3 className="mb-3">Save time </h3>
                                <p>No hassle of running from shop to shop or Desk to Desk, cabin coordination worries with team.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <GiIcons.GiClick />
                                </div>
                                <h3 className="mb-3">Convenient </h3>
                                <p>Manage everything on the go from any place. One can add heartfelt wishes with just a few clicks from anywhere, anytime.</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <MdIcons.MdOutlineFastForward />
                                </div>
                                <h3 className="mb-3">Quick and Effortless</h3>
                                <p>It takes 30 seconds to create and schedule a greeting card with our user-friendly interface.</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaCogs />
                                </div>
                                <h3 className="mb-3">Customizable</h3>
                                <p>Add a personal touch with freedom to include messages, photos, images, videos, GIFs, emojis and more.</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaHandsHelping />
                                </div>
                                <h3 className="mb-3">Easy Collaboration</h3>
                                <p>Super easy to invite with URL or by email.or with QR code and no sign up required</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaPenSquare   />
                                </div>
                                <h3 className="mb-3">Unlimited signatures and wishes.</h3>
                                <p>Express your wishes freely and openly with unlimited pages.No more fights over signature space</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaFileImage />
                                </div>
                                <h3 className="mb-3">Cards for Every Occasion</h3>
                                <p>From office birthdays to farewell we have the tailored Cards for all occasions</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaUserClock />
                                </div>
                                <h3 className="mb-3">Round the clock Customer Support</h3>
                                <p>We are commited to providing round-the-clock support, ensuring your needs are always met</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaTree />
                                </div>
                                <h3 className="mb-3">Go Green.</h3>
                                <p>Embrace eco-friendliness by choosing digital cards, contribution to tree conservation, water savings, and waste reduction.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    );
}

export default WhyUs;