import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    isownedplanLoad:false,
    err: null,
    data:[],
    ownedPlans:[],
};

const planReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getPricingPlanStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getPricingPlanSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getPricingPlanFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }

            case API_ACTIONS.FetchPlanHistoryStarted:
                return {
                    ...state,
                    isownedplanLoad:true
                }
            case API_ACTIONS.FetchPlanHistorySuccess:
                return {
                    ...state,
                    isownedplanLoad:false,
                    ownedPlans:action.data
                }
            case API_ACTIONS.FetchPlanHistoryFailed:
                return {
                    ...state,
                    isownedplanLoad:false,
                    err:action.data
                }
            default:
                return state;
    }

};
export default planReducer;