import { API_ACTIONS } from "../constants/actionConstant";

const initialState = {
    isLoading: false,
    err: null,
    data:[],
};

const aboutReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getAboutUsStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getAboutUsSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getAboutUsFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
            default:
                return state;
    }
};

export default aboutReducer;
