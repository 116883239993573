import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import frameBg from "../assets/img/advertise-frame.png";
import GirlImg from "../assets/img/girl.png"

const Advertisebanner = () => {
    return (
        <>
            <section className="advertisebanner-area">
                <Container>
                    <div className="banner-inner">
                        <img src={frameBg} alt="" />
                        <img src={GirlImg} alt="girl" className={'girl-image'} />
                        <Row className="justify-content-end">
                            <Col md={6}>
                                <div className="section-title-area light-style text-start ">
                                    <h2 className="mb-4">Leading companies across globe trust us for their digital group card needs.</h2>
                                    <p className="mb-4">Discover why those thousands of workplaces have entrusted us and unlock the benifits for your organisation</p>
                                    <Link to="/pricing" className="btn btn-lg btn-primary">Start Now</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default Advertisebanner;