import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import SvgIcon from './SvgIcon';
import { login, resetPassword } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ForgotPassword = ({ show, onHide }) => {
    const [formData,setFormData] = useState({email:"",error:""});
    const authData = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email.match(emailRegex)) {
            setFormData({...formData,error:"Email is not valid"});
            return false;
        } else {
            setFormData({...formData,error:""})
            return true;
        }
    };
    const onSubmit = (data) => {
        const isEmailValid = validateEmail(formData.email);
        if (isEmailValid) {
            dispatch(resetPassword(formData.email));
        }
    };
    const handleForm = (value)=>{
            setFormData({...formData,email:value});
    }
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body className="section-title-area sign-in-modal">
            <button type="button" class="popup-btn-close btn-close" onClick={onHide} aria-label="Close"></button>

                <h2 className="text-center mb-5"><span className="title-design position-relative">Forgot</span> Password</h2>
                <Form>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                        type="email"
                        placeholder="Enter Your Email Address"
                        onChange={(e) => handleForm(e.target.value)}
                    />
                    <p className="form-error-message mt-1">
                        {authData.loginError !== "Incorrect password!" &&
                            authData.loginError !== "none"
                            ? `${authData.loginError} | `
                            : ""}{" "}
                        {formData.error}
                    </p>
                    </Form.Group>
                    <Button variant="primary" type="button" className="w-100 mt-3" onClick={() => onSubmit()}>
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ForgotPassword;