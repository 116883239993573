import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../componants/Footer";
import Header from "../../componants/Header";
import Blog from "../../assets/img/thankyou-card.png";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getBlogDetails, getBlogList } from "../../actions";
import EmptyState from "../../componants/EmptyState";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import moment from "moment";
import * as FaIcons from "react-icons/fa";

const BlogDetail = (props) => {
    const { blogid } = useParams();
    // console.log(blogid)
    const dispatch = useDispatch();
    const [blogData, setBlogData] = useState(null)
    const blogpostdata = useSelector(state => state.blogdata.blogDetails);
    useEffect(() => {
        // if (blogid !== undefined) {
        //     if (blogpostdata?.length === 0) {
        //         dispatch(getBlogList(blogid));
        //     }
        //     else {
        //         const blogindex = blogpostdata.findIndex(x => parseInt(x.post_id) === parseInt(blogid));
        //         if (blogindex !== -1) {
        //             setBlogData(blogpostdata[blogindex]);
        //         }
        //     }
        // }
        if (blogid !== undefined) {
            dispatch(getBlogDetails(blogid));
        }

    }, [blogid])
    function formatDate(inputDate) {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const date = new Date(inputDate);
        const month = months[date.getMonth()];
        const day = date.getDate();

        return `${month} ${day}`;
    }
    // console.log('blogpostdataaaaa',blogpostdata);
    return (
        <>
            {
                blogpostdata === null ?
                    (
                        <EmptyState />
                    ) :
                    (
                        <>
                            <Helmet>
                                <title>{blogpostdata.meta_title}</title>
                                <meta name="title" content={blogpostdata.meta_title} />
                                <meta name="description" content={blogpostdata.meta_description} />
                                <meta name="keywords" content={blogpostdata.meta_keyword} />
                                <meta property="og:title" content={blogpostdata.meta_title} />
                                <meta property="og:description" content={blogpostdata.meta_description} />
                                <meta property="og:image" content={blogpostdata.image} />
                            </Helmet>
                            <section className="blog-details-area">
                                <Container>
                                    <Row>
                                        <Col lg={7}>
                                            <div className="blog_details">
                                                <div className="blog_details_img">
                                                    <img src={blogpostdata.image} className="img-fluid w-100 mb-5" />
                                                </div>
                                                <div className="top-bar-bt mb-3 mt-3">
                                                    <div className="auther-area">
                                                        <img className="auther-img" src={blogpostdata.author_image} alt={blogpostdata.author_name} />
                                                        <span>{blogpostdata.author_name}</span>
                                                    </div>
                                                    <span className="date">{formatDate(blogpostdata.date_published)}</span>
                                                </div>
                                                <h1 className="page-title">{blogpostdata.title}</h1>
                                                {parse(blogpostdata.description)}
                                                <div className="auther-box">
                                                    <img src={blogpostdata.author_image} alt="" />
                                                    <div className="auther-description">
                                                        <h5>{blogpostdata.author_name}</h5>
                                                        {parse(blogpostdata.author_description)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={5} className="related_sticky_blog">
                                            <h5 className="releted_bolg_title">Related Blogs</h5>
                                            <div className="related_items_about">
                                                {
                                                    blogpostdata?.related_posts?.length > 0 ?
                                                        blogpostdata?.related_posts?.map((relatedpost) => {
                                                            return (
                                                                <div className="releted_items_about_inner">
                                                                    <Row>
                                                                        <Col lg={4} sm={3} md={2} xl={3} className="releted_items_img">
                                                                            <img src={relatedpost.image} className="img-fluid" />
                                                                        </Col>
                                                                        <Col lg={8} sm={9} md={10} xl={9} className="releted_items_cnt">
                                                                            <div className="releted_cnt_inner">
                                                                                <Link to={`/blog-details/${relatedpost.seo}`} >{relatedpost.title}</Link>
                                                                                {parse(relatedpost.description)}
                                                                            </div>
                                                                            <div className="set_txt">
                                                                                <span className="date-time"><FaIcons.FaCalendarDay className="me-2" /> {moment(new Date(relatedpost.date_added.split(" ")[0]), 'YYYY-DD-MM').format('DD-MM-YYYY')}</span>
                                                                                <Link to={`/blog-details/${relatedpost.seo}`} className="card-link">Read More</Link>

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                        }) : <EmptyState />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </>
                    )
            }
        </>
    );
}

export default BlogDetail;