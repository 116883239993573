import React, { useState } from "react";
import { Col, NavLink, Row, Tab, Tabs } from "react-bootstrap";
import CopyIcon from '../assets/img/file-copy.png';
import ReminderIcon from '../assets/img/alert-reminder.png'
import ShareIcon from '../assets/img/share-icon.png';
import QRCode from "react-qr-code";
import * as FaIcons from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { MultiInvite } from "../actions";

const Invites = ({ url, handleReturn, cardsdata }) => {
    const { code } = useParams();
    const customer_id = localStorage.getItem('customer_id');
    const nameslist = "test1,test2";
    const dispatch = useDispatch();

    const [isEmptyField, setIsEmptyField] = useState(false);


    const [isCopied, setIsCopied] = useState('');
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url)
        setIsCopied('Copied');
        setTimeout(function () {
            setIsCopied('');
        }, 2000);
        // console.log(isCopied, 'iscopied');
    }

    const [emails, setEmails] = useState(['']);
    const [activeTab, setActiveTab] = useState('directInput');
    const [emailValidations, setEmailValidations] = useState(emails.map(() => true));

    const addEmailInput = () => {
        if (emails.length < 100) {
            setEmails([...emails, '']);
        }
    };

    const removeEmailInput = (index) => {
        const updatedEmails = [...emails];
        updatedEmails.splice(index, 1);
        setEmails(updatedEmails);
    };

    const handleEmailChange = (index, value) => {
        const updatedEmails = [...emails];
        updatedEmails[index] = value;
        setEmails(updatedEmails);

        // Validate the email input
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

        // Update the validation status for this field
        const updatedValidations = [...emailValidations];
        updatedValidations[index] = isValidEmail;
        setEmailValidations(updatedValidations);

        // Check if any field is empty or invalid
        const hasEmptyField = updatedEmails.some(email => email.trim() === '');
        const hasInvalidEmail = updatedValidations.some(validation => !validation);

        // Update isEmptyField based on empty fields or invalid emails
        setIsEmptyField(hasEmptyField || hasInvalidEmail);
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleSubmit = () => {
        const commaSeparatedEmails = emails.join(', ');
        const customer_id = localStorage.getItem('customer_id');
        const nameslist = "test1,test2";
        dispatch(MultiInvite(commaSeparatedEmails, code, customer_id, nameslist))
        // console.log(commaSeparatedEmails,'',code,'',customer_id,'',nameslist);
    };

    function generateShareLink(imageUrl, title, description, pageUrl) {
        const encodedTitle = encodeURIComponent(title);
        const encodedDescription = encodeURIComponent(description);
        const encodedPageUrl = encodeURIComponent(pageUrl);
        const encodedImageUrl = encodeURIComponent(imageUrl);
    
        const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedPageUrl}&quote=${encodedDescription}&picture=${encodedImageUrl}&title=${encodedTitle}`;
        const twitterShareLink = `https://twitter.com/share?url=${encodedPageUrl}&text=${encodedTitle}`;
        const linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedPageUrl}`;
        const whatsappShareLink = `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodedPageUrl}`;
        const telegramShareLink = `https://t.me/share/url?url=${encodedPageUrl}&text=${encodedTitle}`;
    
        return {
            facebookShareLink,
            twitterShareLink,
            linkedinShareLink,
            whatsappShareLink,
            telegramShareLink,
        };
    }
    

    const imageUrl = cardsdata.background_image;
    const title = cardsdata.cardname + 'to' + cardsdata.recieverfname + ' ' + cardsdata.recieverlname;
    const description = '';
    const pageUrl = `https://egroupgreeting.com/signcard/${code}`;
    // console.log('SignDatasssssssssssssssssssss',imageUrl,title,description,pageUrl);
    const shareLinks = generateShareLink(imageUrl, title, description, pageUrl);

    return (
        <Row className='mt-4'>
            <Col lg={8} md={10} xl={8} className="mx-auto">
                <div className='copy_text_area'>
                    <div className="text-start back_card_btn mb-3">
                        <button className='btn btn-primary' onClick={() => handleReturn()}><FaIcons.FaArrowLeft className='me-2' /> Back To Card</button>
                    </div>
                    <h4 className="mb-3">Option 1 (Invite Via Link)</h4>
                    <div className='heading_text text-center'>
                        <h4 className='m-0'>Share Link</h4>
                    </div>
                    <Row className="mb-4">
                        <Col lg={8} >
                            <div className='sub_text_area bg-white h-100'>
                                <h4 className="m-0 mb-2"><FaIcons.FaPencilAlt className='pe-2' />Invite people to contribute wishes to the eCard.</h4>
                                <p className="sub_text_link m-0">Copy &  Share the unique eCard link with your loved ones to receive beautiful e-wishes on the card</p>
                                <div className="copy-text">
                                    <input type="text" className="text" value={url} />
                                    <button onClick={() => copyToClipboard(url)}
                                    ><FaIcons.FaClone /> </button>
                                </div>
                                <p className='copied-text'>{isCopied}</p>
                            </div>
                        </Col>
                        <Col lg={4} >
                            <div className='qr-style' style={{ background: 'white', padding: '16px' }}>
                                <QRCode value={url} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <h4 className="mb-3">Option 2 (Invite Via Social Media)</h4>
                <div className="card p-3 p-md-4">
                    <p className="sub_text_link m-0"><FaIcons.FaLightbulb className='me-2' /> You can use text messages,messanger,emails,or any other social media channel to share the URL of the Group Card.</p>
                    <ul className='social-icons-list'>
                        <li><NavLink href={shareLinks.facebookShareLink} target="_blank" rel="noopener noreferrer"><FaIcons.FaFacebookF /></NavLink></li>
                        <li><NavLink href={shareLinks.twitterShareLink} target="_blank" rel="noopener noreferrer"><FaIcons.FaTwitter /></NavLink></li>
                        <li><NavLink href={shareLinks.linkedinShareLink} target="_blank" rel="noopener noreferrer"><FaIcons.FaLinkedin /></NavLink></li>
                        <li><a href={shareLinks.whatsappShareLink} target="_blank" rel="noopener noreferrer"><FaIcons.FaWhatsapp /></a></li>
                        <li><a href={shareLinks.telegramShareLink} target="_blank" rel="noopener noreferrer"><FaIcons.FaTelegram /></a></li>
                    </ul>
                </div>
                <div className="mt-4">
                    <h4 className="mb-3">Option 3 (Invite Via Email)</h4>
                    <div className="card p-3 p-md-4">
                        <Tabs
                            id="emailInputTabs"
                            activeKey={activeTab}
                            onSelect={handleTabChange}
                            transition={false}
                            className="invite-email-tab"
                        >
                            <Tab eventKey="directInput" title="Direct Input">
                                {emails.map((email, index) => (
                                    <div className="input-group mb-2" key={index}>
                                        <div style={{ flex: 1 }}>
                                            <input
                                                type="email"
                                                className={`form-control ${!emailValidations[index] ? 'is-invalid' : ''}`}
                                                placeholder="Enter Email"
                                                value={email}
                                                onChange={(e) => handleEmailChange(index, e.target.value)}
                                                required
                                            />
                                            {!emailValidations[index] && (
                                                <div className="invalid-feedback">
                                                    Please enter a valid email address.
                                                </div>
                                            )}
                                        </div>
                                        {index > 0 && (
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={() => removeEmailInput(index)}
                                                >
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                    <FaIcons.FaTimes />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-info text-white mr-2" onClick={addEmailInput}>
                                        <FaIcons.FaPlus className='me-1' /> Add more
                                    </button>
                                </div>
                            </Tab>
                            <Tab eventKey="commaSeparated" title="Comma-Separated">
                                <div className="input-group mb-3">
                                    <textarea
                                        className="form-control"
                                        placeholder="Enter Email (comma-separated)"
                                        value={emails.join(', ')}
                                        cols={50}
                                        rows={5}
                                        onChange={(e) => {
                                            setEmails(e.target.value.split(',').map((email) => email.trim()));
                                        }}
                                    ></textarea>
                                </div>
                            </Tab>
                        </Tabs>
                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-lg btn-primary" onClick={handleSubmit} disabled={!emailValidations.every(validation => validation)}>
                                Send Invitation
                            </button>
                        </div>
                    </div>
                </div>
                <div className="static_sec_area">
                    <Row>
                        <Col lg={4} sm={6}>
                            <div className="static_text_area">
                                <div className="copytext-area d-flex flex-column">
                                    <div className="copy-img me-3">
                                        <img src={CopyIcon} className="img-fluid" alt='copyicon' />
                                    </div>
                                    <div className="copy_text">
                                        <h5 className="mb-2 mt-3">Copy</h5>
                                        <p className="m-0">copy the card URL for collecting virtual signatures Anyone with the link can sign before card delivery.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} sm={6}>
                            <div className="static_text_area">
                                <div className="copytext-area d-flex flex-column">
                                    <div className="copy-img me-3">
                                        <img src={ShareIcon} className="img-fluid" alt='share icon' />
                                    </div>
                                    <div className="copy_text">
                                        <h5 className="mb-2 mt-3">Share</h5>
                                        <p className="m-0">You can use email, text messages, teams chat,slack or any other social media channel to share the URL of the card.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} className="mx-auto mt-sm-4 mt-lg-0">
                            <div className="static_text_area">
                                <div className="copytext-area d-flex flex-column">
                                    <div className="copy-img me-3">
                                        <img src={ReminderIcon} className="img-fluid" />
                                    </div>
                                    <div className="copy_text">
                                        <h5 className="mb-2 mt-3">Reminder</h5>
                                        <p className="m-0">Set a deadline to sign the card, & remind your group to add beautiful photos and personalized message to give the receiver sweet surprise.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Col>
        </Row>

    )
}

export default Invites;