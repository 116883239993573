import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    isFetch:false,
    isLoadWishCard:false,
    iseditLoad:false,
    isSign:false,
    err: null,
    fetchedCards:[],
    data:[],
    wishCardData:[],
    wishCardError:null,
    editorder:null
};

const orderCardReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.OrderCardStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.OrderCardSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.OrderCardFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }

            case API_ACTIONS.getEditCardListStarted:
            return {
                ...state,
                iseditLoad:true
            }
        case API_ACTIONS.getEditCardListSuccess:
            return {
                ...state,
                iseditLoad:false,
                editorder:action.data
            }
        case API_ACTIONS.getEditCardListFailed:
            return {
                ...state,
                iseditLoad:false,
                err:action.data
            }

        case API_ACTIONS.FetchOrderCardStarted:
            return {
                ...state,
                isFetch:true
            }

            case API_ACTIONS.SignCardStarted:
            return {
                ...state,
                isSign:true
            }
            case API_ACTIONS.SignCardSuccess:
                return {
                    ...state,
                    isSign:true
                }
            case API_ACTIONS.SignCardFailed:
            return {
                ...state,
                isSign:true
            }

        case API_ACTIONS.FetchOrderCardSuccess:
            return {
                ...state,
                isFetch:false,
                fetchedCards:action.data
            }
        case API_ACTIONS.FetchOrderCardFailed:
            return {
                ...state,
                isFetch:false,
                err:action.data
            }
            case API_ACTIONS.FetchWishCardStarted:
                return {
                    ...state,
                    isLoadWishCard:true
                }
            case API_ACTIONS.FetchWishCardSuccess:
                return {
                    ...state,
                    isLoadWishCard:false,
                    wishCardData:action.data
                }
            case API_ACTIONS.FetchWishCardFailed:
                return {
                    ...state,
                    isLoadWishCard:false,
                    wishCardError:action.data
                }
            default:
                return state;
    }

};
export default orderCardReducer;