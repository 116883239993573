import React, { useEffect, useState } from "react";
import { Col, Container, Row, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Testimonial from "../../componants/Testimonial";
import { useDispatch, useSelector } from "react-redux";
import BlogCard from "./BlogCard";
import { Helmet } from "react-helmet";
import { BASE_URL, PAGE_TITLE } from "../../constants/constant";
import Header from "../../componants/Header";
import Footer from "../../componants/Footer";
import EmptyState from "../../componants/EmptyState";
import { getBlogCategoryList, getBlogList, updateBlogList } from "../../actions";
import "./index.css"

const Blog = () => {
    const dispatch = useDispatch();
    const blogPostList = useSelector((state) => state.blogdata.blogpostData);
    const blogcategoryList = useSelector((state) => state.blogdata.data);
    useEffect(() => {
        dispatch(getBlogCategoryList());
    }, []);

    const [metaValues, setMetaValues] = useState({
        image: '',
        title: PAGE_TITLE.Blog_Title,
        description: '',
        keyword: '',
        seo: ''
    });
    const [subcategory, setSubCategory] = useState({ type: "none", id: "none" });

    const handleUpdateMetaValues = (image, title, description, keyword, seo) => {
        setMetaValues({
            image: image,
            title: title,
            description: description,
            keyword: keyword,
            seo: seo
        });
    }
    const handleSubCategory = (id, type, elem) => {
        handleUpdateMetaValues(elem.image, elem.meta_title, elem.meta_description, elem.meta_keyword, elem.seo);
        setSubCategory({ type: type, id: id })
    }
    useEffect(() => {
        if (subcategory.id !== "none") {
            dispatch(getBlogList(subcategory.id));
        }
    }, [subcategory]);

    const handleUpdateBlog = (id, page) => {
        dispatch(updateBlogList(id, page));
    }
    useEffect(() => {
        if (blogcategoryList.length > 0) {
            dispatch(getBlogList(blogcategoryList[0].category_id));
        }
    }, [blogcategoryList])



    function formatDate(inputDate) {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const date = new Date(inputDate);
        const month = months[date.getMonth()];
        const day = date.getDate();

        return `${month} ${day}`;
    }
    // console.log('blogPostListss', blogPostList);
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.Blog_Title}</title>
                <title>{metaValues.title}</title>
                <meta name="title" content={metaValues.title} />
                <meta name="description" content={metaValues.description} />
                <meta name="keywords" content={metaValues.keyword} />
                <meta property="og:title" content={metaValues.title} />
                <meta property="og:description" content={metaValues.description} />
                <meta property="og:image" content={metaValues.image} />
            </Helmet>
            <section className="category-listing-area">
                <Container>
                    <Row>
                        <Col lg={3}>
                            <div className="category-listing-details">
                                <Accordion>
                                    {
                                        blogcategoryList?.length > 0 && (
                                            <>
                                                {
                                                    blogcategoryList?.map((elem, index) => {
                                                        return (
                                                            <Accordion.Item eventKey={elem.category_id}>
                                                                <Accordion.Header onClick={() => handleSubCategory
                                                                    (elem.category_id, elem.title, elem)}>{elem.title}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    <ListGroup>
                                                                        <ListGroupItem>
                                                                            {
                                                                                elem.subcategory?.length > 0 ? (
                                                                                    <>
                                                                                        {elem.subcategory.map((subelem, index) => {
                                                                                            return (
                                                                                                <div className="link-list" onClick={() => handleSubCategory
                                                                                                    (subelem.category_id, subelem.title, subelem)}>{subelem.meta_title}</div>

                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="text-black">No categories added</div>
                                                                                )
                                                                            }
                                                                        </ListGroupItem>
                                                                    </ListGroup>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                </Accordion>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <Row className=" g-md-4">
                                {
                                    blogPostList.data?.length > 0 ?
                                        (<>
                                            {blogPostList?.data?.map((elem, index) => (
                                                <Col md={6} lg={4} key={'blogpost' + index}>
                                                    <BlogCard authername={elem.author_name} autherImage={elem.author_image} datePublish={formatDate(elem.date_published)} id={elem.post_id} className={''} image={elem.image} title={elem.title} description={elem.short_description} category={elem.category} auther={elem.user} link={`/blog-details/${elem.seo}`} />
                                                </Col>
                                            ))}
                                        </>) : (
                                            <EmptyState />
                                        )
                                }

                            </Row>
                            {
                                parseInt(blogPostList.total) > parseInt(blogPostList.limit) * parseInt(blogPostList.pageno) ? (
                                    <div className="text-center mt-5">
                                        <Button className="btn btn-lg btn-primary" onClick={() => handleUpdateBlog("", blogPostList.pageno + 1)}>Load More</Button>
                                    </div>
                                ) : null
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
            <Testimonial />

        </>
    );
}

export default Blog;