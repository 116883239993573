import React, { useEffect } from "react";
import "./pricing.css";
import PricingCard from "../../componants/PricingCard";
import { Helmet } from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";
import Header from "../../componants/Header";
import Footer from "../../componants/Footer";


const Pricing = () => {
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.Pricing_Title}</title>
            </Helmet>
            <PricingCard  />

        </>
    );
}

export default Pricing;