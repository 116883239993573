import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SvgIcon from "./SvgIcon";
import * as FaIcons from "react-icons/fa";
import shape from '../assets/img/light-grey-curve-line.svg';

const Featuresec = () => {
    return (
        <>
            <section className="feature-area  position-relative">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="section-title-area text-center mb-5">
                                <h2 className="mb-4">Empower Your <span className="title-design position-relative">Business</span> with Our End-to-End Enterprise Solution </h2>
                                <p>Our comprehensive solution empower your business with the necessory tools for batch greetings, brand customization, seamless integration,and real-time analytics through a user-friendly dashboard. it's incredibly simple to set up,enabling you to unlock these benifits within minutes.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-lg-5 features-card-list">
                        <Col md={4} sm={6}>
                           <div className="features-card">
                                <h3>Full stack enterprise solution</h3>
                                <p>Manage everything from a single location with ease and convenience.</p>
                           </div>
                        </Col>
                        <Col md={4} sm={6}>
                           <div className="features-card">
                                <h3>Batch greetings</h3>
                                <p>Create and Send cards to multiple users at a time.</p>
                           </div>
                        </Col>
                        <Col md={4} sm={6}>
                           <div className="features-card">
                                <h3>Customize branding</h3>
                                <p>with a unique web address, opportunities to embed your company's logo, and adherence to brand standards.</p>
                           </div>
                        </Col>
                        <Col md={4} sm={6}>
                           <div className="features-card">
                                <h3>Integration</h3>
                                <p>Seamless integration with the company intranet page and other business applications.</p>
                           </div>
                        </Col>
                        <Col md={4} sm={6}>
                           <div className="features-card">
                                <h3>Dashboard</h3>
                                <p>Track your team's engagement, run the much need people analytics.</p>
                           </div>
                        </Col>
                        <Col md={4} sm={6}>
                           <div className="features-card">
                                <h3>Simple to setup</h3>
                                <p>Our guided design helps you start in minute</p>
                           </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    );
}

export default Featuresec;