import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import store from "./store";
import { toast } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById('root'));


toast.configure({
    autoClose: 2000,
    draggable: true,
    position: toast.POSITION.TOP_RIGHT,
    progress: 2,
});

root.render(
    // <React.StrictMode>
        <Provider store={store}>
            {/* <GoogleOAuthProvider clientId={googleClientId}> */}
                <App />
            {/* </GoogleOAuthProvider> */}
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
