import React from 'react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { handleUser } from './actions';
import  { gapi } from 'gapi-script';
import GoToTopButton from './componants/GoToTopButton';
function App() {
    const dispatch = useDispatch();
    
    let googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    useEffect(()=>{
        function start() {
            gapi.client.init({
                clientId:googleClientId,
                scope:""
            })
        };
        gapi.load('client:auth2',start)
        },[])
    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                toastClassName={'Toastify__toast-theme--light'}
            />
            <Routes />
            <GoToTopButton />
        </>
    );
}

export default App;