import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function FormTooltip({ tooltipText, children,className,placement }) {
  return (
    <OverlayTrigger
      placement={placement} // You can change the placement as per your preference
      overlay={<Tooltip>{tooltipText}</Tooltip>}
    >
      <button className={`border-0 form-tooltip-btn ms-2 ${className}`}>
        {children}
      </button>
    </OverlayTrigger>
  );
}

export default FormTooltip;
