const API_URL = process.env.REACT_APP_API;
export const BASE_URL = process.env.REACT_APP_APP_URL;
export const GIPHY_URL = process.env.REACT_APP_GIPHY_URL ;
export const ACTION_ROUTES = {
    categoryList:`${API_URL}?route=api/category/lists`,
    authRoute: `${API_URL}?route=api/login`,
    registerRoute:`${API_URL}?route=api/register`,
    resetRegisterRoute:`${API_URL}?route=api/register/resendcode`,
    blogCategory:`${API_URL}?route=api/blog/category`,
    BlogPost:`${API_URL}?route=api/blog/posts`,
    BlogDetails:`${API_URL}?route=api/blog`,
    cardUrl:`${API_URL}?route=api/card`,
    WishlistPost:`${API_URL}?route=api/card/wishlist_card_list`,
    WishlistAdd:`${API_URL}?route=api/card/wishlist_add`,
    WishlistDelete:`${API_URL}?route=api/card/wishlist_delete`,
    pricingPlan:`${API_URL}?route=api/plan`,
    b2bPlan:`${API_URL}?route=api/plan/business_enquiry`,
    userAdd:`${API_URL}?route=api/customer/add_user`,
    updateUser:`${API_URL}?route=api/customer/update_user`,
    userDelete:`${API_URL}?route=api/customer/user_delete`,
    userList:`${API_URL}?route=api/customer/user_list`,
    giphyFetch:`https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_URL}`,
    stickerFetch:`https://api.giphy.com/v1/stickers/search?api_key=${GIPHY_URL}`,
    orderCard:`${API_URL}?route=api/order/add`,
    fetchorderCard:`${API_URL}?route=api/order/card_history`,
    fetchreceivedCard:`${API_URL}?route=api/order/card_received_history`,
    orderApi:`${API_URL}?route=api/order`,
    timeZoneList: `${API_URL}?route=api/card/timzone`,
    updateProfile:`${API_URL}?route=api/register/update`,
    contactRoute:`${API_URL}?route=api/plan/contact`,
    deleteSign:`${API_URL}?route=api/order/sing_delete`,
    stripeRoute:`${API_URL}?route=api/plan/add_plan`,
    cardDetails:`${API_URL}?route=api/card/details`,
    faqRoute:`${API_URL}?route=api/faq`,
    bgImageAPI:`${API_URL}?route=api/card/backgroundimages`,
    SetImageAPI:`${API_URL}?route=api/card/backgroundsetcard`,
    InformationAPI:`${API_URL}?route=api/information&seo=`,
    orderDetails:`${API_URL}?route=api/plan/details`,
    MultiInvite:`${API_URL}?route=api/order/multi_invite`,
    editcard:`${API_URL}?route=api/order/order_details`,
    updatecard:`${API_URL}?route=api/order/update_card`,
    pdfUrl:`${API_URL}?route=api/orderpdf/pdf`,
};

export const PAGE_TITLE = {
    Home_Title:`Celebrate Together: E Greeting Cards`,
    Blog_Title:`Blog - Stay up-to-date with our latest news | Egroup Greeting`,
    About_Title:`About Egroup Greeting`,
    Category_Title:`Greet Occasions | Egroup Greeting`,
    CategoryListing_Title:`Greeting Categories Templates || Egroup Greeting`,
    Pricing_Title:`Pricing | Egroup Greeting`,
    Wishboard_Title:`Wishboard | Egroup Greeting`,
    CreateCard_Title:`Send Card Online | Egroup Greeting`,
    Contact_Title:`Contact Us | Egroup Greeting`,
    Privacy_Title:`Privacy Policy | Egroup Greeting`,
    Faq_Title:`Frequently Asked Questions | Egroup Greeting`,
    Business_Title:`Greeting Cards for Business | Egroup Greeting`,
};