import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    iscardsUpdate:false,
    isCardsLoad:false,
    err: null,
    data:[],
    cardsData:[],
    searchData:[],
};

const subCategoryReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getSubCategoryListStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getSubCategoryListSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data.categories
            }
        case API_ACTIONS.getSubCategoryListFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
        case API_ACTIONS.getCardsListStarted:
            return {
                ...state,
                isCardsLoad:true
            }
        case API_ACTIONS.getCardsListSuccess:
            return {
                ...state,
                isCardsLoad:false,
                cardsData:action.data
            }
        case API_ACTIONS.getCardsListFailed:
            return {
                ...state,
                isCardsLoad:false,
                err:action.data
            }

            case API_ACTIONS.searchandFilterCardsStarted:
                return {
                    ...state,
                    isCardsLoad:true
                }
            case API_ACTIONS.searchandFilterCardsSuccess:
                return {
                    ...state,
                    isCardsLoad:false,
                    searchData:action.data
                }
            case API_ACTIONS.searchandFilterCardsFailed:
                return {
                    ...state,
                    isCardsLoad:false,
                    err:action.data
                }

            case API_ACTIONS.getCardDetailsListStarted:
                return {
                    ...state,
                    // isCardsLoad:true
                }
            case API_ACTIONS.getCardDetailsListSuccess:
                return {
                    ...state,
                    isCardsLoad:true,
                    cardsData:action.data
                }
            case API_ACTIONS.getCardDetailsListFailed:
                return {
                    ...state,
                    isCardsLoad:false,
                    err:action.data
                }

            case API_ACTIONS.updateCardsListStarted:
                return {
                    ...state,
                    iscardsUpdate:true
                }
            case API_ACTIONS.updateCardsListSuccess:
                return {
                    ...state,
                    iscardsUpdate:false,
                    cardsData:action.data
                }
            case API_ACTIONS.updateCardsListFailed:
                return {
                    ...state,
                    iscardsUpdate:false,
                    err:action.data
                }

                case API_ACTIONS.updateSearchCardsListStarted:
                    return {
                        ...state,
                        iscardsUpdate:true
                    }
                case API_ACTIONS.updateSearchCardsListSuccess:
                    return {
                        ...state,
                        iscardsUpdate:false,
                        searchData:action.data
                    }
                case API_ACTIONS.updateSearchCardsListFailed:
                    return {
                        ...state,
                        iscardsUpdate:false,
                        err:action.data
                    }

                    case API_ACTIONS.clearSearchList:
                    return {
                        ...state,
                        iscardsUpdate:false,
                        searchData:null
                       
                    }
            default:
                return state;
    }
};
export default subCategoryReducer;