import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const BlogCard = (props) => {
    return (
        <Card className={`blog-card rounded-0 border-0 ${props.className}`} key={props.id}>
            <div className="blog-image-wrap">
                <Card.Img variant="top" src={props.image} className={'rounded-0'} />
            </div>
            <Card.Body>
                <div className="top-bar-bt">
                    <div className="auther-area">
                        <img className="auther-img" src={props.autherImage} alt="" />
                        <span>{props.authername}</span>
                    </div>
                    <span className="date">{props.datePublish}</span>
                </div>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                    {props.description}
                </Card.Text>
                <Link to={props.link} className="card-link">Read More</Link>
            </Card.Body>
        </Card>
    );
}

export default BlogCard;