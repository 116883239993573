import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import pcipng from "../assets/img/footericons/pci.png"
import isoimg from "../assets/img/footericons/iso.png"
import stripeimg from "../assets/img/footericons/stripe.png"
import BillDesk from "../assets/img/footericons/BillDesk.jpg"
import { BsTwitterX } from "react-icons/bs";

import * as FaIcons from "react-icons/fa";

let SiteInfoEmail = process.env.REACT_APP_COMPANY_MAIL;
let LinkdinLink = process.env.REACT_APP_LINKEDIN_LINK;
let InstagramLink = process.env.REACT_APP_INSTAGRAM_LINK;
let FacebookLink = process.env.REACT_APP_FACEBOOK_LINK;
let TwitterlINK = process.env.REACT_APP_TWITTER_LINK;

let YoutubeLink = process.env.REACT_APP_YOUTUBE_LINK;

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row className="row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
                    <Col>
                        <img src={logo} className="mb-4" alt="logo" />
                        <p className="footer-oneline">Online group greeting platform for people to connect, celebrate life's events.</p>
                        <div className="ft-social-list">
                            <Link to={YoutubeLink} target="_blank" className="ft-social-btn" aria-label="YouTube"><FaIcons.FaYoutube /></Link>
                            <Link to={FacebookLink} target="_blank" className="ft-social-btn" aria-label="Facebook"><FaIcons.FaFacebook /></Link>
                            <Link to={LinkdinLink} target="_blank" className="ft-social-btn" aria-label="LinkedIn"><FaIcons.FaLinkedin /></Link>
                            <Link to={InstagramLink} target="_blank" className="ft-social-btn" aria-label="Instagram"><FaIcons.FaInstagram /></Link>
                            <Link to={TwitterlINK} target="_blank" className="ft-social-btn" aria-label="Twitter"><BsTwitterX /></Link>
                        </div>
                    </Col>
                    <Col>
                        <div className="resource-link d-flex flex-column">
                            <h5 className="mb-4">Company</h5>
                            <Link to="/about">About us</Link>
                            <Link to="/pricing">Pricing</Link>
                            <Link to="/business">Our Solutions</Link>
                            <Link to="/contact">Work with Us</Link>
                            <Link to="/blog">Blog</Link>
                            <Link to="/faq">FAQ</Link>
                            <Link to="#">Try the Demo</Link>
                        </div>
                    </Col>
                    <Col>
                        <div className="resource-link d-flex flex-column">
                            <h5 className="mb-4">Legal</h5>
                            <Link to="/privacy-policy">Privacy policy</Link>
                            <Link to="/terms-of-service">T&C</Link>
                            <Link to="/press">Press</Link>
                            <Link to="/">Brand Guidelines</Link>
                            <Link to="/category/listing/professional">Use cases</Link>
                        </div>
                    </Col>
                    <Col>
                        <div className="resource-link d-flex flex-column">
                            <h5 className="mb-4">Explore</h5>
                            <Link to="/category/listing/professional/Birthday-ecards-professional">Birthday card</Link>
                            <Link to="/category/listing/professional/farewell-professional">Farewell card</Link>
                            <Link to="/category/listing/personal/valentine-s-day-ecard">Valentine Cards</Link>
                            <Link to="/category/listing/personal/Thank%20you">Thank You Cards</Link>
                        </div>
                    </Col>
                    {/* <Col>
                        <div className="resource-link d-flex flex-column">
                            <h5 className="mb-4">Contact Us</h5>
                            <NavLink href={`mailto:${SiteInfoEmail}`}>{SiteInfoEmail}</NavLink>
                            <h6 className="mb-2">Address</h6> 
                           <address style={{color: '#74757e'}}>#Summane, 1st main Road,Sangameshwara Layout
                            Chitradurga, Karnataka, India 577501  
                            </address>
                            <h5 className="mb-4">Follow Us</h5>
                           
                        </div>
                    </Col> */}
                </Row>
                <Row className="copyright-row">
                    <Col lg={12}>
                        <div className="d-flex justify-content-end align-items-center flex-column flex-xl-row ">
                            <div className="payment-accept d-flex justify-content-center justify-content-lg-end mt-xl-4">
                                <img src={pcipng} alt="pci" />
                                <img src={isoimg} alt="iso" />
                                <img src={BillDesk} alt="BillDesk" />
                                <img src={stripeimg} alt="stripe" className="stripe" />
                            </div>
                        </div>
                        <div className="Copyright-Text text-center mt-3 w-100">©2023  eGroupGreeting.com. All rights reserved.</div>
                    </Col>
                </Row>

            </Container>
        </footer>
    );
}

export default Footer;