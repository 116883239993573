import React, { useEffect, useState } from "react";
import { Col, Container, Row, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./CategoryListing.css";
import Testimonial from "../../componants/Testimonial";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { BASE_URL, PAGE_TITLE } from "../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import { clearSearchList, getCardsList, getCategoryList, getSubCategoryList, searchandFilterCards } from "../../actions/index.js";
import ProductCard from "../../componants/ProductCard";
import * as FaIcons from "react-icons/fa";
import { toast } from "react-toastify";

import uploadImg from "../../assets/img/upload-img.png"

const CategoryListing = (props) => {
    const dispatch = useDispatch();
    const { id, subid } = useParams();
    const navigate = useNavigate();
    const wishlisted = useSelector(state => state.wishlistpost.data)
    const isLogin = localStorage.getItem('isLogin');
    const [activeKey, setActiveKey] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearch, setisSearch] = useState(false);
    const [isFilter, setisFilter] = useState(false);
    const [filter, setFilter] = useState("All")

    const [metaValues, setMetaValues] = useState({
        image: '',
        title: PAGE_TITLE.Category_Title,
        description: '',
        keyword: '',
        seo: ''
    });
    const [subcategory, setSubCategory] = useState({ type: "none", id: "none" });

    const handleUpdateMetaValues = (image, title, description, keyword, seo) => {
        setMetaValues({
            image: image,
            title: title,
            description: description,
            keyword: keyword,
            seo: seo
        });
    }

    const subcategoryList = useSelector((state) => state.subcategory.data);
    const categoryList = useSelector((state) => state.category.data);
    const cardsList = useSelector((state) => state.subcategory.cardsData);
    const searchcardsList = useSelector((state) => state.subcategory.searchData)
    const [filteredData,setFilteredData] = useState([])
    useEffect(() => {
        dispatch(getCategoryList());
    }, []);
    useEffect(() => {
        if (id !== undefined) {
            dispatch(getSubCategoryList(id));
            // console.log(categoryList,id, "this category list")
            if (categoryList?.length > 0) {
                var metaIndex = categoryList.findIndex(p => p.seo === id);
                if (metaIndex !== -1) {
                    handleUpdateMetaValues(categoryList[metaIndex].image, categoryList[metaIndex].meta_title + ' ecards', categoryList[metaIndex].meta_description, categoryList[metaIndex].meta_keyword, categoryList[metaIndex].seo);
                }
            }
        }
    }, [id, categoryList])

    useEffect(() => {
        if (id !== undefined && subid !== undefined && subcategoryList?.length > 0) {
            // console.log(categoryList,subcategoryList)
            var metaIndex = subcategoryList.findIndex(p => p.seo === subid);
            if (metaIndex !== -1) {
                handleUpdateMetaValues(subcategoryList[metaIndex].image, subcategoryList[metaIndex].meta_title + ' ecards', subcategoryList[metaIndex].meta_description, subcategoryList[metaIndex].meta_keyword, subcategoryList[metaIndex].seo);
                setSubCategory({ type: subcategoryList[metaIndex].name, id: subcategoryList[metaIndex].category_id });
                // navigate(`/category/listing/${id}/${elem.seo}`, { replace: true });
            }
        } else if (subid === undefined && id !== undefined) {
            setSubCategory({ id: "none", type: "none" })
        }
    }, [id, subid, categoryList, subcategoryList])

    const handleCategoryClick = (cat_id) => {
        if(isSearch){
            handleClearSearch()
        }
        setSubCategory({ id: "none", type: "none" })
        navigate(`/category/listing/${cat_id}`);


    }
    const handleSubCategory = (myid, type, elem) => {
        if(isSearch){
            handleClearSearch()
        }
        handleUpdateMetaValues(elem.image, elem.meta_title, elem.meta_description, elem.meta_keyword, elem.seo);
        setSubCategory({ type: type, id: myid });
        // history.push(`/category/listing/${id}/${elem.seo}`)
        navigate(`/category/listing/${id}/${elem.seo}`);

    }

    useEffect(() => {
        if (subcategory.id !== "none") {
            dispatch(getCardsList(subcategory.id, 1));
        }
    }, [subcategory])

    const handleCardClick = (id) => {
        // if(isLogin){
        // history.push(`/create/${id}`)
        navigate(`/create/${id}`)

        // }else{
        //     toast.error("Please Login and Retry")
        // }

    }

    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const handleOnSearch = (value) => {

        setSearchTerm(value);

    }

    const submitSearch = (value, type) => {
        setisSearch(true);

        dispatch(searchandFilterCards(filter !== "All" ? { [type]: value, filter: filter === "Free" ? 0 : 1 } : { [type]: value }, 1))
    }

    const handleClearSearch = () => {
        setisSearch(false);
        setisFilter(false)
        setSearchTerm("");
        setFilter("All")
        dispatch(clearSearchList());
    }
    const handleFilter = (value) => {
        setFilter(value)
    }
    useEffect(()=>{
        if(isSearch){
            if(filter === "All"){
                setFilteredData(searchcardsList?.data);
            }else if (filter === "Free"){
                const filteredVal = searchcardsList?.data?.filter((item) => {
                    return item.ispremium === "0"
                });
                setFilteredData(filteredVal)
            }else{
                const filteredVal = searchcardsList?.data?.filter((item) => {
                    return item.ispremium === "1"
                });
                setFilteredData(filteredVal)
            }
        }else{
if(filter === "All"){
    setFilteredData(cardsList?.data);
            }else if (filter === "Free"){
                const filteredVal = cardsList?.data?.filter((item) => {
                    return item.ispremium === "0"
                });
                // console.log(filteredVal)
                setFilteredData(filteredVal)
            }else{
                const filteredVal = cardsList?.data?.filter((item) => {
                    return item.ispremium === "1"
                });
                setFilteredData(filteredVal)
            }
        }
    },[isSearch,filter,cardsList,searchcardsList])
    console.log('filteredData',subcategoryList);
    return (
        <>
            <Helmet>
                <title>{metaValues.title}</title>
                <meta name="title" content={metaValues.title} />
                <meta name="description" content={metaValues.description} />
                <meta name="keywords" content={metaValues.keyword} />
                <meta property="og:title" content={metaValues.title} />
                <meta property="og:description" content={metaValues.description} />
                <meta property="og:image" content={metaValues.image} />
                <meta property="og:url" content={`${BASE_URL}/category/listing/${id}`} />
            </Helmet>
            <section className="category-listing-area">
                <Container>
                    <Row className="g-3 g-md-4 g-lg-5">
                        <div className="breadcrumb-list mb-3">
                            <Breadcrumb>
                                <Breadcrumb.Item href="/category/listing">Greet occasions</Breadcrumb.Item>
                                <Breadcrumb.Item href={`/category/listing/${categoryList[categoryList.findIndex(x => x.seo === id)]?.seo}`}>{categoryList[categoryList.findIndex(x => x.seo === id)]?.name}</Breadcrumb.Item>
                                {
                                    subcategory.id !== 'none' && (
                                        <Breadcrumb.Item>{subcategory.type}</Breadcrumb.Item>
                                    )
                                }
                            </Breadcrumb>
                        </div>
                        <Col lg={5} md={6} sm={8} xl={3}>
                            <div className="category-listing-details">
                                <div className="category-heading pb-3">
                                    <h4>Category</h4>
                                </div>
                                <Accordion defaultActiveKey={id}>
                                    {
                                        categoryList?.length > 0 && (
                                            <>
                                                {
                                                    categoryList.map((elem, index) => {
                                                        return (
                                                            <Accordion.Item eventKey={elem.seo} key={elem.seo}>
                                                                <Accordion.Header onClick={() => handleCategoryClick(elem.seo)} >
                                                                    {elem.name}
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <ListGroup>
                                                                        <ListGroupItem>
                                                                            {
                                                                                subcategoryList?.length > 0 ? (
                                                                                    <>
                                                                                        {subcategoryList.map((elem, index) => {
                                                                                            return (
                                                                                                <div className={`link-list ${elem.category_id === subcategory.id ? "active" : ""}`} onClick={() => handleSubCategory(elem.category_id, elem.name, elem)}>{elem.name}</div>

                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="text-danger">No categories added</div>
                                                                                )
                                                                            }
                                                                        </ListGroupItem>
                                                                    </ListGroup>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                </Accordion>
                            </div>
                        </Col>
                        <Col lg={7} md={12} sm={12} xl={9}>
                            <div className="serch-product-area mb-4 mt-5 mt-lg-0">
                                <div className="search-wrapper">
                                    <input type="search" className="form-control" placeholder="search product" value={searchTerm} onChange={(e) => handleOnSearch(e.target.value)} />
                                    {
                                        isSearch ? <button className="icon-btn" type="button" onClick={() => handleClearSearch()}>X</button> : <button type="submit" className="icon-btn" onClick={() => submitSearch(searchTerm, "search")}><FaIcons.FaSearch /></button>
                                    }

                                </div>
                                <div className="filter-box">
                                    <label className="me-2">Filter:</label>
                                    <select className="form-control form-select pro-filter" value={filter} onChange={(e) => handleFilter(e.target.value)}>
                                        <option>All</option>
                                        <option>Free</option>
                                        <option>Premium</option>
                                    </select>
                                </div>
                            </div>
                            <>
                                {
                                    isSearch ? (
                                        <>
                                            <div className="row g-5">
                                                {
                                                    filteredData?.length > 0 ? (
                                                        <>
                                                            {
                                                                filteredData?.map((elem) => {
                                                                    return (
                                                                        <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                                                                        <ProductCard type={"product"} cardid={elem.card_id} className={''} image={elem.image} title={elem.title} description={elem.short_description} category={elem.category} auther={elem.user} seo={elem.seo} link={'/blog-details'} isPremium={elem.ispremium} handleClick={handleCardClick} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }


                                                        </>
                                                    ) :
                                                        (<div>Search Data Not Available</div>)
                                                }
                                                <>
                                                    {
                                                        searchcardsList?.data?.length > 0 && searchTerm !== "" && (<>
                                                            {
                                                                parseInt(searchcardsList.total) > parseInt(searchcardsList.limit) * parseInt(searchcardsList.pageno) ? (
                                                                    <div className="text-center mt-5">
                                                                        <Button className="btn btn-lg btn-primary" onClick={() => dispatch(searchandFilterCards(filter !== "All" ? { search: searchTerm, filter: filter === "Free" ? 0 : 1 } : { search: searchTerm }, searchcardsList.pageno + 1))}>Load More</Button>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </>
                                                        )}
                                                </>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                subcategory.id !== "none" ? (
                                                    <>


                                                        <div className="card-design-area mb-4">
                                                            <h5>{subcategory.type} Cards</h5>
                                                        </div>
                                                        <div className="row g-5">
                                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                                                                <div className="upload-box upload-card-img d-flex align-items-center justify-content-center flex-column" onClick={() => navigate(`/create/addimage`)}>
                                                                    <img src={uploadImg} alt="" />
                                                                    <p className="text-center px-2">Upload your own design</p>
                                                                </div>
                                                            </div>
                                                            {
                                                                <>
                                                                    {
                                                                        filteredData?.length > 0 && (
                                                                            <>
                                                                                {
                                                                                    filteredData?.map((elem) => {
                                                                                        return (
                                                                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-3">
                                                                                                <ProductCard type={"product"} cardid={elem.card_id} className={''} image={elem.image} title={elem.title} description={elem.short_description} category={elem.category} auther={elem.user} seo={elem.seo} link={'/blog-details'} isPremium={elem.ispremium} handleClick={handleCardClick} />
                                                                                            </div>

                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                // )
                                                            }
                                                        </div>
                                                        {/* {
                                                        isSearch || isFilter? 
                                                        (
                                                            <>
                                                            {
                                                    searchcardsList?.data?.length > 0 && searchTerm !== "" && (<>
                                                        {
                                                            parseInt(searchcardsList.total) > parseInt(searchcardsList.limit) * parseInt(searchcardsList.pageno) ? (
                                                                <div className="text-center mt-5">
                                                                    <Button className="btn btn-lg btn-primary" onClick={() => dispatch(searchandFilterCards(filter!== "All" ?{search:searchTerm,filter:filter === "Free" ? 0:1} :{search:searchTerm},searchcardsList.pageno + 1))}>Load More</Button>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </>
                                                    )}
                                                            </>
                                                        ): */}
                                                        {/* ( */}
                                                        <>
                                                            {
                                                                cardsList?.data?.length > 0 && subcategory.id !== "none" && (<>
                                                                    {
                                                                        parseInt(cardsList.total) > parseInt(cardsList.limit) * parseInt(cardsList.pageno) ? (
                                                                            <div className="text-center mt-5">
                                                                                <Button className="btn btn-lg btn-primary" onClick={() => dispatch(getCardsList(subcategory.id, cardsList.pageno + 1))}>Load More</Button>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </>
                                                                )}
                                                        </>
                                                        {/* ) */}
                                                        {/* } */}

                                                    </>

                                                ) : (

                                                    <Row className="g-3 g-md-4 g-lg-5">
                                                        {
                                                            subcategoryList?.length > 0 ? (
                                                                <>
                                                                    {subcategoryList.map((elem, index) => {
                                                                        return (
                                                                            <Col sm={6} md={4} lg={3} key={'categorycard' + index} onClick={() => handleSubCategory(elem.category_id, elem.name, elem)}>
                                                                                <div className="main-cateogory-card">
                                                                                    <div className="category-img-bx">

                                                                                        <img src={elem.image} alt="category" />
                                                                                    </div>
                                                                                    <h4 className="title">{elem.name}</h4>
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <div className="text-black">No categories added</div>
                                                            )
                                                        }
                                                    </Row>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </>


                        </Col>
                        {/* <div className="text-center mt-5">
                            <Link to="/" className="btn btn-lg btn-primary">Load More</Link>
                        </div> */}
                    </Row>
                </Container>
            </section>

            <Testimonial />

        </>
    );
}

export default CategoryListing;