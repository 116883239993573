import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { handleConfirmOrder, handleConfirmUpdate, handlePaymentDecode } from "../actions/index";
import LoadingSpinner from "./LoadingSpinner";
import { Button, Col, Row } from "react-bootstrap";
import PaymentSuccess from "../pages/PaymentMessage/PaymentSuccess";
import PaymentFailed from "../pages/PaymentMessage/PaymentFailed";
import { useState } from "react";
import { NavLink } from "react-router-dom";
function BilldeskSuccess() {
	const { type, id } = useParams();
	const [cardId, setcardId] = useState(null);
	const [secondsRemaining, setSecondsRemaining] = useState(10);
	const paymentData = useSelector((state) => state.billdeskReducer);
	const dispatch = useDispatch();
	useEffect(() => {
		if (id !== undefined) {
			// console.log("yo");
			dispatch(handlePaymentDecode(id));
		}
	}, [id]);

	useEffect(() => {
		if(cardId !== null){
			if (secondsRemaining > 0) {
				const timerId = setInterval(() => {
				  setSecondsRemaining(prevSeconds => prevSeconds - 1);
				}, 1000);
		  
				return () => {
				  clearInterval(timerId);
				};
			  } else {
				window.location.href = `${process.env.REACT_APP_APP_URL}/create/signcard/${cardId}`
			  }
		}
		
	  }, [secondsRemaining,cardId]);
	const handleSuccess = (id) => {
		// console.log(id);
		setcardId(id);
	};
	useEffect(() => {
		if (paymentData.data !== null) {
			// console.log(paymentData.data);
			if (paymentData.data.auth_status === "0300") {
				if (type === "ordercomplete") {
					// console.log(paymentData.data);
					dispatch(
						handleConfirmOrder(
							paymentData.data.additional_info.additional_info1,
							handleSuccess,
							"ordercard",
							"billdesk"
						)
					);
				} else if (type === "completion") {
					dispatch(
						handleConfirmOrder(
							paymentData.data.additional_info.additional_info1,
							() => {},
							type,
							"billdesk"
						)
					);
				}else if(type === "updatecompletion"){
					dispatch(handleConfirmUpdate(paymentData.data.additional_info.additional_info1,()=>{}))
				}
			}
		}
	}, [paymentData.data, type]);

	// console.log(type, id);
	const RecieptViewer = ({ data }) => {
		switch (data.auth_status) {
			case "0300":
				return (
					<div className="payment-reciept-parent">
						<div className="reciept">
							<Row className="mb-4">
								<PaymentSuccess />
							</Row>
							<Row>
								<Col>Purpose of payment</Col>
								<Col>{"Online Payment"}</Col>
							</Row>
							<Row>
								<Col>Payment Mode</Col>
								<Col>{data.payment_method_type}</Col>
							</Row>
							<Row>
								<Col>Amount</Col>
								<Col>{data.charge_amount}</Col>
							</Row>
							<Row>
								<Col>Date</Col>
								<Col>{data.transaction_date}</Col>
							</Row>
							<Row>
								<Col>Transaction Id</Col>
								<Col>{data.transactionid}</Col>
							</Row>

							<Row>
								<Col>Order Id</Col>
								<Col>{data.orderid}</Col>
							</Row>

							<Row>
								{
									type === "updatecompletion" ? (<NavLink exact="true" to="/profile">
									Go to Profile
								</NavLink>):(<NavLink exact="true" to="/">
									Go to Home
								</NavLink>)
								}
								
								{
									cardId!==null ?(
										<NavLink exact="true" to={`/create/signcard/${cardId}`}>
									Go to Card
								</NavLink>
									):null
								}
								
							</Row>
							{
								cardId!== null && (
<Row>
							<p>Redirecting in {secondsRemaining} seconds...</p>
							</Row>
								)
							}
							
						</div>
					</div>
				);
			default:
				return (
					<div className="payment-reciept-parent">
						<div className="reciept">
							<Row className="mb-4">
								<PaymentFailed />
							</Row>
							<Row>
								<Col>Status Description </Col>
								<Col>{`${data.transaction_error_code} : ${data.transaction_error_desc}`}</Col>
							</Row>
							<Row>
								<Col>Purpose of payment</Col>
								<Col>{"Online Payment"}</Col>
							</Row>
							<Row>
								<Col>Payment Mode</Col>
								<Col>{data.payment_method_type}</Col>
							</Row>
							<Row>
								<Col>Amount</Col>
								<Col>{data.charge_amount}</Col>
							</Row>
							<Row>
								<Col>Date</Col>
								<Col>{data.transaction_date}</Col>
							</Row>
							<Row>
								<Col>Transaction Id</Col>
								<Col>{data.transactionid}</Col>
							</Row>

							<Row>
								<Col>Order Id</Col>
								<Col>{data.orderid}</Col>
							</Row>

							<Row>
								<NavLink exact="true" to="/">
									Go to Home
								</NavLink>
							</Row>
						</div>
					</div>
				);
		}
	};
	return (
		<>
			{paymentData.isLoadingBilldesk ? (
				<LoadingSpinner />
			) : (
				<>
					{paymentData.data !== null ? (
						<>
							<RecieptViewer data={paymentData.data} />
						</>
					) : (
						<LoadingSpinner />
					)}
				</>
			)}
		</>
	);
}

export default BilldeskSuccess;

