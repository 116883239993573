import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isAuthenticated: false,
    isLoginLoad:false,
    isRegisterLoad:false,
    loginError:"none",
    registerError:"none",
    loggedinData:null,
    isRegistered:false,
    isChangePassword:false,
    isActivated:false,
    activateError:"none",
    country:"INR"

};

const authReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.loginStarted:
            return {
                ...state,
                isLoginLoad:true
            }
            case API_ACTIONS.loginLoad:
            return {
                ...state,
                isLoginLoad:false,
                isAuthenticated:true,
                loggedinData:action.data
            }
        case API_ACTIONS.loginFailed:
            return {
                ...state,
                isLoginLoad:false,
                loginError:action.data
            }
        case API_ACTIONS.loginSuccess:
            return {
                ...state,
                isLoginLoad:false,
                isAuthenticated:true,
                loggedinData:action.data
            }

        case API_ACTIONS.registerStarted:
            return {
                ...state,
                isRegisterLoad:true
            }
        case API_ACTIONS.registerFailed:
            return {
                ...state,
                isRegisterLoad:false,
                registerError:action.data
            }
        case API_ACTIONS.registerSuccess:
            return {
                ...state,
                isRegisterLoad:false,
                isRegistered:true,
            }

        case API_ACTIONS.changePasswordStarted:
            return {
                ...state,
                
            }
        case API_ACTIONS.changePasswordFailed:
            return {
                ...state,
                isChangePassword:false
            }
        case API_ACTIONS.changePasswordSuccess:
            return {
                ...state,
                isChangePassword:true
            }

            case API_ACTIONS.activateMailStarted:
                return {
                    ...state,                   
                }
            case API_ACTIONS.activateMailFailed:
                return {
                    ...state,
                    activateError:action.data
                }
            case API_ACTIONS.activateMailSuccess:
                return {
                    ...state,
                    isActivated:true
                }
            case API_ACTIONS.setCountryCode:
                return {
                    ...state,
                    country:action.data
                }
        default:
            return state;
}
}
export default authReducer;