import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import JSZip from "jszip";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import logo from "../../assets/img/logo.svg";
import { useRef } from "react";
import LoadingSpinner from "../../componants/LoadingSpinner";
import { FetchWishCard, clearPDF, getPdf } from "../../actions";
import * as FaIcons from "react-icons/fa";
const PDFExporter = ({isToggled,handleClose, code, isSignPage = false, wishCardData }) => {
	const [loadingMessage, setLoadingMessage] = useState("");
	const dispatch = useDispatch();
	const wishCardDataDetails = useSelector(
		(state) => state.ordercarddetails.wishCardData
	);
	const pdfDetails = useSelector((state) =>state.pdf);
	const [cardDetails, setCardDetails] = useState(null);
	useEffect(() => {
		if (isSignPage) {
			setCardDetails(wishCardData);
		} else {
			setCardDetails(wishCardDataDetails);
		}
	}, [wishCardData, wishCardDataDetails, isSignPage]);

	const pdfref = useRef();
	const handleDownloadPDF = () => {
		// setIsLoading(true);
		// setLoadingMessage("Please Wait While Generating PDF...");

		// const pages = pdfref.current.children;
		// const pdf = new jsPDF("p", "mm", "a4", true);
		// let width = pdf.internal.pageSize.getWidth();

		// for (let i = 0; i < pages.length; i++) {
		// 	const page = pages[i];
		// 	const canvas = await html2canvas(page, {
		// 		useCORS: true,
		// 		allowTaint: true,
		// 		proxy: "https://cors-anywhere.herokuapp.com/",
		// 	});
		// 	const imgData = canvas.toDataURL("image/jpeg", 1);
		// 	const imgWidth = width;

		// 	let imgHeight;
		// 	if (page.classList.contains("signeturepage")) {
		// 		// Calculate height based on the actual content inside the canvas
		// 		const contentHeight = getContentHeight(canvas);
		// 		imgHeight = contentHeight;
		// 	} else {
		// 		// Preserve aspect ratio for other pages
		// 		imgHeight = (canvas.height * imgWidth) / canvas.width;
		// 	}

		// 	pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, "", "FAST");
		// 	if (i < pages.length - 1) {
		// 		pdf.addPage();
		// 	} else {
		// 		pdf.save("wishbook-egroupgreeting.pdf");
		// 		setIsLoading(false);
		// 		setLoadingMessage("");
		// 		handleClose();
		// 	}
		// }
		dispatch(getPdf(code))
	};
	const handlePdfStop = () =>{
		dispatch(clearPDF());
	}
	const handleDownloadPDFAndImages = async () => {
		const imagesZip = await generateImages();
		imagesZip.generateAsync({ type: "blob" }).then((blob) => {
			const zipFileName = "wishbook-images-egroupgreeting.zip";
			saveAs(blob, zipFileName);
			// setIsLoading(false);
			setLoadingMessage("");
			handleClose();
		});
	};
	const generateImages = async () => {
		// setIsLoading(true);
		setLoadingMessage("Please Wait While Generating Images...");
		const pages = pdfref.current.children;
		const zip = new JSZip();

		for (let i = 0; i < pages.length; i++) {
			const page = pages[i];
			const canvas = await html2canvas(page, {
				useCORS: true,
				allowTaint: true,
				proxy: "https://cors-anywhere.herokuapp.com/",
			});

			// Convert canvas to image data URL
			const imgData = canvas.toDataURL("image/jpeg");

			// Add image to the zip file with a unique name
			zip.file(`image_${i + 1}.jpeg`, imgData.split(",")[1], { base64: true });
		}
		return zip;
	};
	const getContentHeight = (canvas) => {
		const contentHeight = canvas.getBoundingClientRect().height;
		// You may need to adjust this value based on your specific requirements
		const maxHeight = 200; // Set a maximum height to prevent extremely large pages
		return Math.min(contentHeight, maxHeight);
	};
	useEffect(() => {
		if (code !== undefined && !isSignPage) {
			dispatch(FetchWishCard(code));
		}
	}, [code, isSignPage]);
	return (
		<>
			<Modal show={isToggled} onHide={()=>handleClose()}>
				<Modal.Header closeButton>
					<Modal.Title>Preview</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="pdf-inner position-relative">
						<div className="pdf-book" ref={pdfref}>
							<div
								style={{
									pageBreakBefore: "always",
									background: "#ffffff",
									border: "1px solid #ccc",
								}}
								className="page"
								data-density="hard"
							>
								<table width="100%" style={{ borderCollapse: "collapse" }}>
									<tr>
										<td>
											<img
												src={cardDetails?.card_details?.image}
												style={{ width: "100%", height: "842" }}
												alt="card"
											/>
										</td>
									</tr>
								</table>
							</div>
							{cardDetails?.card?.sing?.map((page, index) => {
								return (
									<>
										<div
											style={{
												pageBreakBefore: "always",
												background: "#ffffff",
												border: "1px solid #ccc",
											}}
											key={"cards" + index}
											className="page signeturepage"
											data-density="hard"
										>
											<table
												width="100%"
												style={{ borderCollapse: "collapse" }}
											>
												<tr>
													<td>
														<h4
															className="text-start mt-3"
															style={{
																textAlign: "left",
																fontFamily: page?.message?.fontfamily,
																fontSize: page.message?.fontsize,
																color: page.message?.fontcolor,
															}}
														>
															{page.message?.message}
														</h4>
													</td>
												</tr>
												<tr>
													<td
														style={{
															textAlign: "right",
															fontFamily: page?.message?.fontfamily,
															fontSize: page.message?.fontsize,
															color: page.message?.fontcolor,
														}}
													>
														~ {page.message?.signerName}
													</td>
												</tr>
											</table>
										</div>
									</>
								);
							})}
							<div
								style={{
									pageBreakBefore: "always",
									background: "#ffffff",
									border: "1px solid #ccc",
								}}
								className="page"
								data-density="hard"
							>
								<table
									width="100%"
									style={{ borderCollapse: "collapse", textAlign: "center" }}
								>
									<tr>
										<td>
											<img src={logo} alt="egroup Greetings Logo" />
										</td>
									</tr>
									<tr>
										<td>
											<p>Save Paper, Save Tree, Save Earth</p>
										</td>
									</tr>
									<tr>
										<td>egroupgreeting.com</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-sm btn-secondary mb-3 me-3"
						onClick={() => {handleDownloadPDF();handleClose();}}
					>
						<FaIcons.FaFilePdf className="me-1" /> Download PDF
					</button>
				</Modal.Footer>
			</Modal>
			{pdfDetails?.isLoading || pdfDetails.iserror && (
				<div className="download-loading-overlay">
					<LoadingSpinner />
					{pdfDetails?.err !== null && (
                        <div className="">
							<Button className="home_btn table-btn mx-2" onClick={() => handleDownloadPDF()}>
								Retry
							</Button>
							<Button className="home_btn table-btn" onClick={()=>handlePdfStop()}>Exit</Button>
							<p>{JSON.stringify(pdfDetails?.err)}</p>
                        </div>
					)}
				</div>
			)}
		</>
	);
};

export default PDFExporter;
