import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    isStickerLoading:false,
    err: null,
    data:[],
    stickerData:[],
    stickerErr:null,
};

const giphyReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getGifStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getGifSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getGifFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
        case API_ACTIONS.getStickerStarted:
            return {
                ...state,
                isStickerLoading:true
            }
        case API_ACTIONS.getStickerSuccess:
            return {
                ...state,
                isStickerLoading:false,
                stickerData:action.data
            }
        case API_ACTIONS.getStickerFailed:
            return {
                ...state,
                isStickerLoading:false,
                stickerErr:action.data
            }
            default:
                return state;
    }

};
export default giphyReducer;