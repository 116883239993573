import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DiamondImg from "../../assets/img/Diamond.png";

import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
	B2BProcess,
	FetchPlanHistory,
	OrderCard,
	OrdercardPlanUpdate,
	createStripeId,
	getPricePlan,
	setCountry,
} from "../../actions";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../componants/CheckoutForm";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const PricingPopup = ({ currentPlan, planid, code, col,handleClose }) => {
	const Pricingplan = useSelector((state) => state.pricingPlan.data);
	const PlanHistory = useSelector((state) => state.pricingPlan.ownedPlans);
	const [isCustomPlan, setIsCustomPlan] = useState(false);
	const currencyType =  useSelector(state => state.authentication.country);
	const [stripeSecret, setStripeSecret] = useState(null);
	const [difference, setDifference] = useState(0);
	const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
	const dispatch = useDispatch();

	const handleClearStripe = () => {
		setStripeSecret(null);
	};

	useEffect(() => {
		dispatch(getPricePlan());
		// dispatch(FetchPlanHistory());
	}, []);

	const handleStripe = (clientSecret) => {
		setStripeSecret(clientSecret);
	};
	const handleStripePayment = async (id, price) => {
		const isLogin = localStorage.getItem("isLogin");
		if (isLogin) {
			if(currencyType === "INR"){
				const { value: gst } = await Swal.fire({
					title: "Do you have a GST Number?",
					text: "If yes, Please Input your GSTIN",
					input: "text",
					inputPlaceholder: "Enter your GST",
					showCancelButton: true,
					cancelButtonText: "Skip and Continue",
					inputValidator: (value) => {
						if (!value) {
							return "GST number is required";
						}
	
						// Define the GST regex pattern for validation
						const gstRegex =
							/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
	
						if (!gstRegex.test(value)) {
							return "Invalid GST format. Please enter a valid GSTIN.";
						}
					},
				});
				if (gst) {
					// console.log("ere");
					dispatch(
						OrdercardPlanUpdate(
							id,
							code,
							"billDesk",
							price,
							currencyType,
							handleStripe,
							gst,
							handleClose,
							false
						)
					);
				} else {
					dispatch(
						OrdercardPlanUpdate(
							id,
							code,
							"billDesk",
							price,
							currencyType,
							handleStripe,
							"",
							handleClose,
							false
						)
					);
				}
			}
			else{
				dispatch(
					OrdercardPlanUpdate(
						id,
						code,
						"stripe",
						price,
						currencyType,
						handleStripe,
						"",
						handleClose,
						false
					)
				);
			}	
		} else {
			toast.error("Please Login to Buy Plans");
		}
	};
	const handleUpgrade = async (id, price) => {
		const isLogin = localStorage.getItem("isLogin");
		if (isLogin) {
			dispatch(
				OrdercardPlanUpdate(
					id,
					code,
					currencyType == "INR" ? "billDesk" : "stripe",
					price,
					currencyType,
					handleStripe,
					"",
                    handleClose,
                    true
				)
			);
		} else {
			toast.error("Please Login to Buy Plans");
		}
	};
	function getPriceDifference(planId, planList) {
		const currentPlan = planList.find((obj) => obj.plan_id === planId);
		const currentIndex = planList.indexOf(currentPlan);

		if (currentIndex !== -1 && currentIndex < planList.length - 1) {
			const nextPlan = planList[currentIndex + 1];
            // console.log(nextPlan)
			let priceDifference;
			if (currencyType === "INR") {
				priceDifference = nextPlan.inr_price - currentPlan.inr_price;
                // console.log("Inrdifference",priceDifference)
			} else {
				priceDifference = nextPlan.price - currentPlan.price;
			}
			return priceDifference;
		}

		return null;
	}
	useEffect(() => {
		if (Pricingplan?.length > 0) {
			let val = getPriceDifference(planid, Pricingplan);
			setDifference(val);
		}
	}, [planid, Pricingplan,currencyType]);
	function QuantityArea({ id, price, isINR, handleStripePayment }) {
		return (
			<>
				<div className="qty-wrapper">
					<div className="total-price">
						{!isINR ? "$" : "₹"}
						{price}
					</div>
				</div>
				<div className="text-center mt-4">
					<Button
						className="btn btn-lg btn-primary"
						onClick={() => handleStripePayment(id, price)}
					>
						Upgrade
					</Button>
				</div>
			</>
		);
	}
	function handleCurrencyChange(e){
        dispatch(setCountry(e.target.value))
    }

	return (
		<>
										<Row className="justify-content-center">
									<Form.Select className="currency-change" aria-label="INR" defaultValue={currencyType} onChange={(e)=>handleCurrencyChange(e)}>
										<option value="INR">INR</option>
										<option value="USD">USD</option>
									</Form.Select>
								</Row>
			<Row className={"mt-3 justify-content-center"}>
				<>
					{Pricingplan?.map((elem, index) => {
						if (parseInt(elem.plan_id) > parseInt(currentPlan)) {
							return (
								<Col
									className={`${col ? col : "col-md-6 col-lg-6"}`}
									key={"plan" + index + "type"}
								>
									<div
										className={`pricing-card selected-version ${
											index === 1 && "bg-light-orange"
										}`}
									>
										<img src={DiamondImg} alt={""} />
										<div className="pricing-price-selection mt-4">
											<span className="price-list">
												<span className="per-post">
													{currencyType !== "INR"
														? `$${elem.price}`
														: `₹${elem.inr_price}`}
												</span>
											</span>
											<span className="per-post">/Card</span>
										</div>
										<h4>{elem.name}</h4>
										<ul className="offer-list">
											<li>
												<i className="fas fa-check"></i>{" "}
												<p>
													Up to{" "}
													{!elem.number_of_sugnatures === "0"
														? elem.number_of_sugnatures
														: "Unlimited"}{" "}
													messages/ Signatures
												</p>
											</li>
											<li>
												<i className="fas fa-check"></i>{" "}
												<p>Add photos and GIFs</p>
											</li>
											<li>
												<i className="fas fa-check"></i>{" "}
												<p>All fonts, Themes</p>
											</li>
											<li>
												<i className="fas fa-check"></i>{" "}
												<p>Advance Scheduling</p>
											</li>
											<li>
												<i className="fas fa-check"></i>{" "}
												<p>Premium backgrounds</p>
											</li>
											<li>
												<i className="fas fa-check"></i>{" "}
												<p>Option to Upgrade any time</p>
											</li>
											<li>
												<i
													className={`fa-solid ${
														elem.name !== "Ultimate Plan"
															? "fa-xmark"
															: "fa-check"
													}`}
												></i>{" "}
												<p>Unlimited pages</p>
											</li>
											<li>
												<i
													className={`fa-solid ${
														elem.name !== "Ultimate Plan"
															? "fa-xmark"
															: "fa-check"
													}`}
												></i>{" "}
												<p>Download in PDF and Image format</p>
											</li>
											<li>
												<i
													className={`fa-solid ${
														elem.name !== "Ultimate Plan"
															? "fa-xmark"
															: "fa-check"
													}`}
												></i>{" "}
												<p>Customize</p>
											</li>
										</ul>
										{elem.name !== "FREE PLAN" && (
											<>
												{elem.number_of_plan > 0  && currentPlan < 2 ? (
													<div className="text-center mt-4">
														<p>Plans Available : {elem.number_of_plan}</p>
														<Button
															className="btn btn-lg btn-primary"
															onClick={() =>
																handleUpgrade(
																	elem.plan_id,
																	currencyType === "INR"
																		? parseInt(planid) === 2
																			? difference
																			: parseFloat(elem.inr_price)
																		: parseInt(planid) === 2
																		? difference
																		: parseFloat(elem.price)
																)
															}
														>
															Proceed with {elem.name}
														</Button>
													</div>
												) : (
													<QuantityArea
														id={elem.plan_id}
														name={elem.name}
														price={
															currencyType === "INR"
																? parseInt(planid) === 2
																	? difference
																	: parseFloat(elem.inr_price)
																: parseInt(planid) === 2
																? difference
																: parseFloat(elem.price)
														}
														isINR={currencyType === "INR"}
														handleStripePayment={handleStripePayment}
													/>
												)}
											</>
										)}
									</div>
								</Col>
							);
						}
					})}
				</>
			</Row>

			{stripeSecret !== null ? (
				<>
					<Modal show={true} centered size={"xl"}>
						<Modal.Body className="section-title-area sign-in-modal">
							<Elements
								stripe={loadStripe(stripeKey)}
								options={{ clientSecret: stripeSecret.clientSecret }}
							>
								<CheckoutForm
									orderid={stripeSecret.order_id}
									isOrderCard={false}
									isUpdatePlan={true}
									clearStripe={handleClearStripe}
								/>
							</Elements>
						</Modal.Body>
					</Modal>
				</>
			) : null}
		</>
	);
};

export default PricingPopup;
