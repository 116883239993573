import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "./contact.css";
import Form from 'react-bootstrap/Form';
import { useDispatch } from "react-redux";
import { contactEgroup } from "../../actions";
import { Link } from "react-router-dom";
import Select from "react-select";

const Contact = () => {

    let SiteInfoEmail = process.env.REACT_APP_COMPANY_MAIL;
    let SiteInfoAddress = process.env.REACT_APP_COMPANY_ADDRESS;
    let SiteInfoMobile = process.env.REACT_APP_COMPANY_MOBILE;
    // console.log(SiteInfoAddress, SiteInfoEmail)
    const dispatch = useDispatch();
    const contactSchema = yup.object().shape({
        name: yup
            .string()
            .min(3, "First name should contain at least 3 characters")
            .required("Please Enter your First Name"),

        email: yup
            .string().email()
            .required("Please Enter Email"),

        message: yup
            .string()
            .required("Please Enter Message"),

        category: yup
            .string()
            .required("Please select a category"),
    });
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        mode: "all",
        resolver: yupResolver(contactSchema)
    });
    const onSubmit = (data) => {
        dispatch(contactEgroup(data, reset))
    };
    const categories = [
        { value: "account", label: "Account Related" },
        { value: "payments", label: "Payments Related" },
        { value: "technical", label: "Technical Issues" },
        { value: "general", label: "General" },
        { value: "feedback", label: "Feedback And Suggestions" },
      ];
      const customStyles = {
        control: (provided, state) => ({
          ...provided,
          background: state.isFocused ? "#fff" : "#fff", // Set your theme color
          border: state.isFocused ? "1px solid #dfd9d9" : "1px solid #dfd9d9",
          boxShadow: state.isFocused ? "none" : null,
          "&:hover": {
            border: "1px solid #FFE4E4",
          },
        }),
        option: (provided, state) => ({
          ...provided,
          background: state.isSelected ? "#F75252" : state.isFocused ? "#FFE4E4" : null,
          color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "#000", // Set the color for the selected value
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          background: "#F75252", // Set the color for the indicator separator
        }),
        // Add more styles for other components as needed
      };
      
    const YOUR_LAT = 14.22344356977779
    const YOUR_LON = 76.40961879224186
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.Contact_Title}</title>
            </Helmet>
            <section className="contact_us">
                <Container>
                    <Row>
                        <Col lg={12} xl={10} className="offset-xl-1">
                            <div className="contact_inner">
                                <Row className="justify-content-between">
                                    <Col md={10}>

                                        <Form className="contact_form_inner" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="contact_field">
                                                <h3>Contact Us</h3>
                                                <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                                <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">

                                                    <Form.Control aria-invalid={errors.firstname ? "true" : "false"} type="text" placeholder="Enter Name" {...register("name", { required: true })} />
                                                    <p className='form-error-message mt-1'>{errors.name?.message}</p>
                                                </Form.Group>
                                                <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                                                    <Form.Control aria-invalid={errors.email ? "true" : "false"} type="email" placeholder="Enter Email" {...register("email", { required: true })} />
                                                    <p className='form-error-message mt-1'>{errors.email?.message}</p>
                                                </Form.Group>
                                                <Select
                                                    {...register("category", { required: true })}
                                                    options={categories}
                                                    placeholder="Select Category"
                                                    className="mb-3"
                                                    styles={customStyles}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    isDisabled={false}
                                                    onChange={(selectedOption) => setValue("category", selectedOption.value)}
                                                />
                                                <div className="mb-3">
                                                    <textarea className="form-control form-group" placeholder="Message" {...register("message", { required: true })}></textarea>
                                                    <p className='form-error-message mt-1'>{errors.message?.message}</p>
                                                </div>
                                                <button type="submit" className="btn btn-primary w-100">Send</button>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col md={2}>
                                        <div className="right_conatct_social_icon d-flex align-items-end">

                                        </div>
                                    </Col>
                                </Row>
                                {/* <div className="contact_info_sec">
                                    <h4>Contact Info</h4>
                                    <div className="d-flex info_single align-items-center">
                                        <i className="fas fa-headset"></i>
                                        <span>{SiteInfoMobile}</span>
                                    </div>
                                    <div className="d-flex info_single align-items-center">
                                        <i className="fas fa-envelope-open-text"></i>
                                        <span>{SiteInfoEmail}</span>
                                    </div>
                                    <div className="d-flex info_single align-items-center">
                                        <i className="fas fa-map-marked-alt"></i>
                                        <span>{SiteInfoAddress}</span>
                                    </div>
                                    <ul className="socil_item_inner info_single mb-0 d-flex">
                                        <li><Link to="/"><i className="fab fa-facebook-square"></i></Link></li>
                                        <li><Link to="/"><i className="fab fa-instagram"></i></Link></li>
                                        <li><Link to="/"><i className="fab fa-twitter"></i></Link></li>
                                    </ul>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="map_sec">
                <div className="container">
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="section-title-area text-center mb-5">
                                <h2 className="mb-3 mb-lg-4">Find Us on Google Map</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quo beatae quasi assumenda, expedita aliquam minima tenetur maiores neque incidunt repellat aut voluptas hic dolorem sequi ab porro, quia error.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="col-md-10 offset-md-1">
                            <div className="map_inner">
                                <div className="map_bind">
                                    <iframe title="Get Direction" src={`https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=6CF5+9WQ,%20CK%20Pura,%20Chitradurga,%20Karnataka%20577501+(Sangameshwara%20Layout)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`} width="100%" height="450" frameBorder="0" style={{ border: '0' }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </section>

        </>
    );
}

export default Contact;