import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./index.css";
import imageUrl from '../../assets/img/404_grid.gif'
import { Link } from "react-router-dom";
const NotFound = () => {
    return (
        <section className="page_404">
            <Container>
                <Row className="justify-content-center">
                    <Col sm={'10'} className="col-sm-offset-1  text-center">
                            <h1 className="text-center main-error-text">404</h1>
                        <div className="four_zero_four_bg" style={{backgroundImage: `url(${imageUrl})`}}>
                        </div>
                        <div className="contant_box_404">
                            <h3 className="h2 mb-4">
                                Look like you're lost
                            </h3>
                            <p>Sorry, this page doesn't exist!</p>
                            <Link to="/" className="btn btn-lg btn-primary mt-3">Back to Home</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default NotFound;