import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BannerLottie from "../assets/img/banner-lottie.json";

import { TypeAnimation } from "react-type-animation";
import { Player, Controls } from '@lottiefiles/react-lottie-player';

const Wishboard = () => {
    return (
        <>
            <section className="wishboard-area">
                <Container>
                    <Row className="g-lg-5">
                        <Col lg={7} md={7}>
                            <Player
                                autoplay
                                loop
                                src={BannerLottie}
                                style={{ height: '', width: '100%' }}
                            >
                            </Player>
                            {/* <img src={sectionImg} className="img-fluid" alt="wishcards" /> */}
                            {/* <Row className="wishboard-card-listing g-lg-5">
                                <Col lg={6} sm={6} md={6} className={'mt-md-5'}>
                                    <Card className={'wishboard-card border-0 mb-4'}>
                                        <Card.Img variant="top" src={sectionImg1} />
                                        <Card.Body>
                                            <Card.Title>I hope all your wishes and dreams come true.</Card.Title>
                                            <Card.Text className="text-end">
                                                ~amayra
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className={'wishboard-card border-0 mb-4'}>
                                        <Card.Img variant="top" src={sectionImg3} />
                                        <Card.Body>
                                            <Card.Title>Happy birthday to you! Make it awesome!</Card.Title>
                                            <Card.Text className="text-end">
                                                ~amayra
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col lg={6} sm={6} md={6}>
                                    <Card className={'wishboard-card border-0 mb-4'}>
                                        <Card.Img variant="top" src={sectionImg2} />
                                        <Card.Body>
                                            <Card.Title>Wishing you an amazing year Happy birthday dear, from all of us!</Card.Title>
                                            <Card.Text className="text-end">
                                                ~mayra
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className={'wishboard-card border-0'}>
                                        <Card.Img variant="top" src={sectionImg4} />
                                        <Card.Body>
                                            <Card.Title>Wish you the best of birthdays, from our whole crew.</Card.Title>
                                            <Card.Text className="text-end">
                                                ~amayra
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col lg={5} md={5}>
                            <div className="wishboard-text-area">
                                <div className="section-title-area">
                                    <h2 className="mb-4"><span className="title-design position-relative">WishList for</span> <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed once, initially
                                        'Birthday',
                                        1000,
                                        'Farewell',
                                        1000,
                                        'Work anniversary',
                                        1000,
                                        'Promotion',
                                        1000,
                                        'Achivements',
                                        1000,
                                    ]}
                                    speed={20}
                                    style={{color:'#F75252'}}
                                    repeat={Infinity}
                                /></h2>
                                    <p className="title-sub-text">Convert Your eCard to Wishboard to get the single view all the wishes from your team</p>
                                    {/* <div className="wishboard-list">
                                        <p className="mb-2"><span className="me-2">1</span>Upload unlimited messages & pictures</p>
                                        <p className="mb-2"><span className="me-2">2</span>Play as a slideshow with music</p>
                                        <p className="mb-2"><span className="me-2">3</span>Share hassle-free with an online URL</p>
                                    </div> */}
                                    <div className="text-start mt-lg-5 mt-4">
                                        <Link to="/wishlist" className="btn btn-lg btn-secondary">Wishlist</Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Wishboard;