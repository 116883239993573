import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SvgIcon from "./SvgIcon";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";
import { TbAwardFilled } from "react-icons/tb";

import shape from '../assets/img/light-grey-curve-line.svg';

const WhyUsBusiness = () => {
    return (
        <>
            <section className="whyus-area  position-relative">
                <div class="light-grey-wave-top"><img src={shape} alt="shape" /></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="section-title-area text-center mb-5">
                                <h2 className="mb-4"><span className="title-design position-relative">Why </span> eGroup Greeting Cards </h2>
                                <p>We specialized in fostering connections among companies and employees, laying the foundation for winning teams. We have been the preferred partner for organizations seeking workplace greetings and appreciation solutions for their employees.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-lg-5">
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <MdIcons.MdPeopleOutline />
                                </div>
                                <h3 className="mb-3">Everyone matter</h3>
                                <p>Appreciation builds trust, loyalty, and deepens the relationships. Maximize performance and productivity.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <MdIcons.MdCelebration  />
                                </div>
                                <h3 className="mb-3">Celebrate every Occasion </h3>
                                <p>Celebrate all wins, achievements small or big doesn’t matte</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <TbAwardFilled />
                                </div>
                                <h3 className="mb-3">Make them feel special</h3>
                                <p>Social recognition, Small act of timely wishes can make profound impact.</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <MdIcons.MdOutlineConnectWithoutContact  />
                                </div>
                                <h3 className="mb-3">Remove Frictions</h3>
                                <p>Give leaders, teams a tool to engage and simplify the process. Say no to manual card passing around desks.</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <GiIcons.GiHumanPyramid  />
                                </div>
                                <h3 className="mb-3">Promote core values</h3>
                                <p>Make peer to peer recognitions, appreciation part of organization DNA.</p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="empty-card">
                                <div className="card-icon" >
                                    <FaIcons.FaPeopleArrows  />
                                </div>
                                <h3 className="mb-3">Build connected culture</h3>
                                <p>Unite, engage your people working in centralized, distributed hybrid mode</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    );
}

export default WhyUsBusiness;