import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { facebookLogin, googleLogin, login } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GoogleLogin } from "react-google-login";
import { toast } from "react-toastify";
import * as FaIcons from "react-icons/fa";

const Login = ({ show, onHide, handleSignUp, handleForgot,handleReset }) => {
    let facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });

    const [emailError, setemailErrors] = useState("");
    const [passwordError, setpasswordErrors] = useState("");

    function getUserData() {
        window.FB.api('/me', { fields: 'first_name,last_name,email' }, (response) => {
            responseFacebook(response);
        });
    }
    const handleSuccess = () => {
        // reset();
        setemailErrors("");
        setpasswordErrors("");
        setLoginData({ email: "", password: "" });
         onHide();
    };
    const FacebookButton = () => {
        const onLoginClick = () => {
            window.FB.login((response) => {
                if (response.authResponse) {
                    document.getElementById('loginBtn').style.display = 'none';
                    getUserData();
                }
            }, { scope: 'email,public_profile', return_scopes: true });
        };

        useEffect(() => {
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: facebookAppId,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v2.2'
                });
            };
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }, []);

        return (
            <>
                <div className="col-12">
                    <button onClick={onLoginClick} id="loginBtn" className="facebook-login-btn"><FaIcons.FaFacebookF /> Facebook</button>
                </div>
            </>
        );
    };


    const handleLogin = (value, type) => {
        setLoginData({ ...loginData, [type]: value });
    };

    const responseMessage = (response) => {
        let id = response.profileObj.googleId;
        let firstname = response.profileObj.givenName;
        let lastname = response.profileObj.familyName;
        let email = response.profileObj.email;
        dispatch(
            googleLogin({
                id: id,
                firstname: firstname,
                lastname: lastname,
                email: email,
            },handleSuccess)
        );
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    const dispatch = useDispatch();
    const authData = useSelector((state) => state.authentication);
    const responseFacebook = (response) => {
        console.log('facebook response submit response',response);
        if (response) {
            dispatch(facebookLogin(response,handleSuccess));
        }
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email.match(emailRegex)) {
            setemailErrors("Email is not valid");
            return false;
        } else {
            setemailErrors("");
            return true;
        }
    };

    const validatePassword = (password) => {
        // Password validation rules
        const minPasswordLength = 6;
        if (password.length < minPasswordLength) {
            setpasswordErrors("Password is not valid");
            return false;
        } else {
            setpasswordErrors("");
            return true;
        }
    };
    const onSubmit = (data) => {
        const isEmailValid = validateEmail(loginData.email);
        const isPasswordValid = validatePassword(loginData.password);
        if (isEmailValid && isPasswordValid) {
            dispatch(login(loginData, handleSuccess));
        }
        // onHide();
    };



    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body className="section-title-area sign-in-modal">
                <button type="button" class="popup-btn-close btn-close" onClick={onHide} aria-label="Close"></button>
                <h2 className="text-center mb-5">
                    <span className="title-design position-relative">Sign</span> In
                </h2>
                <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter Your Email Address"
                        onChange={(e) => handleLogin(e.target.value, "email")}
                    />
                    <p className="form-error-message mt-1">
                        {authData.loginError !== "Incorrect password!" &&
                            authData.loginError !== "none"
                            ? <>{authData.loginError === "The email is not verified" ? (<div>{authData.loginError} <span className="click-div" onClick={()=>handleReset()}>resend email</span></div>):(<div>{authData.loginError}</div>)}</>
                            : ""}
                        {emailError}
                    </p>
                </Form.Group>
                <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter Your Password"
                        onChange={(e) => handleLogin(e.target.value, "password")}
                    />
                    <p className="form-error-message mt-1">
                        {authData.loginError === "Incorrect password!"
                            ? `${authData.loginError} | `
                            : ""}
                        {passwordError}
                    </p>
                </Form.Group>
                <Form.Group
                    className="mb-3 modal-form text-end"
                    controlId="formBasicCheckbox"
                >
                    <button
                        type="button"
                        className="btn outline-btn"
                        onClick={handleForgot}
                    >
                        Forgot Password?
                    </button>
                </Form.Group>
                <Button
                    variant="primary"
                    onClick={() => onSubmit()}
                    className="w-100 mt-3"
                >
                    LOGIN
                </Button>
                <div className="sign-up-sec mt-4 d-flex align-items-center justify-content-between">
                    <p className="account-link mb-0">Don't have an account?</p>
                    <button className="btn outline-btn" onClick={handleSignUp}>
                        Sign Up
                    </button>
                </div>
                <Row className="mt-4">
                    <div className="col-12">
                        <div class="centered-text-line">
                            <p>or Login with</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Google"
                            className="google-login-btn"
                            onSuccess={responseMessage}
                            onFailure={errorMessage}
                            cookiePolicy={"single_host_origin"}
                        />
                    </div>
                    <div className="col-md-6">
                        <FacebookButton />
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default Login;
