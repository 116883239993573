import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Category.css";
import { Link } from "react-router-dom";
import Cardwork from "../../componants/Cardwork";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryList } from "../../actions/index.js";
import {Helmet} from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";
import Header from "../../componants/Header";
import Footer from "../../componants/Footer";

const Category = () => {
    const dispatch = useDispatch();
    const categoryList = useSelector((state) => state.category.data);
    useEffect(() => {
        dispatch(getCategoryList());
    }, []);

    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.Category_Title}</title>
            </Helmet>
            <section className="main-category-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="section-title-area text-center mb-5">
                                <h2 className="mb-3 mb-lg-4">Choose Your Perfect E-Greeting Card</h2>
                                <p>Explore our Thoughtfully Designed Digital Greeting Cards for Every Occasion</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-3 g-lg-4 justify-content-center">
                        {
                            categoryList?.length > 0 && (
                                <>
                                    {
                                        categoryList.map((elem, index) => {
                                            return (
                                                <Col sm={6} md={4} lg={3} key={'categorycard' + index} >
                                                    <Link to={`listing/${elem.seo}`} className="main-cateogory-card">
                                                        <div className="imageBlock">
                                                            <img src={elem.image} alt="category" />
                                                        </div>
                                                        <h4 className="title">{elem.name}</h4>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    }
                                </>
                            )
                        }
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="text-center mt-5">
                                <Link to={`/category/listing/${categoryList[0]?.seo}`} className="btn btn-lg btn-primary">View All</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Cardwork />
        </>
    );
}

export default Category;