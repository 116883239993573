import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    isLoadingBlogPost:false,
    isLoadingDetails:false,
    err: null,
    blogpostError:null,
    data:[],
    blogpostData:[],
    blogDetails:null,
};

const blogCategoryReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getBlogCategoryListStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getBlogCategoryListSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getBlogCategoryListFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }

            case API_ACTIONS.getBlogListStarted:
            return {
                ...state,
                isLoadingBlogPost:true
            }
        case API_ACTIONS.getBlogListSuccess:
            return {
                ...state,
                isLoadingBlogPost:false,
                blogpostData:action.data
            }
        case API_ACTIONS.getBlogListFailed:
            return {
                ...state,
                isLoadingBlogPost:false,
                err:action.data
            }

            case API_ACTIONS.updateBlogListStarted:
                return {
                    ...state,
                    isupdatingBlogPost:true
                }
            case API_ACTIONS.updateBlogListSuccess:
                return {
                    ...state,
                    isupdatingBlogPost:false,
                    blogpostData:action.data
                }
            case API_ACTIONS.updateBlogListFailed:
                return {
                    ...state,
                    isupdatingBlogPost:false,
                    err:action.data
                }
    

            case API_ACTIONS.getBlogDetailsStarted:
            return {
                ...state,
                isLoadingDetails:true
            }
        case API_ACTIONS.getBlogDetailsSuccess:
            return {
                ...state,
                isLoadingDetails:false,
                blogDetails:action.data
            }
        case API_ACTIONS.getBlogDetailsFailed:
            return {
                ...state,
                isLoadingDetails:false,
                err:action.data
            }
            default:
                return state;
    }

};
export default blogCategoryReducer;