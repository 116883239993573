import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./index.css";
import { Col, Row } from "react-bootstrap";
import User from "../../assets/img/user.png";
import Expiry from "../../assets/img/expiry.png";
import Clock from "../../assets/img/timetable.png";
import Assign from "../../assets/img/assignment.png";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateProfile, getPricePlan } from "../../actions";
import { toast } from "react-toastify";

const UserProfile = () => {
	const dispatch = useDispatch();
	const phoneRegExp =
		/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
	const signupSchema = yup.object().shape({
		firstname: yup
			.string()
			.min(3, "First name should contain at least 3 characters")
			.required("Please Enter your First Name"),
		lastname: yup.string().required("Please Enter your Last Name").test(
			"no-spaces",
			"Last name should not contain spaces",
			(value) => value && !/\s/.test(value)
		  ),
		email: yup.string().email().required("Please Enter Email"),
		telephone: yup
			.string()
			.nullable()
			.optional()
	});
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
		setValue,
	} = useForm({
		mode: "all",
		resolver: yupResolver(signupSchema),
	});
	const authData = useSelector((state) => state.authentication.loggedinData);
	const planPlans = useSelector((state) => state.pricingPlan);
	const onSubmit = (data) => {
		if (
			authData?.firstname !== data.firstname ||
			authData?.lastname !== data.lastname ||
			authData?.email !== data.email ||
			authData?.telephone !== data.telephone
		) {
			// console.log(data);
			dispatch(UpdateProfile({ ...data, customer_id: authData?.customer_id }));
		} else {
			toast.error("Edit data in order to update");
		}
	};
	useEffect(() => {
		if (authData) {
			setValue("firstname", authData?.firstname);
			setValue("lastname", authData?.lastname);
			setValue("email", authData?.email);
			setValue("telephone", authData?.telephone);
		}
	}, [authData]);
	useEffect(() => {
		dispatch(getPricePlan());
		// dispatch(FetchPlanHistory());
	}, []);
	const phoneInputRef = useRef();
	// Check if the phone number input field has a value before validating it
	const validateTelephone = () => {
		if (phoneInputRef.current.value) {
			// Validate the phone number using the yup validation schema
			yup
				.string()
				.matches(
					phoneRegExp,
					"Phone number must be exactly 10 digits and contain only numbers"
				)
				.validate(phoneInputRef.current.value);
		}
	};
	return (
		<>
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center profile_heading_design">
					<img src={User} className="me-3 heading-icon" alt="" />
					{/* <i className="fa-solid fa-user me-3 fs-4 heading-icon"></i> */}
					<h5 className="m-0 heading_board">
                        {(authData?.firstname ?? "") + " " + (authData?.lastname ?? "")}
                    </h5>
				</div>
				<div className="btn btn-primary home_btn">
					<Link to="/home">
						<i className="fa-solid fa-house"></i>Home
					</Link>
				</div>
			</div>
			<hr className="horizontal_line" />
			<div className="account-box-design">
				<p className="text-dark">
					{/* NO ACTIVE PACK: SAVE UPTO 70% With PREPAID PACKS. */}
					{/* <Link to="/pricing" className="details_link"> */}
						YOUR PLAN DETAILS
					{/* </Link> */}
				</p>
				<hr />
				<Row>
					{planPlans.data.length > 0 ? (
						<>
							{planPlans.data.map((elem) => {
								return (
									<Col lg={4} md={6} sm={6} key={elem.plan_id}>
										<div className="d-flex align-items-center flex-row icon-box-design">
											<span className="profile_list">
												<img src={Expiry} className="me-3" alt="" />
											</span>
											<div className="d-flex flex-column text-left">
												<h6 className="m-0 text-start">{elem.name}</h6>
												<span>{elem.number_of_plan} Plans Left</span>
											</div>
										</div>
									</Col>
								);
							})}
						</>
					) : null}
				</Row>
			</div>
			<div className="account-setting account-box-design">
				<h4 className="mb-3">Account Settings</h4>
				<form
					className="d-flex align-items-center flex-wrap account-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
						<Form.Label>First Name</Form.Label>
						<Form.Control
							aria-invalid={errors.firstname ? "true" : "false"}
							type="text"
							placeholder="Enter Name"
							{...register("firstname", { required: true })}
						/>
						<p className="form-error-message mt-1">
							{errors.firstname?.message}
						</p>
					</Form.Group>
					<Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
						<Form.Label>Last Name</Form.Label>
						<Form.Control
							aria-invalid={errors.lastname ? "true" : "false"}
							type="text"
							placeholder="Enter Name"
							{...register("lastname", { required: true })}
						/>
						<p className="form-error-message mt-1">
							{errors.lastname?.message}
						</p>
					</Form.Group>
					<Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
						<Form.Label>Email</Form.Label>
						<Form.Control
							aria-invalid={errors.email ? "true" : "false"}
							type="email"
							placeholder="Enter Email"
							value={authData?.email}
							disabled
						/>
						<p className="form-error-message mt-1">{errors.email?.message}</p>
					</Form.Group>
					<Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
						<Form.Label>Telephone</Form.Label>
						<Form.Control
							ref={phoneInputRef}
							aria-invalid={errors.telephone ? "true" : "false"}
							type="tel"
							maxLength={10}
							minLength={10}
							placeholder="Phone number"
							{...register("telephone")}
							onBlur={validateTelephone}
						/>
						<p className="form-error-message mt-1">
							{errors.telephone?.message}
						</p>
					</Form.Group>
					<Button type="submit" className="mt-3 home_btn">
						Update
					</Button>
				</form>
			</div>
			<div className="account-setting">
				<h4 className="mb-4 mt-lg-5 mt-md-5 mt-sm-5">
					Email Notification Settings
				</h4>
				<div className="d-flex align-items-center justify-content-between">
					<div className="notification_text">
						<span>Features & Updates</span>
						<p>Updates about new features and more.</p>
					</div>
					<div className="pricing-toggle-btn user_toggle">
						<label className="switch">
							<input type="checkbox" />
							<span className="slider round"></span>
						</label>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between">
					<div className="notification_text">
						<span>Marketing & Promotional</span>
						<p>Updates about new offers and discounts.</p>
					</div>
					<div className="pricing-toggle-btn user_toggle">
						<label className="switch">
							<input type="checkbox" />
							<span className="slider round"></span>
						</label>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between">
					<div className="notification_text">
						<span>Events Reminders</span>
						<p>
							Get reminders on upcoming birthdays,work anniversaries and other
							events.
						</p>
					</div>
					<div className="pricing-toggle-btn user_toggle">
						<label className="switch">
							<input type="checkbox" />
							<span className="slider round"></span>
						</label>
					</div>
				</div>
				{/* <Button type="button" className="mt-3 home_btn">Update</Button> */}
			</div>
		</>
	);
};

export default UserProfile;
