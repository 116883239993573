import React, { useRef, useEffect, useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import * as FaIcons from "react-icons/fa";
import HTMLFlipBook from "react-pageflip";
import logo from "../../assets/img/logo.svg";
import ticket from "../../assets/img/ticket.png";
import "./index.css";
import $ from 'jquery'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSignature, FetchWishCard, handleSignCard, handleUpdateCard, sendOrderCard } from "../../actions";
import LoadingSpinner from "../../componants/LoadingSpinner";
import EmptyState from "../../componants/EmptyState";
import SignCardEdit from "./SignCardEdit";
import Invites from "../../componants/Invites"
import { BASE_URL } from "../../constants/constant";
import ReactCanvasConfetti from 'react-canvas-confetti';
import { Form } from "react-bootstrap";
import Edit from '../../assets/img/Edit Row.svg'
import { toast } from "react-toastify";
import SignCardUpdate from "./SignCardUpdate";
import ViewBoard from "../../componants/ViewBoard";



const CreatorSign = () => {
    const { code } = useParams();
    const dispatch = useDispatch();
    const wishcardLoading = useSelector(state => state.ordercarddetails.isLoadWishcard);
    const wishCardData = useSelector(state => state.ordercarddetails.wishCardData);
    const [isSign, setIsSign] = useState(false);
    const [currentAction, setCurrentAction] = useState("Load");
    const initState = {
        signed: false, code: "", id: ""
    }
    const [signedData, setSignedData] = useState(initState)
    const [signDetails, setSignDetails] = useState(null)
    const [dimensions, setDimensions] = useState({ width: 400, height: 600 });
    const [creatorSigned,setcreatorSigned] = useState(-1);
    let customer_id = localStorage.getItem('customer_id');
    let email = localStorage.getItem('email');
    let isLogin = localStorage.getItem('isLogin');
    // console.log(isLogin,"logins stats")
useEffect(()=>{
if(isLogin === false || isLogin === null){
    toast.error("Please Login to Sign")
    setTimeout(()=>{
        window.location.href=`${process.env.REACT_APP_APP_URL}`
    },1000)
}
},[isLogin])
    const book = useRef();

    useEffect(() => {
        if (code !== undefined) {
            dispatch(FetchWishCard(code))
        }
    }, [code]);
    useEffect(() => {
        if (code !== undefined) {
            if (localStorage.getItem("signedCard")) {
                let signedVal = JSON.parse(localStorage.getItem("signedCard"));
                if (signedVal.code === code) {
                    setSignedData({ ...signedVal, signed: true })
                }

            } else {
                setSignedData(initState)
            }

        }
    }, [code, wishCardData])






    useEffect(() => {
        if (signedData.signed) {
            let signobj = wishCardData?.card?.sing.find(elem => parseInt(elem.card_sing_id) === parseInt(signedData.id))
            if (signobj) {
                setSignDetails({ ...signobj })
            }

        }
        if(email){
            if(wishCardData?.card?.sing){
                if(wishCardData?.card?.sing.length > 0){
                    let issigned = wishCardData?.card?.sing.find(elem => elem.message?.signerEmail === email)
                    // console.log(issigned,email,"thishh")
                    if(issigned){
                        setcreatorSigned(1)
                    }else{
                        setcreatorSigned(0)
                    }
                }
                else{
                    setcreatorSigned(0)
                }
            }
            
        }
    }, [signedData.signed, wishCardData])
useEffect(()=>{
if(creatorSigned !== -1){
    if(creatorSigned === 0){
        setCurrentAction("Sign")
    }else{
                setTimeout(()=>{
                    window.location.href=`${process.env.REACT_APP_APP_URL}/signcard/${code}`
                },1000)
    }
}
},[creatorSigned])

    const handleMySign = (mediaData,code,card_sing_id,type,onHide) => {
        // console.log(mediaData,"this data")
        if(mediaData.message !== ""){
            dispatch(handleSignCard({ ...mediaData, code: code, card_sing_id: card_sing_id }, onHide));
        }else{
            toast.error('Please add a message')
        }
                 
           
        }
    
    const onHide = () =>{
        window.location.href=`${process.env.REACT_APP_APP_URL}/signcard/${code}`
    }

    return (
        <>
            {
                wishcardLoading ? (<LoadingSpinner />) :
                    wishCardData !== undefined ? (<>
                        <Helmet>
                            <title>{wishCardData.card_details?.meta_title}</title>
                            <meta name="title" content={wishCardData.card_details?.meta_title + " | egroup Greetings"} />
                            <meta name="description" content={wishCardData.card_details?.meta_description} />
                            <meta name="keywords" content={wishCardData.card_details?.meta_keyword} />
                            <meta property="og:title" content={wishCardData.card_details?.meta_title} />
                            <meta property="og:description" content={wishCardData.card_details?.meta_description} />
                            <meta property="og:image" content={wishCardData.card_details?.image} />
                        </Helmet>
                        <section className={`sign-card-area position-relative ${currentAction === "viewboard" && 'pb-0'}`}>
                                <>
                                    <Container>
                                        {
                                            currentAction !== 'viewboard' && currentAction !== "Load" &&(
                                                <div className="text-center signtext-ctm">
                                                    <h1>{wishCardData.card?.cardname}</h1>
                                                    {/* {wishCardData.card?.recieverfname + " " + wishCardData.card?.recieverlname} */}
                                                    <h4>From {wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname}</h4>
                                                </div>
                                            )
                                        }
                                    </Container>
                                    {(() => {
                                        switch (currentAction) {

                                                case 'Sign':
                                                return (
                                                    <Container>
                                                        <SignCardEdit isSender={true} show={true} onHide={()=>onHide()} code={code} from={wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname} id={wishCardData.card_details?.card_id} handleSendCard={handleMySign}/>
                                                    </Container>
                                                );
                                            
                                                case 'Load':return (
                                                <LoadingSpinner />
                                            )
                                                default:
                                                return null;
                                        }
                                    })()}

                                </>
                        </section>

                    </>) : (<EmptyState />)
            }

        </>


    );
}

export default CreatorSign;