import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './index.css';
import Table from 'react-bootstrap/Table';
import Order from '../../assets/img/order.png';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchOrderCard, FetchReceivedOrderCard, sendOrderCard } from '../../actions';
import moment from "moment";
import EmptyState from '../../componants/EmptyState';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as FaIcons from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';
import SvgIcon from '../../componants/SvgIcon';

const GroupOrder = () => {
    const dispatch = useDispatch();
    const receivedcarddetails = useSelector((state) => state.receivedList.data);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState({ name: "", email: "", status: "" });
    const [appliedFilter, setAppliedFilter] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(FetchReceivedOrderCard())
    }, [])
    const handleViewCard = (code) => {
        window.location.href = `${process.env.REACT_APP_APP_URL}/signcard/${code}`;
    }

    const filterData = () => {
        const filteredData = receivedcarddetails.filter((item) => {
            const nameMatch =
                filter.name === "" ||
                item.recieverfname.toLowerCase().includes(filter.name.toLowerCase());
            const emailMatch =
                filter.email === "" ||
                item.recieveremail.toLowerCase().includes(filter.email.toLowerCase());
            const statusMatch = filter.status === "" || item.status === filter.status;
            return nameMatch && emailMatch && statusMatch;
        });
        setFilteredData(filteredData);
        setAppliedFilter(true);
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };
    const clearFilters = () => {
        setFilter({ name: "", email: "", status: "" });
        setFilteredData([]);
        setAppliedFilter(false);
    };
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <img src={Order} className="me-3 heading-icon" />
                    {/* <i className="fa-solid fa-table me-3 fs-4 heading-icon"></i> */}
                    <h5 className='m-0 heading_board'>Group Card Received</h5>
                </div>
                <div className="btn btn-primary home_btn">
                    <Link to="/category"><i className="fa-solid fa-pencil"></i>Start A New Card</Link>
                </div>
            </div>
            <hr />
            <div className="account-box-design change_psw">
                <form className="d-flex align-items-center order-flex">
                    <Form.Group className="me-2 modal-form">
                        <Form.Control
                            name="name"
                            type="text"
                            value={filter.name}
                            placeholder="Recipient Name"
                            onChange={(e) => handleFilterChange(e)}
                        />
                    </Form.Group>
                    <Form.Group className="me-2 modal-form">
                        <Form.Control
                            name="email"
                            type="email"
                            value={filter.email}
                            placeholder="Recipient Email"
                            onChange={(e) => handleFilterChange(e)}
                        />
                    </Form.Group>
                    {/* <Form.Group className="me-2 modal-form">
                            <Form.Control type="date" onChange={(e)=>handleFilterChange("bod",e.target.value)}/>
                        </Form.Group> */}
                    <Form.Select
                        aria-label="Default select example"
                        value={filter.status}
                        className="modal-form me-2"
                        name="status"
                        onChange={(e) => handleFilterChange(e)}
                    >
                        <option value={""}>Status</option>
                        <option value="0">not sent</option>
                        <option value="1">sent</option>
                    </Form.Select>
                    {!appliedFilter ? (
                        <Button
                            type="button"
                            className="home_btn me-2 wishboard_btn"
                            onClick={() => filterData()}
                        >
                            Filter
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            className="btn-secondary home_btn wishboard_btn"
                            onClick={() => clearFilters()}
                        >
                            Reset
                        </Button>
                    )}
                </form>
            </div>
            <div className='board_table mt-5'>
                <Table bordered responsive>
                    <thead className='table_header'>
                        <tr>
                            <th>No.</th>
                            <th>Card Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th className='text-end'>Actions</th>
                        </tr>
                    </thead>
                    {appliedFilter ? (
                        <>
                            {filteredData?.length > 0 ? (
                                <tbody>
                                    {filteredData?.map((card, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <p className='mb-1 card-text-pra'>{card.card_name}</p>
                                                </td>
                                                <td><p className='mb-0 card-text-pra'>{card.recievername}</p></td>
                                                <td><p className='mb-0 card-text-pra'>{card.recieveremail}</p></td>
                                                <td>
                                                    <div className='action-row'>
                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={
                                                                <Tooltip>
                                                                    ViewCard
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button type="button" className="action-btn edit" onClick={() => handleViewCard(card.code)}><FaIcons.FaEye /></button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <div className="d-flex justify-content-center w-100">
                                    <EmptyState />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {
                                receivedcarddetails?.length > 0 ?
                                    (
                                        <>
                                            {
                                                receivedcarddetails?.map((card, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <p className='mb-1 card-text-pra'>{card.card_name}</p>
                                                            </td>
                                                            <td><p className='mb-0 card-text-pra'>{card.recievername}</p></td>
                                                            <td><p className='mb-0 card-text-pra'>{card.recieveremail}</p></td>
                                                            <td>
                                                                <div className='action-row'>
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        overlay={
                                                                            <Tooltip>
                                                                                ViewCard
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <button type="button" className="action-btn edit" onClick={() => handleViewCard(card.code)}><FaIcons.FaEye /></button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    ) : (
                                        <div className="d-flex justify-content-center w-100">
                                            <EmptyState />
                                        </div>
                                    )
                            }
                        </>
                    )}
                </Table>
            </div>
        </>
    )
}

export default GroupOrder;