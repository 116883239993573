import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import { UserProcess } from "../../actions";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

const AddUser = ({ isUpdate = false, userData ,sidebarHandler}) => {
    const dispatch = useDispatch();

    const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const phoneInputRef = useRef();
    const userSchema = yup.object().shape({
        customer_id: yup.string(),
        firstname: yup
          .string()
          .min(3, "First name should contain at least 3 characters")
          .required("Please Enter your First Name"),
        lastname: yup.string().required("Please Enter your Last Name"),
        email: yup.string().email().required("Please Enter Email"),
        mobile_num: yup.string().nullable().optional().matches(rePhoneNumber, 'Phone number must be exactly 10 digits and contain only numbers'),
        bod: yup
          .string()
          .transform((value, originalValue) => {
            if (originalValue) {
              const dateObj = new Date(originalValue);
              const day = dateObj.getDate().toString().padStart(2, "0");
              const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
              const year = dateObj.getFullYear().toString();
              const formattedDate = `${day}-${month}-${year}`;
              return formattedDate;
            }
            return null;
          }).optional().nullable(),
        married_status: yup.number().oneOf([0, 1]).optional().nullable(),
        work_anniversary_date: yup
          .string()
          .transform((value, originalValue) => {
            if (originalValue) {
              const dateObj = new Date(originalValue);
              const day = dateObj.getDate().toString().padStart(2, "0");
              const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
              const year = dateObj.getFullYear().toString();
              const formattedDate = `${day}-${month}-${year}`;
              return formattedDate;
            }
            return null;
          }).optional().nullable(),
        marriage_date: yup
          .string()
          .transform((value, originalValue) => {
            if (originalValue) {
              const dateObj = new Date(originalValue);
              const day = dateObj.getDate().toString().padStart(2, "0");
              const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
              const year = dateObj.getFullYear().toString();
              const formattedDate = `${day}-${month}-${year}`;
              return formattedDate;
            }
            return null;
          }).optional().nullable(),
      });
      const validateTelephone = () => {
        if (phoneInputRef.current.value) {
          // Validate the phone number using the yup validation schema
          yup.string().matches(rePhoneNumber, "Phone number must be exactly 10 digits and contain only numbers").validate(phoneInputRef.current.value);
        }
    }
    const handleReset = () => {
        document.getElementById("AddUserForm").reset();
        sidebarHandler('userlist')

    }
    const onSubmit = (data) => {
        dispatch(UserProcess(data, handleReset))
    };

    const { register, handleSubmit, watch,setValue, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(userSchema),
        defaultValues: {
            customer_id: localStorage.getItem("customer_id"),
        },
    });
    const isMarried = watch('married_status');
    useEffect(() => {
			setValue("married_status", 0);
	}, []);

const currentDate = new Date().toISOString().split('T')[0];


    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center profile_heading_design">
                    <h5 className='m-0 heading_board'>Add User</h5>
                </div>
                <div className="btn btn-primary home_btn">
                    <Link to="/home"><i className="fa-solid fa-house"></i>Home</Link>
                </div>
            </div>
            <hr />
            <section className='adduser_field_area'>
<div className="d-flex justify-content-end">
<Button type="button" className="btn-secondary home_btn wishboard_btn" onClick={()=>sidebarHandler("userlist")}>User List</Button>

</div>
                <div className="account-setting account-box-design">
                    <Form id="AddUserForm" className='d-flex align-items-center flex-wrap account-form' onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control aria-invalid={errors.firstname ? "true" : "false"} type="text" placeholder='Enter Your First Name' {...register("firstname", { required: true })} />
                        </Form.Group>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control aria-invalid={errors.lastname ? "true" : "false"} type="text" placeholder='Enter Last Name'  {...register("lastname", { required: true })} />
                        </Form.Group>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>Email</Form.Label>
                            <Form.Control aria-invalid={errors.email ? "true" : "false"} type="email" placeholder='Enter Your Email'  {...register("email", { required: true })} />
                        </Form.Group>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control  type="tel" placeholder='Enter Your Number' {...register("mobile_num")} ref={phoneInputRef}
                                                onBlur={validateTelephone} required={false}/>
                        </Form.Group>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>Date Of Birth</Form.Label>
                            <Form.Control id="datePicker" max={currentDate} aria-invalid={errors.bod ? "true" : "false"} type="date" placeholder='Enter Your Birth Date'   {...register("bod")}  required={false}/>
                        </Form.Group>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>Work Anniversary Date</Form.Label>
                            <Form.Control id='mdatePicker' max={currentDate} aria-invalid={errors.work_anniversary_date ? "true" : "false"} type="date" placeholder='Enter Work Anniversary Date'  {...register("work_anniversary_date")}  required={false}/>
                        </Form.Group>
                        <Form.Group className="mb-3 modal-form">
                            <Form.Label>Married Status</Form.Label>
                            <Form.Select aria-label="User Married Status" aria-invalid={errors.married_status ? "true" : "false"} className="form-control"   {...register("married_status")}  required={false}>
                                <option disabled selected>Open this select menu</option>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </Form.Select>
                        </Form.Group>
                        {
                            isMarried === '1' && (
                                <Form.Group className="mb-3 modal-form">
                                    <Form.Label>Marriage Date</Form.Label>
                                    <Form.Control id='mdatePicker' max={currentDate} aria-invalid={errors.marriage_date ? "true" : "false"} type="date" placeholder='Enter Your Marriage Date'  {...register("marriage_date")} />
                                </Form.Group>
                            )
                        }

                        <Col lg={12}>
                            <div className="d-flex justify-content-center">
                                <Button type="submit" className="mt-3 home_btn">Submit</Button>
                            </div>
                        </Col>
                    </Form>
                </div>
            </section>
        </>
    )
}

export default AddUser;