import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import HelmetExport, { Helmet } from "react-helmet";
import { BASE_URL, PAGE_TITLE } from "../../constants/constant";
import "./Createcard.css";
import { useDispatch, useSelector } from "react-redux";
import { OrderCard, getCardDetailsList, login } from "../../actions";
import { useParams } from "react-router-dom";
import CardImg from "../../assets/img/birthday-card.png";
import { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Login from "../../componants/Login";
import Register from "../../componants/Register";
import LoadingSpinner from "../../componants/LoadingSpinner";
import { useForm } from "react-hook-form";
import * as FaIcons from "react-icons/fa";
import ImageUpload from "./ImageUpload";

const cardSchema = yup.object().shape({
	senderfname: yup.string().required("Please Enter Sender First Name"),
	senderlname: yup.string().required("Please Enter Sender Last Name"),
	recieverfname: yup.string().required("Please Enter Reciever First Name"),
	recieverlname: yup.string().required("Please Enter Reciever Last Name"),
	recieveremail: yup.string().email().required("Please Enter Reciever Email"),
	deliverytimetype: yup.number().oneOf([0, 1]).default(1),
	cardname: yup.string().required("Please enter eCard title"),
	date: yup
		.date()
		.min(new Date(Date.now() - 86400000), "Can't select old dates")
		.when("deliverytimetype", {
			is: "1",
			then: yup.string().required("Date is required"),
		}),
	time: yup
		.string()
		
		.when("deliverytimetype", {
			is: "1",
			then: yup.string().required("Enter time"),
		}),
	timezone: yup.string().when("deliverytimetype", {
		is: "1",
		then: yup.string().required("Enter timezone"),
	}),
});
const CardStepItems = ({
	cardTabs,
	register,
	errors,
	handleStep,
	isLater,
	watch,
	id,
	handleReset,
	handleAuthCard,
	isPremium,
	selectedImage,
	encryptid,
	getValues,
	setError,
	setValue
}) => {
	switch (cardTabs) {
		case 0:
			return (
				<Col lg={7} md={6}>
					<div className="craete-card-details">
						<h5 className="mb-3">Sender Details</h5>
						<StepOne
							register={(name, options) => register(name, { ...options, autocomplete: "off" })}
							errors={errors}
							handleStep={handleStep}
						/>
					</div>
				</Col>
			);
		case 1:
			return (
				<Col lg={7}>
					<div className="craete-card-details">
						<h5 className="mb-3">Time for Delivery</h5>
						<StepTwo
                            register={(name, options) => register(name, { ...options, autocomplete: "off" })}
							errors={errors}
							handleStep={handleStep}
							isLater={isLater}
							getValues={getValues}
							setError={setError}
							setValue={setValue}
						/>
					</div>
				</Col>
			);
		case 2:
			return (
				<Col lg={7}>
					<div className="craete-card-details">
						<h5 className="mb-3">Recipient Details</h5>
						<StepThree
							register={register}
							errors={errors}
							handleStep={handleStep}
						/>
					</div>
				</Col>
			);
		case 3:
			return (
				<Container>
					<StepFour
						selectedImage={selectedImage}
						handleAuthCard={handleAuthCard}
						handleStep={handleStep}
						watch={watch}
						id={id}
						handleReset={handleReset}
						isPremium={isPremium}
						encryptid={encryptid}
					/>
				</Container>
			);
		default:
			return null;
	}
};
const CardHandler = ({ islogin, handleAuthCard }) => {
	const [CardStep, setCardStep] = useState(0);
	const [cardIndex, setcardIndex] = useState(-1);
	const [cardid, setCardid] = useState(0);
	const [isPremium, setIsPremium] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const dispatch = useDispatch();
	const { encryptid } = useParams();
	const id = encryptid;
	const cardsList = useSelector((state) => state.subcategory.cardsData.data);
	const isCardLoad = useSelector((state) => state.subcategory.isCardsLoad);
	const isLoading = useSelector((state) => state.ordercarddetails.isLoading);
// console.log(id,cardIndex)
	useEffect(() => {
		if (id !== undefined && id !== "addimage" && cardIndex === -1) {
			// console.log(cardsList, "this array");
			if (cardsList?.length > 0) {
				var metaIndex = cardsList?.findIndex(
					(p) => p.seo === id || p?.card_keyword === id
				);
				if (metaIndex !== -1) {
					setcardIndex(metaIndex);
					setCardid(cardsList[metaIndex].card_id);
					// console.log(
					// 	cardsList[metaIndex].ispremium === "1",
					// 	cardsList[metaIndex]
					// );
					setIsPremium(cardsList[metaIndex].ispremium === "1");
				} else {
					if (!isCardLoad) {
						dispatch(getCardDetailsList(id));
						// console.log("carddetails console1");
					}
				}
			} else {
				if (!isCardLoad) {
					dispatch(getCardDetailsList(id));
					// console.log("carddetails console2");
				}
			}
		}
	}, [id, cardsList, isCardLoad,cardIndex]);

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors, touchedFields, defaultValues },
		setValue,
		setError,
		getValues,
	} = useForm({
		mode: "all",
		resolver: yupResolver(cardSchema),
		   defaultValues:islogin ? {
		    timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
		    senderfname:localStorage.getItem("firstname"),
		    senderlname:localStorage.getItem("lastname") || "",
		    deliverytimetype:0,
		   }:{
		    timezone:Intl.DateTimeFormat().resolvedOptions().timeZone,
			deliverytimetype:0,
		   }
	});
	const isLater = watch("deliverytimetype");
	useEffect(() => {
		// console.log(islogin, touchedFields);
		if (islogin && !touchedFields.senderfname) {
			// register("senderfname", { required: true, value: localStorage.getItem("firstname") })
			// register("senderlname", { required: true, value: localStorage.getItem("lastname") })
			// register("deliverytimetype", { required: true, value: 0})

			setValue("senderfname", localStorage.getItem("firstname"));
			setValue("senderlname", localStorage.getItem("lastname"));
			// setValue("deliverytimetype", 0);
			register("deliverytimetype", { required: true, value: 0})
		}else{
            register("deliverytimetype", { required: true, value: 0})
        }
		
	}, [islogin, setValue, CardStep, touchedFields]);


    // console.log('cardsList',cardsList)
	const handleStep = (step) => {
		setCardStep(step);
	};
	const handleReset = () => {
		reset();
		handleStep(0);
	};
	const onSubmit = (data) => {
		// console.log(data, "cad data");
		let originalValue = data.date;
		const dateObj = new Date(originalValue);
		const day = dateObj.getDate().toString().padStart(2, "0");
		const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
		const year = dateObj.getFullYear().toString();
		const formattedDate = `${year}-${month}-${day}`;
		// console.log("Formatted date:", formattedDate);
		let cardDataobj = { ...data };
		cardDataobj["date"] = formattedDate;
		// console.log(cardDataobj);
		if (encryptid === "addimage") {
			dispatch(
				OrderCard(
					{ ...cardDataobj, customer_id: localStorage.getItem("customer_id") },
					handleReset
				)
			);
		} else {
			dispatch(
				OrderCard(
					{ ...cardDataobj, customer_id: localStorage.getItem("customer_id") },
					handleReset
				)
			);
		}
	};const handleTime = () => {
		if(isLater === 1){
			const selectedDate = getValues("date");
			const selectedTime = getValues("time");
		// console.log("yo")
			if (selectedDate && selectedTime) {
				const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);
				const currentDateTime = new Date();
		
				// Ensure the format of selectedDateTime and currentDateTime is consistent for comparison
				// Adjust time zone if necessary, the date and time should be in the same format for accurate comparison
		
				if (selectedDateTime > currentDateTime) {
					return true
				} else {
					// The selected time is in the past or present concerning the selected date
					return false
				}
			}
		}else{
			return true
		}
		
	}
	const handleMoveStep = (type) => {
		switch (type) {
			case "Step0":
				handleStep(0);
				break;

			case "Step1":
				{
					if (
						(touchedFields.senderfname &&
							touchedFields.senderlname &&
							!errors.senderfname &&
							!errors.senderlname) ||
						(defaultValues.senderfname && defaultValues.senderlname)
					) {
						if (encryptid === "addimage" && selectedImage === null) {
							toast.error("please select an image");
						} else {
							handleStep(1);
						}
					} else {
						toast.error("fill sender details to go to next step");
					}
				}
				break;
			case "Step2":
				{
					if (isLater === 1) {
						// console.log(defaultValues);
						if (
							(touchedFields.date &&
								touchedFields.time &&
								touchedFields.timezone &&
								!errors.date &&
								!errors.time &&
								!errors.timezone) ||
							defaultValues.deliverytimetype !== undefined
						) {
							if(handleTime()){
								handleStep(2);
							}else{
								toast.error("selected time cant be in past");
							}
							
						} else {
							toast.error("fill delivery details to go to next step");
						}
					} else {
						// console.log(defaultValues);
						if (
							(touchedFields.deliverytimetype && !errors.deliverytimetype) ||
							defaultValues.deliverytimetype !== undefined
						) {
							if(handleTime()){
								handleStep(2);
							}else{
								toast.error("selected time cant be in past");
							}
							
							
						} else {
							toast.error("fill delivery details to go to next step");
						}
					}
				}
				break;
			case "Step3":
				{
					if (
						touchedFields.recieverfname &&
						touchedFields.recieverlname &&
						touchedFields.recieveremail &&
						touchedFields.cardname &&
						!errors.recieverfname &&
						!errors.recieverlname &&
						!errors.recieveremail &&
						!errors.cardname
					) {
						handleStep(3);
					} else {
						toast.error("fill recipient details to go to next step");
					}
				}
				break;
			default:
				return null;
		}
	};
    // console.log('testtttttttttt',cardsList[cardIndex])
	return isLoading ? (
		<>
            {
				cardIndex !== -1 && (
					<Helmet>
                <title>{cardsList[cardIndex]?.meta_title}</title>
                <meta name="title" content={cardsList[cardIndex]?.meta_title} />
                <meta name="description" content={cardsList[cardIndex]?.meta_description} />
                <meta name="keywords" content={cardsList[cardIndex]?.meta_title} />
                <meta property="og:title" content={cardsList[cardIndex]?.meta_title} />
                <meta property="og:description" content={cardsList[cardIndex]?.meta_description} />
                <meta property="og:image" content={cardsList[cardIndex]?.image} />
                <meta property="og:url" content={`${BASE_URL}/create/${encryptid}`} />
            </Helmet>
				)
			}
			<section className="create-card-step">
				<Container>
					<Row>
						<LoadingSpinner />
						<div className="text-center signtext-ctm">
							<h4>Please Wait...</h4>
						</div>
					</Row>
				</Container>
			</section>
		</>
	) : (
		<>
			{" "}
			{
				cardIndex !== -1 && (
					<Helmet>
                <title>{cardsList[cardIndex]?.meta_title}</title>
                <meta name="title" content={cardsList[cardIndex]?.meta_title} />
                <meta name="description" content={cardsList[cardIndex]?.meta_description} />
                <meta name="keywords" content={cardsList[cardIndex]?.meta_title} />
                <meta property="og:title" content={cardsList[cardIndex]?.meta_title} />
                <meta property="og:description" content={cardsList[cardIndex]?.meta_description} />
                <meta property="og:image" content={cardsList[cardIndex]?.image} />
                <meta property="og:url" content={`${BASE_URL}/create/${encryptid}`} />
            </Helmet>
				)
			}
			<section className="create-card-step">
				<Container>
					<Row>
						<Col lg={12}>
							<div className="step-flow-area mb-4 d-flex">
								<div className={`step-flow ${CardStep === 0 && "active"}`}>
									<span className="is-complete d-flex align-items-center justify-content-center">
										1
									</span>
								</div>
								<div className={`step-flow ${CardStep === 1 && "active"}`}>
									<span className="is-complete d-flex align-items-center justify-content-center">
										2
									</span>
								</div>
								<div className={`step-flow ${CardStep === 2 && "active"}`}>
									<span className="is-complete d-flex align-items-center justify-content-center">
										3
									</span>
								</div>
								<div className={`step-flow ${CardStep === 3 && "active"}`}>
									<span className="is-complete d-flex align-items-center justify-content-center">
										4
									</span>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
				<Container fluid>
					<div className="step-text-flow d-flex justify-content-evenly align-items-center mb-5">
						<p
							className={`text-step greeting-step ${
								CardStep === 0 && "active"
							}`}
						>
							Greeting
						</p>
						<p
							className={`text-step deliver-step ${CardStep === 1 && "active"}`}
						>
							When to Deliver
						</p>
						<p
							className={`text-step recipient-step ${
								CardStep === 2 && "active"
							}`}
						>
							Recipient Details
						</p>
						<p
							className={`text-step payment-step ${CardStep === 3 && "active"}`}
						>
							Payment
						</p>
					</div>
				</Container>
				<Container>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							{CardStep !== 3 && (
								<Col lg={5}>
									<div className="create-card">
										{encryptid === "addimage" ? (
											<div className="add-image-holder">
												<ImageUpload
													selectedImage={selectedImage}
													setSelectedImage={setSelectedImage}
												/>
											</div>
										) : cardIndex === -1 ? (
											<img src={CardImg} alt="" />
										) : (
											<img src={cardsList[cardIndex].image} alt="" />
										)}
									</div>
								</Col>
							)}

							<CardStepItems
								selectedImage={selectedImage}
								handleAuthCard={handleAuthCard}
								cardTabs={CardStep}
								handleStep={handleMoveStep}
								register={register}
								errors={errors}
								isLater={isLater}
								watch={watch}
								id={cardid}
								handleReset={handleReset}
								isPremium={isPremium}
								encryptid={encryptid}
								getValues = {getValues}
								setError={setError}
								setValue={setValue}
							></CardStepItems>
						</Row>
					</form>
					<Row>
						<Col lg={12}>
							<div className="page-preview-btn d-flex justify-content-between mt-4 mb-4">
								<span className={CardStep >= 1 ? "" : "d-hidden"} onClick={() => handleStep(CardStep - 1)}>
									<FaIcons.FaChevronLeft />
								</span>

								<span className={CardStep < 3 ? "" : " d-hidden"} onClick={() => handleMoveStep(`Step${CardStep + 1}`)}>
                                    <FaIcons.FaChevronRight />
								</span>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};
const CreateCard = () => {
	//   const authData = useSelector(state => state.authentication.loggedinData)
	const islogin = localStorage.getItem("isLogin");
	const [authcardtype, setAuthCardType] = useState("none");
	const handleAuthCard = (type) => {
		if (type === authcardtype) {
			setAuthCardType("none");
		} else {
			setAuthCardType(type);
		}
	};

	return (
		<>
			<CardHandler islogin={islogin} handleAuthCard={handleAuthCard} />
			{authcardtype === "signin" && (
				<>
					{/* {createPortal( */}
					<Login
						show={true}
						onHide={() => handleAuthCard("signin")}
						handleForgot={() => handleAuthCard("Forgot")}
						handleSignUp={() => handleAuthCard("signup")}
					/>
					{/* document.getElementById("modal-root") */}

					{/* )} */}
				</>
			)}
			{authcardtype === "signup" && (
				<Register
					show={true}
					onHide={() => handleAuthCard("signup")}
					handleLogin={() => handleAuthCard("signin")}
					handleSignIn={() => handleAuthCard("signin")}
					isCard
				/>
			)}
		</>
	);
};

export default CreateCard;
