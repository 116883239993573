import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import CardImg from '../../assets/img/birthday-card.png';
import FormTooltip from "../../componants/FormTooltip";
import * as FaIcons from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";

const StepThree = ({register,errors,handleStep}) => {
    const [tooltipPlacement, setTooltipPlacement] = useState('right');

    useEffect(() => {
        // Detect screen width and set tooltip placement accordingly
        const handleScreenResize = () => {
            if (window.innerWidth < 768) {
                setTooltipPlacement('top');
            } else {
                setTooltipPlacement('right');
            }
        };

        // Initialize tooltip placement based on initial screen width
        handleScreenResize();

        // Listen for window resize events
        window.addEventListener('resize', handleScreenResize);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleScreenResize);
        };
    }, []);

    return (
        <>
            <Form.Group className="mb-3 modal-form">
                <Form.Label>Recipient First Name<span className="reqired-star">*</span><FormTooltip tooltipText="eCard reciever first name" placement={tooltipPlacement} className={'me-4 tooltip-plus-btn'}><FaIcons.FaInfo /></FormTooltip></Form.Label>
                <input className="form-control" type="text" placeholder="Enter Your First Name" autoComplete="off" {...register("recieverfname",{required:true})}/>
                <p className='form-error-message mt-1'>{errors.recieverfname?.message} </p>
            </Form.Group>
            <Form.Group className="mb-3 modal-form">
                <Form.Label>Recipient Last Name<span className="reqired-star">*</span><FormTooltip tooltipText="eCard reciever Last name" placement={tooltipPlacement} className={'me-4 tooltip-plus-btn'}><FaIcons.FaInfo /></FormTooltip></Form.Label>
                <input className="form-control" type="text" placeholder="Enter Your Last Name" autoComplete="off" {...register("recieverlname",{required:true})}/>
                <p className='form-error-message mt-1'>{errors.recieverlname?.message} </p>
            </Form.Group>
            <Form.Group className="mb-3 modal-form">
                <Form.Label>Recipient Email<span className="reqired-star">*</span><FormTooltip tooltipText="eCard will be sent to this email id." placement={tooltipPlacement} className={'me-4 tooltip-plus-btn'}><FaIcons.FaInfo /></FormTooltip></Form.Label>
                <input className="form-control" type="text" placeholder="Enter Your Email" autoComplete="off" {...register("recieveremail",{required:true})}/>
                <p className='form-error-message mt-1'>{errors.recieveremail?.message} </p>
            </Form.Group>
            <Form.Group className="mb-3 modal-form">
                <Form.Label>Name of the Card<span className="reqired-star">*</span><FormTooltip tooltipText="eCard title you want to appear for the Reciever and Team " placement={tooltipPlacement} className={'me-4 tooltip-plus-btn'}><FaIcons.FaInfo /></FormTooltip></Form.Label>
                <input className="form-control" type="text" placeholder="Thank you" autoComplete="off" {...register("cardname",{required:true})}/>
                <p className='form-error-message mt-1'>{errors.cardname?.message} </p>
            </Form.Group>
            <div className="continue-btn text-center mt-4">
                <Button className="btn btn-primary" onClick={()=>handleStep("Step3")}>Continue</Button>
            </div>
        </>
    );
}

export default StepThree;