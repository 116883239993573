import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import sectionImg1 from '../assets/img/latestblog-img1.png'
import sectionImg2 from '../assets/img/latestblog-img2.png'
import sectionImg3 from '../assets/img/latestblog-img3.png'
import $ from 'jquery'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { getBlogList } from "../actions";
import { useDispatch, useSelector } from "react-redux";

const Latestblog = () => {
    const blogPostList = useSelector((state) => state.blogdata.blogpostData.data);

    const dispatch = useDispatch();
    const [paddingLeft, setPaddingLeft] = React.useState(0);

    React.useEffect(() => {
        dispatch(getBlogList());
        const calculatePaddingLeft = () => {
            const containerWidth = $('.latestblog-area .container').outerWidth();
            const windowWidth = $(window).outerWidth();
            const outerSpace = windowWidth - containerWidth;
            setPaddingLeft(outerSpace / 2);
        };

        calculatePaddingLeft();

        window.addEventListener('resize', calculatePaddingLeft);

        return () => {
            window.removeEventListener('resize', calculatePaddingLeft);
        };
    }, []);

    return (
        <>
            <section className="latestblog-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <div className="section-title-area mb-5 d-flex align-items-center justify-content-between">
                                <div>
                                <h2 className="mb-4">Read <span className="title-design position-relative">Our Latest</span> Blog Posts and resources</h2>
                                <p className="">Explore our valuable insights and inspiration for crafting meaningful messages on your greeting cards.</p>
                                </div>
                                <Link to="/blog" className="btn btn-lg btn-primary">Explore More</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="blog-container" style={{ paddingLeft: `${paddingLeft}px` }}>
                    <Row className="latestblog-card-listing">
                        <Col md={12}>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 50,
                                    },
                                    1366: {
                                        slidesPerView: 4.5,
                                        spaceBetween: 30,
                                    },
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                 { blogPostList?.slice(0, 8).map((elem, index) => (
                                <SwiperSlide key={'blogpostlatest' + index}>
                                    <Card className={'category-card rounded-0 border-0'}>
                                        <div className="blog-image-wrap">
                                            <Card.Img variant="top" src={elem.image} className={'rounded-0'} />
                                        </div>
                                        <Card.Body>
                                            <Card.Title>{elem.title}</Card.Title>
                                            <Card.Text>
                                                {elem.short_description}
                                            </Card.Text>
                                            <Link to={`/blog-details/${elem.seo}`} className="card-link">Read More</Link>
                                        </Card.Body>
                                    </Card>
                                </SwiperSlide>
                                 ))}
                            </Swiper>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Latestblog;