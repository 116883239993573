import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateWishList,addWishList } from "../actions";
import SvgIcon from "./SvgIcon";
import * as CryptoJS from 'crypto-js'
import { useEffect } from "react";
import { useState } from "react";

const ProductCard = (props) => {

    const dispatch = useDispatch();
const wishlisted = useSelector((state) => state.wishlistpost.data.ids);
const isLogin = useSelector((state)=>state.authentication.isAuthenticated);
    const [liked,setLiked] =useState(false);
    const handleDelete = (id) =>{
        dispatch(updateWishList(id))         
    }

    const handleAdd = (id) =>{
        dispatch(addWishList(id))         
    }
  useEffect(()=>{
if(isLogin){
    if(wishlisted !== undefined){
        // console.log(wishlisted,props.cardid,"thishishi")
        if(wishlisted.includes(props.cardid)){
            // console.log("here")
            setLiked(true);
        }else{
            setLiked(false)
        }
    }
}
  },[wishlisted,isLogin])
  
    return (
        <div className={`upload-card-img ${props.className}`} >
            <img src={props.image} alt="" />
            {
                isLogin && (
<SvgIcon name={!liked ? 'Wishlist' : 'UnWishlist'} className="wishlist" handleClick={()=>{!liked ?handleAdd(props.cardid) :handleDelete(props.cardid)}}/>
           
                )
            }
            
           {
            props.isPremium == "1" ? (<div className="pro-icon">
            <SvgIcon name={'Proicon'} />
        </div>):null
           }
            
            <div className="overlay-img" onClick={()=>props.handleClick(props.seo)}>
                <h5>{props.title}</h5>
            </div>
        </div>
    );
}

export default ProductCard;