import React from "react";
import Invitation from "../../componants/Invitation";
import Wishboard from "../../componants/Wishboard";
import Cardwork from "../../componants/Cardwork";
import Latestblog from "../../componants/Latestblog";
import Testimonial from "../../componants/Testimonial";
import Advertisebanner from "../../componants/Advertisebanner";
import {Helmet} from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";

import "./index.css";
import WhyUs from "../../componants/WhyUs";
import BusinessSlider from "../../componants/BusinessSlider";
import WhyUsBusiness from "../../componants/WhyUsBusiness";
import Featuresec from "../../componants/FeatureBusiness";
import Businessblog from "../../componants/Businessblog";

const Business = ()=> {
    const Site_URL = process.env.REACT_APP_APP_URL
  return (
    <>
  
        <Helmet>
            <title>{PAGE_TITLE.Business_Title}</title>
            <meta name="title" content={PAGE_TITLE.Business_Title} />
            <meta name="description" content={'This is dummy description of egroup cards'} />
            <meta name="keywords" content={'This, keyword, keyword'} />
            <meta property="og:title" content={PAGE_TITLE.Business_Title} />
            <meta property="og:description" content={'This is dummy description of egroup cards'} />
            <meta property="og:image" content={'This,keyword,keyword'} />
            <meta property="og:url" content={`${Site_URL}/`} />
        </Helmet>
      <BusinessSlider />
      <WhyUsBusiness />
      <Featuresec />
      {/* <Latestblog /> */}
      <Businessblog />
      <Testimonial />
     </>
  );
}

export default Business;