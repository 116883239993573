import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import SvgIcon from './SvgIcon';
import { changePassword, login } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as FaIcons from "react-icons/fa";

const ChangePassword = ({ show, onHide, handleSignUp }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const dispatch = useDispatch();
    const authData = useSelector((state) => state.authentication)
    const passwordSchema = yup.object().shape({
        password: yup.string()
            .required('No password provided.')
            .min(6, 'Password is too short - should be 6 chars minimum.')
            .matches(/[a-zA-Z0-9]/, 'Password can only contain Latin letters.'),
        confirm: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        mode: "all",
        resolver: yupResolver(passwordSchema)
    });

    const onSubmit = (data) => {

        dispatch(changePassword({ customer_id: authData.loggedinData.customer_id, ...data }, handleSuccess))
    };

    const handleSuccess = () => {
        onHide();
    }

    const handleClose = () => {
        onHide();
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };




    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body className="section-title-area sign-in-modal">
                <button type="button" class="btn-close change-close" onClick={() => handleClose()} aria-label="Close"></button>
                <h2 className="text-center mb-5"><span className="title-design position-relative">Change</span><br /> Password</h2>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type={passwordVisible ? 'text' : 'password'} placeholder="Enter New Password" {...register("password", { required: true })} />
                        {!passwordVisible ? (
                            <FaIcons.FaEyeSlash
                                className='eyeview'
                                onClick={togglePasswordVisibility}
                            />
                        ) : (
                            <FaIcons.FaEye
                                className='eyeview'
                                onClick={togglePasswordVisibility}
                            />
                        )}
                        </div>
                        <p className='form-error-message'>{errors.password?.message}</p>
                    </Form.Group>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control id="confirm" type={passwordVisible ? 'text' : 'password'} placeholder="Reenter New Password" {...register("confirm", { required: true })} />
                        <p className='form-error-message'>{errors.confirm?.message}</p>
                    </Form.Group>


                    <Button variant="primary" type="submit" className="w-100 mt-3">
                        Submit
                    </Button>
                </Form>

            </Modal.Body>
        </Modal>
    );
}

export default ChangePassword;