import React, { useEffect, useState } from 'react'
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { getGif } from '../../actions/index.js';
import "./Giphy.css";
import * as FaIcons from "react-icons/fa";
import LoadingSpinner from '../../componants/LoadingSpinner/index.js';

function Giphy({ handleSelect }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedGif, setSelectedGif] = useState("none")
    const dispatch = useDispatch();
    const giphyData = useSelector((state) => state.giphyData);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (searchTerm === "") {
            dispatch(getGif("wishes"))
        } else {
            dispatch(getGif(searchTerm))
        }
    };

    useEffect(() => {
        dispatch(getGif("wishes"))
    }, [])
    return (
        <>
            <InputGroup className="mb-3">
                <div style={{ flex: '1', position: 'relative' }}>
                    <img src="/static/media/powered_by_giphy.e7afe87d18425bec3ea1.gif" alt="Powered By Giphy" style={{
                        width: '124px',
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        opacity: '0.3',
                        pointerEvents: 'none',
                    }} />
                    <input type="text" placeholder="Search Gif..." value={searchTerm} onChange={handleChange} class="form-control" />
                </div>

                <button type="button" className="btn btn-danger active" onClick={(e) => handleSubmit(e)}>
                    <FaIcons.FaSearch />
                </button>
            </InputGroup>
            {
                giphyData.isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>

                        <div className='gif-holder'>
                            <Row className='px-3 justify-content-between'>
                                {giphyData.data.map((gif) => (
                                    <Col className="col-6 col-lg-3 mb-4">
                                        <img key={gif.id} src={gif.images.fixed_height.url} alt={gif.title} className='image-size-fixed img-fluid w-100' onClick={() => handleSelect("media", gif.images.fixed_height.url)} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </>
                )
            }

        </>
    )
}

export default Giphy