import { API_ACTIONS } from "../constants/actionConstant";

const initialState = {
    isLoading: false,
    err: null,
    data:[],
};

const privacyReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getPrivacyPolicyStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getPrivacyPolicySuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getPrivacyPolicyFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
            default:
                return state;
    }
};

export default privacyReducer;
