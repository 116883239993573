import React, { useRef, useEffect, useCallback, useState } from "react";
import { Button, Collapse, Container, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import * as FaIcons from "react-icons/fa";
import HTMLFlipBook from "react-pageflip";
import logo from "../../assets/img/logo.svg";
import ticket from "../../assets/img/ticket.png";
import "./index.css";
import $ from 'jquery'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSignature, FetchWishCard, handleSignCard, handleUpdateCard, sendOrderCard } from "../../actions";
import LoadingSpinner from "../../componants/LoadingSpinner";
import EmptyState from "../../componants/EmptyState";
import SignCardEdit from "./SignCardEdit";
import Invites from "../../componants/Invites"
import { BASE_URL } from "../../constants/constant";
import ReactCanvasConfetti from 'react-canvas-confetti';
import { toast } from "react-toastify";
import SignCardUpdate from "./SignCardUpdate";
import ViewBoard from "../../componants/ViewBoard";
import { isMobile } from 'react-device-detect';
import PDFExporter from "../PDFViewer";

function getAnimationSettings() {
    return {
        angle: 360,
        colors:
            [
                '#26ccff',
                '#a25afd',
                '#ff5e7e',
                '#88ff5a',
                '#fcff42',
                '#ffa62d',
                '#ff36ff'
            ],
        decay: 0.8,
        drift: 0,
        startVelocity: 122,
        spread: 360,
        shapes:
            [
                'circle',
                'square'
            ],
        ticks: 600,
        zIndex: -1,
        gravity: 1,
        scalar: 1,
        particleCount: 253,
        origin: {
            x: 0.5,
            y: 0.5
        }
    };
}

const SignCard = ({ isSender }) => {
    const dominname = process.env.REACT_DOMIN_NAME
    const [isbookOpen, setIsBookOpen] = useState(false);
    const [isFire, setIsFire] = useState(false);
    const [isInvite, setInvite] = useState(false);
    const { code } = useParams();
    const dispatch = useDispatch();
    const wishcardLoading = useSelector(state => state.ordercarddetails.isLoadWishcard);
    const wishCardData = useSelector(state => state.ordercarddetails.wishCardData);
    const [isSign, setIsSign] = useState(false);
    const [currentAction, setCurrentAction] = useState("Card");
    const initState = {
        signed: false, code: "", id: ""
    }
    const [signedData, setSignedData] = useState(initState)
    const [signDetails, setSignDetails] = useState(null)
    const [dimensions, setDimensions] = useState({ width: 400, height: 600 });
    const [creatorSigned, setcreatorSigned] = useState(-1);
    const [isToggled,setIsToggled] = useState(false);



    let customer_id = localStorage.getItem('customer_id');
    let email = localStorage.getItem('email');
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            if (screenWidth < 768) { // For mobile devices
                setDimensions({ width: screenWidth - 40, height: screenWidth * 1.5 });
            } else if (screenWidth < 992) { // For tablets
                setDimensions({ width: 400, height: 600 });
            } else { // For larger devices like iPad
                setDimensions({ width: 400, height: 600 });
            }
        };

        // Initial call to set the dimensions based on the current screen size
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const book = useRef();
   
    useEffect(() => {
        if (isSender) {
            setIsBookOpen(true);
        } else {
            $('.firebtn').click(function () {
                setIsFire(true);
            });
            if (window.innerWidth <= 767) {
                if (!$('.envelope').hasClass('open')) {
                    $('.envelope').removeClass('new').addClass('open');
                    $('.envelope .back .top-flap').addClass('d-none');
                    const Letterheight = $('.letter').outerHeight();
                    const Letterwidth = $('.letter').outerWidth();
                    $('.letter-image').height(Letterwidth);
                    $('.letter-image').width(Letterheight);
                    $(window).on("load", function () {
                        // $('.envelope').click(function () {
                        startAnimation();
                        $('.envelope').removeClass('new').addClass('open');
                        $('.envelope .back .top-flap').addClass('d-none');
                        setTimeout(function () {
                            setTimeout(function () {
                                setIsBookOpen(true);
                            }, 0);
                        }, 0);
                    });
                }
            } else {
                if (!$('.envelope').hasClass('open')) {
                    const Letterheight = $('.letter').outerHeight();
                    const Letterwidth = $('.letter').outerWidth();
                    $('.letter-image').height(Letterwidth);
                    $('.letter-image').width(Letterheight);
                    $(window).on("load", function () {
                        // $('.envelope').click(function () {
                        startAnimation();
                        setTimeout(function () {
                            $('.envelope').removeClass('new').addClass('open');
                            setTimeout(function () {
                                setIsBookOpen(true);
                                $('.envelope .back .top-flap').addClass('d-none');
                            }, 3500);
                        }, 1500);
                    });
                }
            }
        }

    }, [isSender]);

    useEffect(() => {
        if (code !== undefined) {
            dispatch(FetchWishCard(code))
        }
    }, [code]);
    useEffect(() => {
        console.log(code,"code")
        if (code !== undefined) {
            if (localStorage.getItem("signedCard")) {
                let signedVal = JSON.parse(localStorage.getItem("signedCard"));
                console.log(signedVal,code,"this area")
                if (signedVal.code === code) {
                    setSignedData({ ...signedVal, signed: true })
                }

            } else {
                setSignedData(initState)
            }

        }
    }, [code, wishCardData])
    const handleSign = () => {
        setIsSign(!isSign)
    }
    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings());
        }
    }, []);

    const startAnimation = useCallback(() => {
        nextTickAnimation();
    }, [nextTickAnimation]);

    const handleAction = (type) => {

        if (type !== currentAction) {
            if (type === "Sign") {
                if (wishCardData.card.status === "0") {
                    toast.error("card  has already been sent cannot add more signatures");
                } else if (parseInt(wishCardData.card.signatures_left) > 0) {
                    setCurrentAction(type)
                } else {
                    toast.error("please upgrade the plan to sign card");
                }

            } else {
                setCurrentAction(type)
            }

        }
    }


    useEffect(() => {
        console.log()
        if (signedData.signed) {
            let signobj = wishCardData?.card?.sing.find(elem => parseInt(elem.card_sing_id) === parseInt(signedData.id))
            console.log(signobj,"this obj")
            if (signobj) {
                setSignDetails({ ...signobj })
            }
        }
        if (email) {
            let issigned = wishCardData?.card?.sing.find(elem => elem.message?.signerEmail === email)
             console.log(issigned?.message?.signerEmail, email, "thishh")
            if (issigned?.message?.signerEmail === email) {
                setcreatorSigned(1);
            } else {
                setcreatorSigned(0)
            }
        }
    }, [signedData.signed, wishCardData])
    const handleDelete = (id, code) => {
        dispatch(DeleteSignature({ card_sing_id: id, code: code }))
    }
    const handleSendCard = (code) => {
        dispatch(sendOrderCard(code))
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email !== undefined && emailRegex.test(email);
    };
    
    const isValidName = (name) => {
        const nameRegex = /^[a-zA-Z\s]+$/;
        return name !== undefined && nameRegex.test(name);
    };
    
    const isValidMessage = (message) => {
        return message !== undefined;
    };
    
    const handleMySign = (mediaData, code, card_sing_id, type, onHide) => {
        if (!isValidEmail(mediaData.signerEmail)) {
            toast.error('Please enter a valid email address');
        } else if (!isValidName(mediaData.signerName)) {
            toast.error('Please enter a valid name');
        } else if (!isValidMessage(mediaData.message)) {
            toast.error('Please enter a message');
        } else {
            if (!mediaData.message || !mediaData.signerEmail || !mediaData.signerName) {
                toast.error('Please fill all fields');
            } else {
                if (type === "sign") {
                    if (wishCardData.card?.sing.length !== 0) {
                        const hasMatchingSignerEmail = wishCardData.card.sing.some(obj => obj?.message?.signerEmail === mediaData.signerEmail);
                        if (hasMatchingSignerEmail) {
                            toast.error("A sign already exists with this email");
                        } else {
                            dispatch(handleSignCard({ ...mediaData, code: code, card_sing_id: card_sing_id }, onHide));
                        }
                    } else {
                        dispatch(handleSignCard({ ...mediaData, code: code, card_sing_id: card_sing_id }, onHide));
                    }
                } else {
                    dispatch(handleUpdateCard({ ...mediaData, code: code, card_sing_id: card_sing_id }, onHide));
                }
            }
        }
    };
    

    const handleClosePDF = () =>{
        setIsToggled(!isToggled)
    }
    const openPDF = (code)=>{
        setIsToggled(true)
    }
    return (
        <>
            {
                wishcardLoading ? (<LoadingSpinner />) :
                    wishCardData !== undefined ? (<>
                        <Helmet>
                            <title>{`${wishCardData.card?.cardname} to ${wishCardData.card?.recieverfname} ${wishCardData.card?.recieverlname}`}</title>
                            <meta name="title" content={`${wishCardData.card?.cardname} to ${wishCardData.card?.recieverfname} ${wishCardData.card?.recieverlname}`} />
                            <meta name="description" content={`${wishCardData.card?.cardname} to ${wishCardData.card?.recieverfname} ${wishCardData.card?.recieverlname} From ${wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname}`} />
                            <meta name="keywords" content={wishCardData.card_details?.meta_keyword} />
                            <meta property="og:title" content={`${wishCardData.card?.cardname} to ${wishCardData.card?.recieverfname} ${wishCardData.card?.recieverlname}`} />
                            <meta property="og:description" content={`${wishCardData.card?.cardname} to ${wishCardData.card?.recieverfname + " " + wishCardData.card?.recieverlname} From ${wishCardData.card?.senderfname} ${wishCardData.card?.senderlname}`} />
                            <meta property="og:image" content={wishCardData.card_details?.image} />
                        </Helmet>
                        <section className={`sign-card-area position-relative ${currentAction === "viewboard" && 'pb-0'}`}>
                            <ReactCanvasConfetti
                                className="canvas confitee-layout"
                                refConfetti={getInstance}
                            />
                            {isbookOpen ? (
                                <>
                                    <Container>
                                        {
                                            currentAction !== 'viewboard' && currentAction !== "Load" && (
                                                <div className="text-center signtext-ctm">
                                                    <h1>{wishCardData.card?.cardname} to {wishCardData.card?.recieverfname + " " + wishCardData.card?.recieverlname}</h1>
                                                    <h4>From {wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname} <br />
                                                        {/* {` Will be sent on ${wishCardData.card?.date} ${wishCardData.card?.time}`} */}
                                                        {wishCardData.card?.time && (
                                                            <>
                                                                {wishCardData.card?.status === "1" && (
                                                                    <>Will be sent on {wishCardData.card?.date} {wishCardData.card?.time}</>
                                                                )}
                                                            </>
                                                        )}
                                                    </h4>
                                                </div>
                                            )
                                        }
                                        {
                                            !isInvite && currentAction !== "viewboard" && currentAction !== "Load" && (

                                                <div className="buttons-group d-flex flex-wrap justify-content-center mt-3">
                                                    {
                                                        wishCardData.card?.status === '1' ? (
                                                            <>
                                                                {
                                                                    signedData.signed ?
                                                                        null
                                                                        :
                                                                        <button className="btn btn-lg btn-primary me-3 mb-3" onClick={() => handleAction("Sign")}><FaIcons.FaEdit className="me-1" /> Sign Card</button>
                                                                }
                                                                {wishCardData.card?.date === "NaN-NaN-NaN" && (
                                                                    <>
                                                                        {
                                                                            customer_id && customer_id === wishCardData.card?.customer_id && creatorSigned === 1 ? (
                                                                                <button className="btn btn-sm btn-primary dark me-3 mb-3" onClick={() => handleSendCard(wishCardData.card?.code)}><FaIcons.FaPaperPlane className="me-1" /> Finish & Send </button>

                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : null
                                                    }


                                                    <button className="btn btn-sm btn-primary outline me-3 mb-3" onClick={() => handleAction("Invite")}><FaIcons.FaUserPlus className="me-1" /> Invite</button>

                                                    {/* {
                                                        parseInt(wishCardData.card?.plan_id) > 1 && ( */}
                                                    <button className="btn btn-sm btn-primary dark mb-3 me-3" onClick={() => handleAction("viewboard")}><FaIcons.FaPaperPlane className="me-1" /> View Board </button>
                                                    {/* )
                                                    } */}
                                                    {
                                                        parseInt(wishCardData.card?.plan_id) > 1 && (
                                                      <button className="btn btn-sm btn-primary dark mb-3 me-3" onClick={() => openPDF()}><FaIcons.FaFileDownload className="me-1" /> Download </button>
                                                    )} 
                                                </div>
                                            )
                                        }
                                    </Container>
                                    {(() => {
                                        switch (currentAction) {
                                            case 'Card':
                                                return (
                                                    <Container>
                                                        <div className="flip-book-area">
                                                            <HTMLFlipBook
                                                                width={dimensions.width}
                                                                height={dimensions.height}
                                                                ref={book}
                                                                // size="stretch"
                                                                maxShadowOpacity={0.3}
                                                                showCover={true}
                                                                mobileScrollSupport={true}
                                                            >
                                                                <div className="page page-cover" data-density="hard">
                                                                    <div className="book-product-image">
                                                                        <img src={wishCardData?.card_details?.image} className="w-100 h-100" alt="wish image" />
                                                                    </div>
                                                                </div>

                                                                {
                                                                    wishCardData.card?.sing?.map((page, index) => {
                                                                        return (
                                                                            <div className="page" data-density="hard" key={'card' + index}>
                                                                                <div className="card-box-ui">
                                                                                    {
                                                                                        parseInt(page?.card_sing_id) === parseInt(signedData?.id) && signedData.signed ? (<button type="button" className="p-image-delete m-3" onClick={() => handleAction("Edit")}>
                                                                                            <FaIcons.FaEdit />
                                                                                        </button>) : null
                                                                                    }
                                                                                    {
                                                                                        page.message?.media !== "none" && (
                                                                                            <img src={page.message?.media !== "none" ? page.message?.media : logo} className="w-100" alt="signeture" />
                                                                                        )
                                                                                    }

                                                                                    <h4 className="text-start mt-3" style={{ fontFamily: page?.message?.fontfamily, fontSize: page.message?.fontsize, color: page.message?.fontcolor }}>{page.message?.message}</h4>
                                                                                    <h6 className="text-end mt-2" style={{ fontFamily: page?.message?.fontfamily, fontSize: page.message?.fontsize, color: page.message?.fontcolor }}>~ {page.message?.signerName}</h6>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    wishCardData?.card?.sing?.length % 2 !== 0 && !isMobile ?
                                                                        (
                                                                            <div className="page" data-density="hard" >
                                                                                <div className="card-box-ui"></div>
                                                                            </div>
                                                                        ) : <></>
                                                                }
                                                                <div className="page" data-density="hard">
                                                                    <div className="logo-box">
                                                                        <img src={logo} alt='egroup Greetings Logo' />
                                                                        <div className="bottom-tag-line">
                                                                            <p>Save Paper, Save Tree, Save Earth</p>
                                                                            <span>{dominname}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </HTMLFlipBook>
                                                        </div>
                                                        <div className="navigation-area">
                                                            <button className="book-btn book-prev-btn" type="button" onClick={() => book.current.pageFlip().flipPrev()}><FaIcons.FaArrowLeft /></button>
                                                            <button className="book-btn book-next-btn" type="button" onClick={() => book.current.pageFlip().flipNext()}><FaIcons.FaArrowRight /></button>
                                                        </div>
                                                        {/* <div className="edit_section mt-5 mb-3">
                                                            <div className="text-center">
                                                                <div className="edit_heading">
                                                                    <h5>Your Messages</h5>
                                                                    <p>You can directly edit your messages from here</p>
                                                                </div>
                                                                <form>
                                                                    <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                                                                        <Form.Control type="text" className="mx-auto input_name" value="name" />
                                                                        <div className="edit-btn">
                                                                            <button type="button"><img src={Edit} className="me-2" />Edit</button>
                                                                        </div>
                                                                    </Form.Group>
                                                                </form>
                                                            </div>
                                                        </div> */}
                                                    </Container>
                                                );
                                            case 'Invite':
                                                return (
                                                    <Container>
                                                        <section className="invite-area">
                                                            <Invites cardsdata={wishCardData.card} url={`${BASE_URL}/signcard/${code}`} handleReturn={() => handleAction("Card")} />
                                                        </section>
                                                    </Container>

                                                );
                                            case 'Sign':
                                                return (
                                                    <Container>
                                                        <SignCardEdit isSender={isSender} show={true} onHide={() => handleAction("Card")} code={code} from={wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname} id={wishCardData.card_details?.card_id} handleSendCard={handleMySign} />
                                                    </Container>
                                                );
                                            case 'Edit':
                                                return (
                                                    <Container>
                                                        <SignCardUpdate show={true} onHide={() => handleAction("Card")} code={code} from={wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname} card_sing_id={signedData.id} data={signDetails.message}
                                                            isSender={isSender} handleSendCard={handleMySign} />
                                                    </Container>
                                                );
                                            case 'viewboard':
                                                return (
                                                    <ViewBoard code={code} defaultbg={wishCardData.card?.background_image} signdata={wishCardData.card?.sing} cardtitle={`${wishCardData.card?.cardname} ${wishCardData.card?.recieverfname + " " + wishCardData.card?.recieverlname}`} from={wishCardData.card?.senderfname + " " + wishCardData.card?.senderlname} handleAction={handleAction} isCreator={customer_id === wishCardData.card?.customer_id} />
                                                );
                                            case 'Load': return (
                                                <LoadingSpinner />
                                            )
                                            default:
                                                return null;
                                        }
                                    })()}
                                </>
                            ) : (
                                <Container className="main-animate-container">
                                    <div className="mail-wrapper-box">
                                        <div className="envelope new">
                                            <div className="front">
                                                <div className="mailme">
                                                    <img src={logo} className="emailbox-logo" alt='egroup Greetings Logo' />
                                                    {/* <img src={ticket} className="tickit-img" alt='mail ticket' /> */}
                                                    {/* <p>Click Here</p> */}
                                                </div>
                                            </div>
                                            <div className="back">
                                                <div className="letter" >
                                                    <div className="letter-image" style={{ 'backgroundImage': `url(${wishCardData?.card_details?.image})` }}>
                                                    <img src={wishCardData?.card_details?.image} className="w-100 h-100" alt="wish image" />
                                                    </div>
                                                </div>
                                                <div className="flap left-flap"></div>
                                                <div className="flap right-flap"></div>
                                                <div className="flap bottom-flap"></div>
                                                <div className="flap top-flap"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            )}
                        </section>

                    </>) : (<EmptyState />)
            }
            <PDFExporter code={code} isToggled={isToggled} handleClose={handleClosePDF} isSignPage={true} wishCardData={wishCardData}/>
        </>
    );
}

export default SignCard;