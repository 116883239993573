import React, { useEffect } from "react";
import "./index.css";
import { Helmet } from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicy } from "../../actions";
import OgImage from "../../assets/img/favicon.png";
import parse from 'html-react-parser';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const PrivacyInfo = useSelector((state) => state.privacyData.data);
    useEffect(() => {
        dispatch(getPrivacyPolicy());
    }, []);
    // console.log('Privacy', PrivacyInfo);
    const currentURL = window.location.href;
    return (
        <>
            <Helmet>
                <title>{PrivacyInfo.title}</title>
                <meta name="title" content={PrivacyInfo.meta_title} />
                <meta name="description" content={PrivacyInfo.meta_description} />
                <meta name="keywords" content={PrivacyInfo.meta_keyword} />
                <meta property="og:title" content={PrivacyInfo.meta_title} />
                <meta property="og:description" content={PrivacyInfo.meta_description} />
                <meta property="og:image" content={OgImage} />
                <meta property="og:url" content={currentURL} />
            </Helmet>
            <section className="site-documents-area">
                <Container>
                    <Row>
                        <Col lg={12}>
                            {PrivacyInfo.description && parse(PrivacyInfo.description)}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default PrivacyPolicy;