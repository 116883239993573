import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    err: null,
    data:[],
};

const receivedCardReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.FetchReceivedCardStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.FetchReceivedCardSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.FetchReceivedCardFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
            default:
                return state;
    }

};
export default receivedCardReducer;