import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleUser, setCountry } from '../actions';
import useGeoLocation from "react-ipgeolocation";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  let isLogin = useSelector(state => state.authentication.isAuthenticated);
  const location = useGeoLocation();
  useEffect(()=>{
    if(!isLogin){
        dispatch(handleUser())
    }
},[isLogin])

//   useEffect(() => {
//     console.log(location)
//     if (location.country === "IN") {
//       dispatch(setCountry("INR"))
//     } else if(location.country !== "IN") {
//       dispatch(setCountry("USD"))
//     }
// }, [location]);


  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export default ScrollToTop;