import React from "react";
import Banner from "../../componants/Banner";
import Invitation from "../../componants/Invitation";
import Wishboard from "../../componants/Wishboard";
import Cardwork from "../../componants/Cardwork";
import Latestblog from "../../componants/Latestblog";
import Testimonial from "../../componants/Testimonial";
import Advertisebanner from "../../componants/Advertisebanner";
import { Helmet } from "react-helmet";
import { PAGE_TITLE } from "../../constants/constant";

import "./Homepage.css";
import Header from "../../componants/Header";
import Footer from "../../componants/Footer";
import WhyUs from "../../componants/WhyUs";

const Homepage = () => {
    return (
        <>
            <Helmet>
                <title>{PAGE_TITLE.Home_Title}</title>
                <meta name="title" content={PAGE_TITLE.Home_Title} />
                <meta name="description" content={'This is dummy description of egroup cards'} />
                <meta name="keywords" content={'This,keyword,keyword'} />
                <meta property="og:title" content={PAGE_TITLE.Home_Title} />
                <meta property="og:description" content={'This is dummy description of egroup cards'} />
                <meta property="og:image" content={'This,keyword,keyword'} />
                <meta property="og:url" content="https://egroup-greetings.jaitras.com/" />
            </Helmet>
            <Banner />
            {/* <Invitation /> */}
            <Cardwork />
            <Wishboard />
            <WhyUs />
            <Latestblog />
            <Testimonial />
            <Advertisebanner />

        </>
    );
}

export default Homepage;