import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./index.css";
import Table from "react-bootstrap/Table";
import Order from "../../assets/img/order.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUsers, getUsers } from "../../actions";
import EmptyState from "../../componants/EmptyState";
import * as FaIcons from "react-icons/fa";
import { useState } from "react";
import EditUser from "./EditUser";
import moment from "moment";
import Swal from "sweetalert2";

const UserList = ({ sidebarHandler }) => {
	const dispatch = useDispatch();
	const UserList = useSelector((state) => state.invitedUser.data.data);
	const customer_id = JSON.parse(localStorage.getItem("customer_id"));
	const [userData, setUserData] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [filter, setFilter] = useState({ name: "", email: "", bod: "" });
	const [appliedFilter, setAppliedFilter] = useState(false);


	const filterData = () => {
		const filteredData = UserList.filter((item) => {
            // console.log(item)
			const nameMatch =
				filter.name === "" ||
				item.firstname.toLowerCase().includes(filter.name.toLowerCase());
			const emailMatch =
				filter.email === "" ||
				item.email.toLowerCase().includes(filter.email.toLowerCase());
			const statusMatch =
				filter.bod === "" ||
                	item.bod.includes(moment(new Date(filter.bod), "YYYY-DD-MM").format("DD-MM-YYYY"));
			return nameMatch && emailMatch && statusMatch;
		});
        // console.log(filteredData)
		setFilteredData(filteredData);
		setAppliedFilter(true);
	};

	const handleFilterChange = (e) => {
		const { name, value } = e.target;
			setFilter({ ...filter, [name]: value });
	};

	useEffect(() => {
		dispatch(getUsers(customer_id));
	}, []);
	const handleDelete = (id, name) => {
		Swal.fire({
			title: `Are you sure you want to delete the record ${name}`,
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: "Yes",
			denyButtonText: `No`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				dispatch(DeleteUsers(id));
			} else if (result.isDenied) {
			}
		});
	};
	const handleEdit = (data) => {
		if (isEdit) {
			setUserData({});
			setIsEdit(!isEdit);
		} else {
			setUserData(data);
			setIsEdit(!isEdit);
		}
	};
    const clearFilters = () => {
		setFilter({ name: "", email: "", bod: "" });
		setFilteredData([]);
		setAppliedFilter(false);
	};
    const currentDate = new Date().toISOString().split('T')[0];


	return (
		<>
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center profile_heading_design">
					<h5 className="m-0 heading_board">All Users</h5>
				</div>
				<div className="btn btn-primary home_btn">
					<Link to="/home">
						<i className="fa-solid fa-house"></i>Home
					</Link>
				</div>
				
			</div>
			<hr />
            <div className="d-flex justify-content-end mb-4 mt-5">
                <Button
					type="button"
					className="btn-secondary home_btn wishboard_btn"
					onClick={() => sidebarHandler("adduser")}
				>
					Add User
				</Button>
                </div>
			<div className="account-box-design change_psw">
               
				<form className="d-flex align-items-center order-flex">
                <Form.Group className="me-2 modal-form">
								<Form.Control
									name="name"
									type="text"
									value={filter.name}
									placeholder="Recipient Name"
									onChange={(e) => handleFilterChange(e)}
                                    disabled={appliedFilter}
								/>
							</Form.Group>
							<Form.Group className="me-2 modal-form">
								<Form.Control
									name="email"
									type="email"
									value={filter.email}
									placeholder="Recipient Email"
									onChange={(e) => handleFilterChange(e)}
                                    disabled={appliedFilter}
								/>
							</Form.Group>
					<Form.Group className="me-2 modal-form">
						<Form.Control
                        name="bod"
							type="date"
                            value={filter.bod}
							onChange={(e) => handleFilterChange(e)}
                            disabled={appliedFilter}
                            max={currentDate}
						/>
					</Form.Group>

					{!appliedFilter ? (
								<Button
									type="button"
									className="home_btn me-2 wishboard_btn"
									onClick={() => filterData()}
								>
									Filter
								</Button>
							) : (
								<Button
									type="button"
									className="btn-secondary home_btn wishboard_btn"
									onClick={() => clearFilters()}
								>
									Reset
								</Button>
							)}
				</form>
			</div>
            
			<div className="board_table ">
				<Table bordered responsive>
					<thead className="table_header">
						<tr>
							<th>No.</th>
							<th>User Name</th>
							<th>Email</th>
							<th>DOB</th>
							<th className="text-end">Actions</th>
						</tr>
					</thead>
					<tbody>
                        {
                            appliedFilter ? (
                                        <>
                                            {filteredData?.length > 0 ? (
                                                <>
                                                    {filteredData.map((elem, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{elem.firstname + " " + elem.lastname}</td>
                                                                <td>{elem.email}</td>
                                                                <td>{elem.bod}</td>
                                                                {/* <td>{elem.marriage_date === '' ? 'Unmarried' : 'Married'}</td>
                                                                            <td>{elem.marriage_date}</td> */}
                                                                <td>
                                                                    <div className="action-row">
                                                                        <button
                                                                            className="action-btn edit"
                                                                            onClick={() => handleEdit(elem)}
                                                                        >
                                                                            <FaIcons.FaEdit />
                                                                        </button>
                                                                        <button
                                                                            className="action-btn delete"
                                                                            onClick={() =>
                                                                                handleDelete(elem.user_id, elem.firstname)
                                                                            }
                                                                        >
                                                                            <FaIcons.FaTrash />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                            ):(<EmptyState />)}
                                        </>
                            ):(
                                <>
                                {UserList?.length > 0 ?  (
                                    <>
                                        {UserList.map((elem, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{elem.firstname + " " + elem.lastname}</td>
                                                    <td>{elem.email}</td>
                                                    <td>{elem.bod}</td>
                                                    {/* <td>{elem.marriage_date === '' ? 'Unmarried' : 'Married'}</td>
                                                                <td>{elem.marriage_date}</td> */}
                                                    <td>
                                                        <div className="action-row">
                                                            <button
                                                                className="action-btn edit"
                                                                onClick={() => handleEdit(elem)}
                                                            >
                                                                <FaIcons.FaEdit />
                                                            </button>
                                                            <button
                                                                className="action-btn delete"
                                                                onClick={() =>
                                                                    handleDelete(elem.user_id, elem.firstname)
                                                                }
                                                            >
                                                                <FaIcons.FaTrash />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                ):(<EmptyState />)}
                                </>
                            )
                        }
						
					</tbody>
				</Table>
			</div>
			{isEdit && (
				<EditUser show={true} onHide={handleEdit} userData={userData} />
			)}
		</>
	);
};

export default UserList;
