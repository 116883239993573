import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./index.css";
import Table from "react-bootstrap/Table";
import Order from "../../assets/img/order.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchOrderCard, FetchWishCard, sendOrderCard } from "../../actions";
import moment from "moment";
import EmptyState from "../../componants/EmptyState";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as FaIcons from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import SvgIcon from "../../componants/SvgIcon";
import PricingPopup from "../Pricing/PricingPopup";
import { Modal } from "react-bootstrap";
import EditFullCard from "../EditFullCard";
import PDFExporter from "../PDFViewer";
const GroupOrder = () => {
	const dispatch = useDispatch();
	const ordercarddetails = useSelector(
		(state) => state.ordercarddetails.fetchedCards.data
	);
	const [filteredData, setFilteredData] = useState([]);
	const [filter, setFilter] = useState({ name: "", email: "", status: "" });
	const [appliedFilter, setAppliedFilter] = useState(false);
	const [editcard, setEditCard] = useState({ isedit: false, data: null });
	const [loading, setLoading] = useState(false);
	const [wishCardDatasss, setWishCardDatasss] = useState(null);
	const [selectedCode, setSelectedCode] = useState("none");
	const [isToggled, setIsToggled] = useState(false);

	const [isUpgrade, setsUpgrade] = useState({
		data: {},
		status: false,
		fulldata: {},
	});
	// console.log(filteredData, "filtered Data new");
	const [show, setShow] = useState(true);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const wishCardData = useSelector(
		(state) => state.ordercarddetails.wishCardData
	);
	console.log("wishCardData", ordercarddetails);
	useEffect(() => {
		setWishCardDatasss(wishCardData);
	}, [wishCardData]);
	useEffect(() => {
		dispatch(FetchOrderCard());
	}, []);
	const handleClosePDF = () => {
		setIsToggled(!isToggled);
	};
	// useEffect(() => {
	//     if (filter.type !== "none") {
	//         const newfilteredArray = ordercarddetails.
	//             filter(item => filter.type === "name" ? item.recieverfname.toLowerCase().includes(filter.searchterm.toLowerCase())
	//                 : filter.type === "email" ? item.recieveremail.toLowerCase().includes(filter.searchterm.toLowerCase())
	//                     : null)
	//         setFilteredData(newfilteredArray);

	//     }

	const filterData = () => {
		const filteredData = ordercarddetails.filter((item) => {
			const nameMatch =
				filter.name === "" ||
				item.recieverfname.toLowerCase().includes(filter.name.toLowerCase());
			const emailMatch =
				filter.email === "" ||
				item.recieveremail.toLowerCase().includes(filter.email.toLowerCase());
			const statusMatch = filter.status === "" || item.status === filter.status;
			return nameMatch && emailMatch && statusMatch;
		});
		setFilteredData(filteredData);
		setAppliedFilter(true);
	};
	const handleFilterChange = (e) => {
		const { name, value } = e.target;
		setFilter({ ...filter, [name]: value });
	};
	// }, [ordercarddetails, filter])

	const handleSendcard = (code) => {
		dispatch(sendOrderCard(code));
	};

	const handleUpdatePlanModal = (currentPlan, planid, code, fulldata) => {
		setsUpgrade({
			data: { currentPlan: currentPlan, planid: planid, code: code },
			status: true,
			fulldata: fulldata,
		});
	};
	const closeModal = () => {
		setsUpgrade({ data: {}, status: false, fulldata: {} });
	};
	const handleViewCard = (code) => {
		window.location.href = `${process.env.REACT_APP_APP_URL}/signcard/${code}`;
	};
	const handleSignCard = (code) => {
		window.location.href = `${process.env.REACT_APP_APP_URL}/create/signcard/${code}`;
	};
	const clearFilters = () => {
		setFilter({ name: "", email: "", status: "" });
		setFilteredData([]);
		setAppliedFilter(false);
	};

	const openPDF = (code) => {
		setSelectedCode(code);
		setIsToggled(true);
	};
	// console.log('ordercarddetails', ordercarddetails);

	return (
		<>
			{/* {loading && <p>Loading...</p>}

        {wishCardDatasss &&
            <div className="pdf-view">
                <div className="page" data-density="hard">
                    <div className="card-box-ui">
                        <img src={wishCardDatasss.card_details?.image !== "none" && wishCardDatasss.card_details?.image} className="w-100" alt="image" />
                    </div>
                </div>
                {
                    wishCardDatasss.card?.sing?.map((page, index) => {
                        return (
                            <div className="page" data-density="hard" key={'card' + index}>
                                <div className="card-box-ui">
                                    <img src={page.message?.media !== "none" && page.message?.media} className="w-100" alt="image" />
                                    <h4 className="text-start mt-3" style={{ fontFamily: page?.message?.fontfamily, fontSize: page.message?.fontsize, color: page.message?.fontcolor }}>{page.message?.message}</h4>
                                    <h6 className="text-end mt-2" style={{ fontFamily: page?.message?.fontfamily, fontSize: page.message?.fontsize, color: page.message?.fontcolor }}>~ {page.message?.signerName}</h6>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="page" data-density="hard">
                    <div className="logo-box">
                        <p>Save Paper, Save Tree, Save Earth</p>
                        <span>egroupgreeting.com</span>
                    </div>
                </div>
            </div>
            } */}
			{isUpgrade.status ? (
				<>
					<div className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img src={Order} className="me-3 heading-icon" />
							<h5 className="m-0 heading_board">Upgrade Plan</h5>
						</div>

						<Button
							type="button"
							className="home_btn table-btn"
							onClick={() => closeModal()}
						>
							Back
						</Button>
					</div>
					<div className="board_table mt-5">
						<Table bordered responsive>
							<thead className="table_header">
								<tr>
									<th>Cover</th>
									<th>Title & Recipient</th>
									<th>Card Type</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{1}</td>
									<td>
										<p className="mb-1 card-text-pra">
											{isUpgrade.fulldata.cardname}
										</p>
										<p className="mb-0 card-text-pra">
											{isUpgrade.fulldata.recieverfname +
												" " +
												isUpgrade.fulldata.recieverlname}
										</p>
										<p className="mb-0 card-text-pra">
											{isUpgrade.fulldata.recieveremail}
										</p>
									</td>
									<td>
										{isUpgrade.fulldata.plan_id === "1"
											? "Free"
											: isUpgrade.fulldata.plan_id === "2"
											? "Essential"
											: "Ultimate"}
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
					<div className="row">
						<PricingPopup
							col={"col-md-6 col-lg-4"}
							currentPlan={isUpgrade.data.currentPlan}
							planid={isUpgrade.data.planid}
							code={isUpgrade.data.code}
							handleClose={closeModal}
						/>
					</div>
				</>
			) : (
				<>
					<div className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<img src={Order} className="me-3 heading-icon" />
							{/* <i className="fa-solid fa-table me-3 fs-4 heading-icon"></i> */}
							<h5 className="m-0 heading_board">Group Card Orders</h5>
						</div>
						<div className="btn btn-primary home_btn">
							<Link to="/category">
								<i className="fa-solid fa-pencil"></i>Start A New Card
							</Link>
						</div>
					</div>
					<hr />
					{editcard.isedit ? (
						<EditFullCard
							editData={editcard.data}
							handleEdit={() => setEditCard({ isedit: false, data: null })}
						/>
					) : (
						<>
							<div className="account-box-design change_psw">
								<form className="d-flex align-items-center order-flex">
									<Form.Group className="me-2 modal-form">
										<Form.Control
											name="name"
											type="text"
											value={filter.name}
											placeholder="Recipient Name"
											onChange={(e) => handleFilterChange(e)}
											disabled={appliedFilter}
										/>
									</Form.Group>
									<Form.Group className="me-2 modal-form">
										<Form.Control
											name="email"
											type="email"
											value={filter.email}
											placeholder="Recipient Email"
											onChange={(e) => handleFilterChange(e)}
											disabled={appliedFilter}
										/>
									</Form.Group>
									{/* <Form.Group className="me-2 modal-form">
                            <Form.Control type="date" onChange={(e)=>handleFilterChange("bod",e.target.value)}/>
                        </Form.Group> */}
									<Form.Select
										aria-label="Default select example"
										value={filter.status}
										className="modal-form me-2"
										name="status"
										onChange={(e) => handleFilterChange(e)}
										disabled={appliedFilter}
									>
										<option value={""} disabled>
											Status
										</option>
										<option value="1">Not sent</option>
										<option value="0">Sent</option>
									</Form.Select>
									{!appliedFilter ? (
										<Button
											type="button"
											className="home_btn me-2 wishboard_btn"
											onClick={() => filterData()}
										>
											Filter
										</Button>
									) : (
										<Button
											type="button"
											className="btn-secondary home_btn wishboard_btn"
											onClick={() => clearFilters()}
										>
											Reset
										</Button>
									)}
								</form>
							</div>
							<div className="board_table mt-5">
								<Table bordered responsive>
									<thead className="table_header">
										<tr>
											<th>Cover</th>
											<th>Title & Recipients</th>
											<th>Card Type</th>
											<th>Upgrade Card</th>
											<th>Status</th>
											<th className="text-end">Actions</th>
										</tr>
									</thead>
									<>
										{appliedFilter ? (
											<>
												{filteredData?.length > 0 ? (
													<tbody>
														{filteredData?.map((card, index) => {
															return (
																<tr index={"grouporder" + index}>
                                                                        <td >
                                                                            <img src={card.image} alt={card.cardname} className="group-imageview" />
                                                                        </td>
																	<td>
																		<p className="mb-1 card-text-pra">
																			{card.cardname}
																		</p>
																		<p className="mb-0 card-text-pra">
																			{card.recieverfname +
																				" " +
																				card.recieverlname}
																		</p>
																		<p className="mb-0 card-text-pra">
																			{card.recieveremail}
																		</p>
																	</td>
																	<td>
																		{card.plan_id === "1"
																			? "Free"
																			: card.plan_id === "2"
																			? "Essential"
																			: "Ultimate"}
																	</td>

																	<td>
																		{parseInt(card.plan_id) !== 0 &&
																			parseInt(card.plan_id) !== 3 &&
																			card.status !== "0" && (
																				<Button
																					type="button"
																					className="home_btn me-2 table-btn"
																					onClick={() =>
																						handleUpdatePlanModal(
																							card.plan_id,
																							card.plan_id,
																							card.code,
																							card
																						)
																					}
																				>
																					<SvgIcon name={"Proicon"} /> Upgrade{" "}
																					<FaIcons.FaArrowRight />
																				</Button>
																			)}
																	</td>
																	<td>
																		{card.isCreaterSigned === 0 ? (
																			<OverlayTrigger
																				placement="right"
																				delay={{ show: 250, hide: 400 }}
																				overlay={<Tooltip>Sign card</Tooltip>}
																			>
																				<Button
																					type="button"
																					className="home_btn table-btn"
																					onClick={() =>
																						handleSignCard(card.code)
																					}
																				>
																					Sign Card
																				</Button>
																			</OverlayTrigger>
																		) : (
																			<>
																				{card.status === "0" ? (
																					<>
																						<p className="date">
																							{new Date(
																								card.send_date
																							).toLocaleDateString("en-US", {
																								month: "short",
																								day: "numeric",
																								year: "numeric",
																							})}
																						</p>
																						<Badge bg="success">Sent</Badge>
																					</>
																				) : (
																					<OverlayTrigger
																						placement="right"
																						delay={{ show: 250, hide: 400 }}
																						overlay={
																							<Tooltip>Send card</Tooltip>
																						}
																					>
																						<Button
																							type="button"
																							className="home_btn table-btn"
																							onClick={() =>
																								handleSendcard(card.code)
																							}
																							disabled={card.status === "0"}
																						>
																							Send Card
																						</Button>
																					</OverlayTrigger>
																				)}
																			</>
																		)}
																	</td>
																	<td>
																		<div className="action-row d-flex justify-content-center">
																			<div className="action-row ">
																				{parseInt(card.plan_id) > 1 && (
																					<OverlayTrigger
																						placement="right"
																						delay={{ show: 250, hide: 400 }}
																						overlay={
																							<Tooltip>Download PDF</Tooltip>
																						}
																					>
																						<button
																							type="button"
																							className="action-btn edit"
																							onClick={() => openPDF(card.code)}
																						>
																							<FaIcons.FaFileDownload />
																						</button>
																					</OverlayTrigger>
																				)}
																				<OverlayTrigger
																					placement="right"
																					delay={{ show: 250, hide: 400 }}
																					overlay={<Tooltip>ViewCard</Tooltip>}
																				>
																					<button
																						type="button"
																						className="action-btn edit"
																						onClick={() =>
																							handleViewCard(card.code)
																						}
																					>
																						<FaIcons.FaEye />
																					</button>
																				</OverlayTrigger>
																			</div>
																			<OverlayTrigger
																				placement="right"
																				delay={{ show: 250, hide: 400 }}
																				overlay={<Tooltip>Edit card</Tooltip>}
																			>
																				<button
																					type="button"
																					className="action-btn edit"
																					disabled={card.status === "0"}
																				>
																					<FaIcons.FaEdit />
																				</button>
																			</OverlayTrigger>

																			{/* <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={
                                                                    <Tooltip>
                                                                        Delete card
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="action-btn delete" disabled={card.status === "0"} ><FaIcons.FaTrash /></button>
                                                            </OverlayTrigger> */}
																		</div>
																	</td>
																</tr>
															);
														})}
													</tbody>
												) : (
													<div className="d-flex justify-content-center w-100">
														<EmptyState />
													</div>
												)}
											</>
										) : (
											<>
												{ordercarddetails?.length > 0 ? (
													<tbody>
														{ordercarddetails?.map((card, index) => {
															return (
																<tr>
																	<td>
																		<img src={card.image} alt={card.cardname} className="group-imageview" />
																	</td>
																	<td>
																		<p className="mb-1 card-text-pra">
																			{card.cardname}
																		</p>
																		<p className="mb-0 card-text-pra">
																			{card.recieverfname +
																				" " +
																				card.recieverlname}
																		</p>
																		<p className="mb-0 card-text-pra">
																			{card.recieveremail}
																		</p>
																	</td>
																	<td>
																		{card.plan_id === "1"
																			? "Free"
																			: card.plan_id === "2"
																			? "Essential"
																			: "Ultimate"}
																	</td>

																	<td>
																		{parseInt(card.plan_id) !== 0 &&
																			parseInt(card.plan_id) !== 3 &&
																			card.status !== "0" && (
																				<Button
																					type="button"
																					className="home_btn me-2 table-btn"
																					onClick={() =>
																						handleUpdatePlanModal(
																							card.plan_id,
																							card.plan_id,
																							card.code,
																							card
																						)
																					}
																				>
																					<SvgIcon name={"Proicon"} /> Upgrade{" "}
																					<FaIcons.FaArrowRight />
																				</Button>
																			)}
																	</td>
																	<td>
																		{card.isCreaterSigned === 0 ? (
																			<OverlayTrigger
																				placement="right"
																				delay={{ show: 250, hide: 400 }}
																				overlay={<Tooltip>Sign card</Tooltip>}
																			>
																				<Button
																					type="button"
																					className="home_btn table-btn"
																					onClick={() =>
																						handleSignCard(card.code)
																					}
																				>
																					Sign Card
																				</Button>
																			</OverlayTrigger>
																		) : (
																			<>
																				{card.status === "0" ? (
																					<>
																						<p className="date">
																							{new Date(
																								card.send_date
																							).toLocaleDateString("en-US", {
																								month: "short",
																								day: "numeric",
																								year: "numeric",
																							})}
																						</p>
																						<Badge bg="success">Sent</Badge>
																					</>
																				) : (
																					<OverlayTrigger
																						placement="right"
																						delay={{ show: 250, hide: 400 }}
																						overlay={
																							<Tooltip>Send card</Tooltip>
																						}
																					>
																						<Button
																							type="button"
																							className="home_btn table-btn"
																							onClick={() =>
																								handleSendcard(card.code)
																							}
																							disabled={card.status === "0"}
																						>
																							Send Card
																						</Button>
																					</OverlayTrigger>
																				)}
																			</>
																		)}
																	</td>
																	<td>
																		<div className="action-row d-flex justify-content-center">
																			<div className="action-row ">
																				{parseInt(card.plan_id) > 1 && (
																					<OverlayTrigger
																						placement="right"
																						delay={{ show: 250, hide: 400 }}
																						overlay={
																							<Tooltip>Download PDF</Tooltip>
																						}
																					>
																						<button
																							type="button"
																							className="action-btn edit"
																							onClick={() => openPDF(card.code)}
																						>
																							<FaIcons.FaFileDownload />
																						</button>
																					</OverlayTrigger>
																				)}

																				<OverlayTrigger
																					placement="right"
																					delay={{ show: 250, hide: 400 }}
																					overlay={<Tooltip>ViewCard</Tooltip>}
																				>
																					<button
																						type="button"
																						className="action-btn edit"
																						onClick={() =>
																							handleViewCard(card.code)
																						}
																					>
																						<FaIcons.FaEye />
																					</button>
																				</OverlayTrigger>
																			</div>
																			<OverlayTrigger
																				placement="right"
																				delay={{ show: 250, hide: 400 }}
																				overlay={<Tooltip>Edit card</Tooltip>}
																			>
																				<button
																					type="button"
																					className="action-btn edit"
																					disabled={card.status === "0"}
																					onClick={() =>
																						setEditCard({
																							isedit: true,
																							data: card,
																						})
																					}
																				>
																					<FaIcons.FaEdit />
																				</button>
																			</OverlayTrigger>
																			{/* <OverlayTrigger
                                                                                    placement="bottom"
                                                                                    delay={{ show: 250, hide: 400 }}
                                                                                    overlay={<Tooltip>Download PDF</Tooltip>}
                                                                                >
                                                                                    <button
                                                                                        type="button"
                                                                                        className="action-btn edit"
                                                                                        code={card.code}
                                                                                        onClick={() => handleDownloadClick(card.code)}
                                                                                    >
                                                                                        <FaIcons.FaDownload />

                                                                                    </button>
                                                                                </OverlayTrigger> */}
																			{/* <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={
                                                                    <Tooltip>
                                                                        Delete card
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button type="button" className="action-btn delete" disabled={card.status === "0"} ><FaIcons.FaTrash /></button>
                                                            </OverlayTrigger> */}
																		</div>
																	</td>
																</tr>
															);
														})}
													</tbody>
												) : (
													<div className="d-flex justify-content-center w-100">
														<EmptyState />
													</div>
												)}
											</>
										)}
									</>
								</Table>
							</div>
							{selectedCode !== "none" && (
								<PDFExporter
									code={selectedCode}
									isToggled={isToggled}
									handleClose={handleClosePDF}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default GroupOrder;
