import React from "react";
import { Col, Row } from "react-bootstrap";
import UserIcon from '../../assets/img/user-icon.png'
import PricingCard from "../../componants/PricingCard";
import { useSelector } from "react-redux";

const StepFour = ({ register, errors, watch, id, handleReset, handleAuthCard, isPremium, selectedImage, encryptid }) => {
    const isLoggedin = useSelector(state => state.authentication.isAuthenticated);
    let watchdata = watch();

    return (
        <>
            <Row>
                <Col lg={12}>
                    {
                        isLoggedin ? (
                            <>
                                <PricingCard isOrderCard watchdata={watchdata} cardid={id} handleResetOrder={handleReset} isPremium={isPremium} selectedImage={selectedImage} encryptid={encryptid} />
                            </>
                        ) : (<div className="craete-card-details text-center">
                            <img src={UserIcon} alt="" className="img-fluid mt-4 mb-4" />
                            <p className="login-link mb-5">If you are not login so Click here to <span className='login-text' onClick={() => handleAuthCard("signin")}>LOGIN</span> or <span className='login-text' onClick={() => handleAuthCard("signup")}>SIGN UP</span></p>
                        </div>)
                    }
                </Col>
            </Row>
            {/*                    
            <Row>
                <Col lg={12}>
                    <div className="link-btn d-flex align-items-center justify-content-between flex-wrap mt-5">
                        <Button type={"submit"} className="btn btn-primary mb-3">Continue with free plan</Button>
                        <Button type={"Submit"} className="btn btn-primary mb-3">Processed to pay</Button>
                    </div>
                </Col>
            </Row> */}
        </>
    );
}

export default StepFour;