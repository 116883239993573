import React from "react";
import { Col, Row } from "react-bootstrap";
import leftBannerShape from "../assets/img/image2.svg";
import rightBannerShape from "../assets/img/image4.svg";
import Slide1 from "../assets/img/business-slide/1.png";
import Slide2 from "../assets/img/business-slide/2.png";
import Slide3 from "../assets/img/business-slide/3.png";
import Slide4 from "../assets/img/business-slide/4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
const BusinessSlider = () => {
    return (
        <>
            <section className="banner-area with-slide">
                <img src={leftBannerShape} alt="" className="left-banner-shape" />
                <img src={rightBannerShape} alt="" className="right-shade-img" />
                <div className="container">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        autoHeight={true}
                        autoplay={true}
                        modules={[Pagination]}
                        className="mySwiper businessSliderarea"
                    >
                        <SwiperSlide key={1}>
                            <Row className="align-items-center">
                                <Col sm={6} md={6} lg={6} className="banner-title-design">
                                    <div className="slider-title-area">
                                        <h1 className="mb-4">Build a People-first Culture Invest in people for payback</h1>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6} className="banner-col-design">
                                    <div className="banner-img position-relative">
                                        <img src={Slide1} alt="" className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <Row className="align-items-center">
                                <Col sm={6} md={6} lg={6} className="banner-title-design">
                                    <div className="slider-title-area">
                                        <h1 className="mb-4">Reimagine the workplace Appreciation For the new age workforce</h1>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6} className="banner-col-design">
                                <div className="banner-img position-relative">
                                        <img src={Slide2} alt="" className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <Row className="align-items-center">
                                <Col sm={6} md={6} lg={6} className="banner-title-design">
                                    <div className="slider-title-area">
                                        <h1 className="mb-4">Turn your company into THE best place to work</h1>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6} className="banner-col-design">
                                <div className="banner-img position-relative">
                                        <img src={Slide3} alt="" className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <Row className="align-items-center">
                                <Col sm={6} md={6} lg={6} className="banner-title-design">
                                    <div className="slider-title-area">
                                        <h1 className="mb-4">It's time to move away from automated boring greeting mailers.</h1>
                                    </div>
                                </Col>
                                <Col sm={6} md={6} lg={6} className="banner-col-design">
                                <div className="banner-img position-relative">
                                        <img src={Slide4} alt="" className="img-fluid" />
                                    </div>
                                </Col>
                            </Row>
                        </SwiperSlide>
                    </Swiper>

                </div>
            </section>
        </>
    );
}

export default BusinessSlider;