import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "../reducers/authReducer";
import blogCategoryReducer from "../reducers/blogCategoryReducer";
import categoryReducer from "../reducers/categoryReducer";
import giphyReducer from "../reducers/giphyReducer";
import orderCardReducer from "../reducers/orderCardReducer";
import planReducer from "../reducers/planReducer";
import subCategoryReducer from "../reducers/subCategoryReducer";
import timeZoneReducer from "../reducers/timeZoneReducer";
import userReducer from "../reducers/UserReducer";
import wishlistReducer from "../reducers/wishlistReducer";
import receivedCardReducer from "../reducers/receivedCardReducer";
import faqReducer from "../reducers/faqReducer";
import privacyReducer from "../reducers/privacyReducer";
import termsReducer from "../reducers/termsReducer";
import bgImageReducer from "../reducers/bgImageReducer";
import aboutReducer from "../reducers/aboutReducer";
import paymentReducer from "../reducers/paymentReducer";
import pdfReducer from "../reducers/pdfReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
	applyMiddleware(thunk)
	// other store enhancers if any
);


const store = configureStore({
	reducer: {
		category:categoryReducer,
		subcategory:subCategoryReducer,
		wishlistpost:wishlistReducer,
		authentication:authReducer,
		pricingPlan:planReducer,
		giphyData:giphyReducer,
        blogdata:blogCategoryReducer,
		ordercarddetails:orderCardReducer,
        invitedUser: userReducer,
        timezoneList: timeZoneReducer,
        receivedList: receivedCardReducer,
		faq: faqReducer,
		bgImage: bgImageReducer,
        privacyData: privacyReducer,
        termsData: termsReducer,
        aboutData: aboutReducer,
		billdeskReducer:paymentReducer,
		pdf:pdfReducer,
	},
    middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}),
});

export default store;