import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ChromePicker, SliderPicker } from 'react-color';


const fontFamilies = [
    { label: 'Alkatra', value: 'Alkatra' },
    { label: 'Caveat', value: 'Caveat' },
    { label: 'Dancing Script', value: 'DancingScript' },
    { label: 'Fasthand', value: 'Fasthand' },
    { label: 'IndieFlower', value: 'IndieFlower' },
    { label: 'Kalam', value: 'Kalam' },
    { label: 'OpenSans', value: 'OpenSans' },
    { label: 'Pacifico', value: 'Pacifico' },
    { label: 'PlayfairDisplay', value: 'PlayfairDisplay' },
    { label: 'RobotoSerif', value: 'RobotoSerif' },
    { label: 'Satisfy', value: 'Satisfy' },
    { label: 'SourceSerifPro', value: 'SourceSerifPro' },

    // add more font families as needed
];

function CardText({ handleSelect, data, isSender }) {


    return (
        <Row className='mt-4'>

            {
                !isSender && (
                    <>
                        <Col lg={6}>
                            <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" value={data?.signerName} placeholder="Enter Name" required onChange={(e) => handleSelect("signerName", e.target.value)} />

                            </Form.Group>
                        </Col>
                        <Col lg={6}>

                            <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" value={data?.signerEmail} placeholder="Enter Your Email Address" onChange={(e) => handleSelect("signerEmail", e.target.value)} required />
                            </Form.Group>
                        </Col>
                    </>
                )
            }

            <Col lg={12}>
                <Form.Group className="mb-3 modal-form">
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control as="textarea" rows={5} cols={50} maxLength={400} className='message-area' placeholder="Pen your personal message here..." value={data.message} onChange={(e) => handleSelect("message", e.target.value)} />
                    <p className='form-error-message mt-1'>{400 - data?.message?.length} characters left</p>
                </Form.Group>
            </Col>
            <Col lg={4}>
                <Form.Group className="mb-3 modal-form">
                    <Form.Label>Style Your Message</Form.Label>
                    <select
                        value={data?.fontfamily}
                        className='form-control form-select'
                        onChange={e => handleSelect("fontfamily", e.target.value)}
                    >
                        {fontFamilies.map(({ label, value }) => (
                            <option key={value} value={value}>{label}</option>
                        ))}
                    </select>
                </Form.Group>
            </Col>
            <Col lg={3}>
                <Form.Group className="mb-3 modal-form">
                    <Form.Label className='w-100'>Font size</Form.Label>
                    <Button type="button" className="mb-2 me-2 btn-danger active plus" onClick={data.fontsize > 10 ? () => handleSelect("fontsize", data.fontsize - 1) : null}>
                        -
                    </Button>
                    <Button className="mb-2 mx-2 btn-danger plus" disabled>
                        {data.fontsize}
                    </Button>
                    <Button type="button" className="mb-2 ms-2 btn-danger active plus" onClick={data.fontsize < 30 ? () => handleSelect("fontsize", data.fontsize + 1) : null}>
                        +
                    </Button>
                </Form.Group>
            </Col>
            <Col lg={5}>
                <Form.Group className="mb-3 modal-form">
                    <Form.Label className='w-100'>Color</Form.Label>
                    <SliderPicker
                        color={data.color}
                        onChangeComplete={color => handleSelect("color", color.hex)}
                    />
                </Form.Group>
            </Col>

        </Row>
    );
}
export default CardText