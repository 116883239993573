import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SvgIcon from "./SvgIcon";

const Cardwork = () => {
    return (
        <>
            <section className="cardwork-area">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="section-title-area text-center mb-5">
                                <h2 className="mb-4">How <span className="title-design position-relative">eCards</span> Works? </h2>
                                {/* <p>With tools make sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p> */}
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-lg-5">
                        <Col md={4} sm={6}>
                            <Card className={'cardwork-list wishing-card border-0'}>
                                <div className="card-pattern">
                                    <SvgIcon name={'CardPattern'} />
                                </div>
                                <Card.Body>
                                    <div className="inner-icon mb-3">
                                        <SvgIcon name={'Invite'} />
                                    </div>
                                    <Card.Title className="mb-3">Choose your <span className="onlineer">eCard</span> </Card.Title>
                                    <Card.Text>Choose From our eCard libeary or design your own. Add date,time,photos,and more; See it instantly on your screen</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={6}>
                            <Card className={'cardwork-list wishing-card border-0'}>
                                <div className="card-pattern">
                                    <SvgIcon name={'CardPattern'} />
                                </div>
                                <Card.Body>
                                    <div className="inner-icon mb-3">
                                        <SvgIcon name={'Deliver'} />
                                    </div>
                                    <Card.Title className="mb-3">Invite for <span className="onlineer">eCollaboration</span> </Card.Title>
                                    <Card.Text>Share a unique greeting card link with your friend, family, team members for them  to add personalized wishes.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12}>
                            <Card className={'cardwork-list wishing-card border-0 category-item'}>
                                <div className="card-pattern">
                                    <SvgIcon name={'CardPattern'} />
                                </div>
                                <Card.Body>
                                    <div className="inner-icon mb-3">
                                        <SvgIcon name={'Message'} />
                                    </div>
                                    <Card.Title className="mb-3">Greeting card is <span className="onlineer">eDelivered</span></Card.Title>
                                    <Card.Text>Your loved one will receive the greeting card by email at the scheduled date and time making the occasion special.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    );
}

export default Cardwork;