import React from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './index.css';
import Lock from '../../assets/img/padlock.png'
import { useState } from 'react';
import { changePassword } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as FaIcons from "react-icons/fa";

const ChangePassword = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const dispatch = useDispatch();
    const passwordSchema = yup.object().shape({
        password: yup.string()
            .required('No password provided.')
            .min(6, 'Password is too short - should be 6 chars minimum.')
            .matches(/[a-zA-Z0-9]/, 'Password can only contain Latin letters.'),
        confirm: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        mode: "all",
        resolver: yupResolver(passwordSchema)
    });
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const onSubmit = (data) => {

        dispatch(changePassword({ customer_id: localStorage.getItem("customer_id"), ...data }, handleSuccess))
    };

    const handleSuccess = () => {
        reset();
    }
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center profile_heading_design">
                    <img src={Lock} className="me-3 heading-icon" />
                    <h5 className='m-0 heading_board'>Change Password</h5>
                </div>
                <div className="btn btn-primary home_btn">
                    <Link to="/home"><i className="fa-solid fa-house"></i>Home</Link>
                </div>
            </div>
            <hr />
            <hr />
            <div className="breadcrumb-list mb-3">
                <Breadcrumb>
                    <Breadcrumb.Item href="/category">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="account-box-design change_psw">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <div className='position-relative'>
                        <Form.Control type={passwordVisible ? 'text' : 'password'} placeholder="Enter New Password" {...register("password", { required: true })} />
                        {!passwordVisible ? (
                            <FaIcons.FaEyeSlash
                                className='eyeview'
                                onClick={togglePasswordVisibility}
                            />
                        ) : (
                            <FaIcons.FaEye
                                className='eyeview'
                                onClick={togglePasswordVisibility}
                            />
                        )}
                        </div>
                        <p className='form-error-message'>{errors.password?.message}</p>

                    </Form.Group>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control id="confirm" type={passwordVisible ? 'text' : 'password'} placeholder="Reenter New Password" {...register("confirm", { required: true })} />
                        <p className='form-error-message'>{errors.confirm?.message}</p>
                    </Form.Group>


                    <Button variant="primary" type="submit" className="mt-3">
                        Change Password
                    </Button>
                </Form>

            </div>
        </>
    )
}

export default ChangePassword;