import React, { useEffect } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import { activateMail, resendAuthCode } from '../actions';
import { useState } from 'react';
import Swal from "sweetalert2";
import { toast } from 'react-toastify';

function Activate() {
    const {authcode} = useParams();
    const dispatch = useDispatch();
    const authData = useSelector((state) => state.authentication);
    const navigate = useNavigate();
    const [reactivate,setReActivate] =useState(false);
    const [emailError,setemailErrors] = useState("");
    const [email,setEmail] = useState("");

    // console.log("this is authcode",authcode)
  const handleSend = (type) =>{
    if(type === "success"){
        Swal.fire({
            title: 'Activation Successfull',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Homepage',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.href = process.env.REACT_APP_APP_URL;
            } else if (result.isDenied) {
              
            }
          })
    }
      else if(type === "failure"){
        toast.error("Failed to activate url please retry with a new code")
      setReActivate(true)
    }
    else if(type === "codesuccess"){
      Swal.fire({
        title: 'New Code will be Sent Soon',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: 'Reload',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          window.location.href = process.env.REACT_APP_APP_URL;
        } else if (result.isDenied) {
          
        }
      })
    }
    else if(type === "codefailure"){
      toast.error("failed to resend code please retry")
    }
 
  }
  
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email.match(emailRegex)) {
      setemailErrors("Email is not valid");
      return false;
    } else {
      setemailErrors("");
      return true;
    }
  };

  const onSubmit = () => {
    const isEmailValid = validateEmail(email);
if (isEmailValid) {
    dispatch(resendAuthCode({email:email},handleSend))
}
};

useEffect(()=>{
  if(authcode !== undefined){
    dispatch(activateMail(authcode,handleSend))
  }  
},[authcode])
  return (
    <div className="activate-holder">
      {
        reactivate ? (<>
        
                <h2 className="text-center mb-5"><span className="title-design position-relative">Sign</span> In</h2>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control  type="email" placeholder="Enter Your Email Address"  onChange={(e)=>setEmail(e.target.value)}/>
                        <p className='form-error-message mt-1'>{authData.loginError !== "Incorrect password!" && authData.loginError !== "none" ? `${authData.loginError} | ` : ""} {emailError}</p>
                    </Form.Group>                   
                    <Button variant="primary" className="mt-3" onClick={()=>onSubmit()} >
                        Resend Code
                    </Button>
                
            
        </>):(
          <>
          <Spinner animation="grow" />
    <div>Please wait till the activation completes</div>
          </>
        )
      }
    
    </div>
    
  )
}

export default Activate