import React from 'react'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useState } from 'react';
import { activateMail, resendAuthCode } from '../actions';
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
function ResendEmail({show,onHide}) {
    const authData = useSelector((state) => state.authentication);
    const [emailError,setemailErrors] = useState("");
    const [email,setEmail] = useState("");
    const dispatch = useDispatch();
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email.match(emailRegex)) {
            setemailErrors("Email is not valid");
            return false;
        } else {
            setemailErrors("");
            return true;
        }
    };
    const handleSend = (type) =>{
         if(type === "codesuccess"){
          Swal.fire({
            title: 'New Code will be Sent Soon',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'Reload',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.href = process.env.REACT_APP_APP_URL;
            } else if (result.isDenied) {
            }
          })
        }
        else if(type === "codefailure"){
          toast.error("failed to resend code please retry")
        }
     
      }
    const onSubmit = () => {
        const isEmailValid = validateEmail(email);
    if (isEmailValid) {
        dispatch(resendAuthCode({email:email},handleSend))
    }
    };
  return (
    <Modal show={show} onHide={onHide} centered>
            <Modal.Body className="section-title-area sign-in-modal">
                <button type="button" class="popup-btn-close btn-close" onClick={onHide} aria-label="Close"></button>
                <h2 className="text-center mb-5">
                    <span className="title-design position-relative">Resend</span> Email
                </h2>
                <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control  type="email" placeholder="Enter Your Email Address"  onChange={(e)=>setEmail(e.target.value)}/>
                    </Form.Group>                   
                    <Button variant="primary" className="mt-3" onClick={()=>onSubmit()} >
                        Resend Email
                    </Button>
                </Modal.Body>
                </Modal>
  )
}

export default ResendEmail