import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Price111 from "../assets/img/price-111.png";
import Price222 from "../assets/img/price-222.png";
import Price333 from "../assets/img/price-333.png";
import SvgIcon from "./SvgIcon";
import { useDispatch, useSelector } from "react-redux";
import {
    B2BProcess,
    OrderCard,
    createStripeId,
    getPricePlan,
    handleBilldesk,
    setCountry,
} from "../actions";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import * as FaIcons from "react-icons/fa";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { toast } from "react-toastify";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const businessPlanSchema = yup.object().shape({
    customer_id: yup.string(),
    name: yup
        .string()
        .min(3, "First name should contain at least 3 characters")
        .required("Please Enter your First Name"),
    company_name: yup.string().required("Please Enter your Last Name"),
    email: yup.string().email().required("Please Enter Email"),
    mobile_number: yup.string().matches(phoneRegExp, "Phone number is not valid"),
    num_of_employees: yup
        .number()
        .min(1)
        .required("Enter the No. of Employees")
        .default(1),

    num_of_cards: yup
        .number()
        .min(1)
        .required("Enter the No. of Cards")
        .default(1),
    message: yup.string().required("Please Enter Message"),
});

const PricingCard = ({
    isOrderCard = false,
    watchdata,
    handleResetOrder,
    cardid,
    isPremium,
    selectedImage,
    encryptid,
}) => {
    const Pricingplan = useSelector((state) => state.pricingPlan.data);
    const PlanHistory = useSelector((state) => state.pricingPlan.ownedPlans);
    const [isCustomPlan, setIsCustomPlan] = useState(false);
    const currencyType = useSelector((state) => state.authentication.country);
    const [stripeSecret, setStripeSecret] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const dispatch = useDispatch();
    const [finalTotal, setFinalTotal] = useState(0);

    const handleTogglePlan = () => {
        setIsCustomPlan(!isCustomPlan);
    };

    useEffect(() => {
        setIsLoading(false);
    }, [currencyType]);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(businessPlanSchema),
        defaultValues: {
            customer_id: localStorage.getItem("customer_id"),
        },
    });

    useEffect(() => {
        dispatch(getPricePlan());
        // dispatch(FetchPlanHistory());
    }, []);
    const handleReset = () => {
        reset();
    };
    const onSubmit = (data) => {
        dispatch(B2BProcess(data, handleReset));
    };
    const handleStripe = (clientSecret) => {
        setStripeSecret(clientSecret);
    };
    const handleClearStripe = () => {
        setStripeSecret(null);
    };
    const handleStripePayment = async (id, amount, quantity) => {
        const isLogin = localStorage.getItem("isLogin");
        if (isLogin) {
            if (currencyType === "INR") {
                const { value: gst } = await Swal.fire({
                    title: "Do you have a GST Number?",
                    text: "If yes, Please Input your GSTIN",
                    input: "text",
                    inputPlaceholder: "Enter your GST",
                    showCancelButton: true,
                    cancelButtonText: "Skip and Continue",
                    inputValidator: (value) => {
                        if (!value) {
                            return "GST number is required";
                        }

                        // Define the GST regex pattern for validation
                        const gstRegex =
                            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;

                        if (!gstRegex.test(value)) {
                            return "Invalid GST format. Please enter a valid GSTIN.";
                        }
                    },
                });
                // console.log(gst)
                if (gst) {
                    // console.log("ere")
                    if (isOrderCard) {
                        handleCardStorage(id);

                        paymentSwitcher(true, true, id, amount, quantity, gst);
                    } else {
                        // console.log('here')
                        handleCardStorage(id);
                        paymentSwitcher(true, true, id, amount, quantity, gst);
                    }
                } else {
                    if (isOrderCard) {
                        handleCardStorage(id);
                        paymentSwitcher(false, true, id, amount, quantity);
                    } else {
                        handleCardStorage(id);
                        paymentSwitcher(false, false, id, amount, quantity);
                    }
                }
            } else {
                if (isOrderCard) {
                    handleCardStorage(id);
                    paymentSwitcher(false, true, id, amount, quantity);
                } else {
                    handleCardStorage(id);
                    paymentSwitcher(false, false, id, amount, quantity);
                }
            }

        } else {
            toast.error("Please Login to Buy Plans");
        }
    };
    const handleOrderCard = (id) => {
        let originalValue = watchdata.date;
        const dateObj = new Date(originalValue);
        const day = dateObj.getDate().toString().padStart(2, "0");
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getFullYear().toString();
        const formattedDate = `${year}-${month}-${day}`;
        // console.log("Formatted date:", formattedDate);

        let cardDataobj = { ...watchdata };
        cardDataobj["date"] = formattedDate;
        // console.log(id, selectedImage, "tihis data");
        // console.log(encryptid)
        if (encryptid === "addimage") {
            // console.log("this flow")
            dispatch(
                OrderCard(
                    {
                        ...cardDataobj,
                        customer_id: localStorage.getItem("customer_id"),
                        plan_id: id,
                        card_id: 0,
                        card_image: selectedImage,
                    },
                    handleResetOrder
                )
            );
        } else {
            dispatch(
                OrderCard(
                    {
                        ...cardDataobj,
                        customer_id: localStorage.getItem("customer_id"),
                        plan_id: id,
                        card_id: cardid,
                    },
                    handleResetOrder
                )
            );
        }
    };

    const handleCardStorage = (id) => {
        if (encryptid === "addimage") {
            // console.log(selectedImage);
            const reader = new FileReader();
            reader.onload = function (event) {
                const base64String = event.target.result;
                localStorage.setItem(
                    "fileData",
                    JSON.stringify({
                        base64String: base64String,
                        fileType: selectedImage.type,
                        name: selectedImage.name,
                    })
                );
            };
            reader.readAsDataURL(selectedImage);

            localStorage.setItem(
                "orderdetails",
                JSON.stringify({
                    ...watchdata,
                    card_image: true,
                    plan_id: id,
                    customer_id: localStorage.getItem("customer_id"),
                    card_id: cardid,
                })
            );
        } else {
            localStorage.setItem(
                "orderdetails",
                JSON.stringify({
                    ...watchdata,
                    plan_id: id,
                    customer_id: localStorage.getItem("customer_id"),
                    card_id: cardid,
                })
            );
        }
    };
    const paymentSwitcher = (isgst, isordercard, id, amount, quantity, gst) => {
        // console.log(isgst, isordercard, id, amount, quantity, gst);
        if (isgst) {
            if (currencyType === "INR") {
                dispatch(
                    handleBilldesk(
                        id,
                        amount,
                        quantity,
                        currencyType,
                        "billDesk",
                        isordercard ? "ordercomplete" : "completion",
                        "",
                        gst,
                        () => { }
                    )
                );
            } else {
                dispatch(
                    createStripeId(
                        id,
                        amount,
                        quantity,
                        currencyType,
                        "stripe",
                        gst,
                        handleStripe
                    )
                );
            }
        } else {
            if (currencyType === "INR") {
                dispatch(
                    handleBilldesk(
                        id,
                        amount,
                        quantity,
                        currencyType,
                        "billDesk",
                        isordercard ? "ordercomplete" : "completion",
                        "",
                        "",
                        () => { }
                    )
                );
            } else {
                dispatch(
                    createStripeId(
                        id,
                        amount,
                        quantity,
                        currencyType,
                        "stripe",
                        "",
                        handleStripe
                    )
                );
            }
        }
    };
    function QuantityArea({
        id,
        price,
        isINR,
        handleStripePayment,
        existingPlans,
        isOrderCard,
        name,
        handleOrder,
    }) {
        const [quantity, setQuantity] = useState(1);
        const [totalCost, setTotalCost] = useState({
            total: price,
            discount: price,
        });
        const handleCost = (type) => {
            if (type === "Increment") {
                if (quantity < 50000) {
                    setQuantity(quantity + 1);
                }
            } else {
                if (quantity > 1) {
                    setQuantity(quantity - 1);
                }
            }
        };
        useEffect(() => {
            let discount = 0;
            if (quantity >= 6 && quantity <= 50) {
                discount = 0.1;
            } else if (quantity >= 51 && quantity <= 500) {
                discount = 0.15;
            } else if (quantity >= 501 && quantity <= 5000) {
                discount = 0.18;
            } else if (quantity >= 5001) {
                discount = 0.2;
            }

            let total = Math.round((quantity * price + Number.EPSILON) * 100) / 100;
            setTotalCost({
                total: total,
                discount:
                    Math.round((total * (1 - discount) + Number.EPSILON) * 100) / 100,
            });
        }, [quantity]);

        return (
            <>
                {isOrderCard ? (
                    <>
                        {existingPlans > 0 ? (
                            <div className="text-center mt-4">
                                <div className="text-center credit-area">
                                    <p>{existingPlans > 0 ? ('Credits Available : ' + existingPlans) : null}</p>
                                </div>
                                <Button
                                    className="btn btn-lg btn-primary"
                                    onClick={() => handleOrder(id)}
                                >
                                    Proceed with {name}
                                </Button>
                            </div>
                        ) : (
                            <>
                                <div className="qty-wrapper">
                                    <div className="qty-input">
                                        <button
                                            className="qty-count qty-count--minus"
                                            data-action="minus"
                                            type="button"
                                            onClick={() => handleCost("Decrement")}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="number"
                                            min={1}
                                            className="quantity-box"
                                            value={quantity}
                                            onChange={(event) =>
                                                setQuantity(parseInt(event.target.value))
                                            }
                                        />
                                        <button
                                            className="qty-count qty-count--add"
                                            data-action="add"
                                            onClick={() => handleCost("Increment")}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <div className="total-price">
                                        {isINR ? "₹" : "$"}
                                        {totalCost.discount}
                                        <span>
                                            {isINR ? "₹" : "$"}
                                            {totalCost.total}
                                        </span>
                                    </div>
                                </div>
                                <div className="text-center mt-4">
                                    <Button
                                        className="btn btn-lg btn-primary"
                                        onClick={() => handleStripePayment(id, price, quantity)}
                                    >
                                        Buy Now
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className="qty-wrapper">
                            <div className="text-center credit-area mt-4">
                                <p>{existingPlans > 0 ? ('Credits Available : ' + existingPlans) : null}</p>
                            </div>
                            <div className="qty-input">
                                <button
                                    className="qty-count qty-count--minus"
                                    data-action="minus"
                                    type="button"
                                    onClick={() => handleCost("Decrement")}
                                >
                                    -
                                </button>
                                <input
                                    type="number"
                                    min={1}
                                    className="quantity-box"
                                    value={quantity}
                                    onChange={(event) =>
                                        setQuantity(parseInt(event.target.value))
                                    }
                                />
                                <button
                                    className="qty-count qty-count--add"
                                    data-action="add"
                                    onClick={() => handleCost("Increment")}
                                >
                                    +
                                </button>
                            </div>
                            <div className="total-price">
                                {isINR ? "₹" : "$"}
                                {totalCost.discount}
                                <span>
                                    {isINR ? "₹" : "$"}
                                    {totalCost.total}
                                </span>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <Button
                                className="btn btn-lg btn-primary"
                                onClick={() => handleStripePayment(id, price, quantity)}
                            >
                                Buy Now
                            </Button>
                        </div>
                    </>
                )}
            </>
        );
    }
    function handleCurrencyChange(e) {
        dispatch(setCountry(e.target.value))
    }
    // console.log('Pricingplan',Pricingplan)
    return (
        <>
            {isLoading ? (
                // Display loading state, such as a spinner or a "Loading..." message
                <section className="pricing-area">
                    <p className="text-black text-center">Loading...</p>
                </section>
            ) : (
                <>
                    {PlanHistory?.length > 0 ? (
                        <Row className="mt-3">
                            {Pricingplan.map((elem, index) => {
                                return (
                                    <Col lg={4} md={6} key={"plan" + index + "type"}>
                                        <div
                                            className={`pricing-card ${index === 1 && "bg-light-orange"
                                                }`}
                                        >
                                            {elem?.number_of_sugnatures === "7" && <img src={Price111} alt={""} />}
                                            {elem?.number_of_sugnatures === "25" && <img src={Price222} alt={""} />}
                                            {elem?.number_of_sugnatures === "0" && <img src={Price333} alt={""} />}

                                            <h4>{elem.name}</h4>
                                            <ul className="offer-list">
                                                <li>
                                                    <FaIcons.FaCheck />{" "}
                                                    <p>
                                                        {elem?.number_of_sugnatures !== "0" ? 'Up to ' : ''}
                                                        {elem?.number_of_sugnatures !== "0" ? elem?.number_of_sugnatures : <span>Unlimited</span>}
                                                        {` `}messages/Signatures
                                                    </p>
                                                </li>
                                                <li>
                                                    <FaIcons.FaCheck /> <p>{elem.name === "Free Plan" ? "Basic card. Designs & backgrounds" : "Premium card. Designs & backgrounds"}</p>
                                                </li>
                                                <li>
                                                    <FaIcons.FaCheck /> <p>Add photos and GIFs</p>
                                                </li>
                                                <li>
                                                    <FaIcons.FaCheck /> <p>Manage the Scheduling</p>
                                                </li>
                                                <li>
                                                    <FaIcons.FaCheck /> <p>Wishlist View</p>
                                                </li>
                                                <li>
                                                    {elem.name !== "Ultimate Plan" && elem.name !== "Essential Plan" ? (
                                                        <FaIcons.FaTimes className="xmark" />
                                                    ) : (
                                                        <FaIcons.FaCheck />
                                                    )}{" "}
                                                    <p>Download in PDF and Image format</p>
                                                </li>
                                            </ul>
                                            <div className="text-center mt-4">
                                                <Link to="/category" className="btn btn-lg btn-primary">
                                                    Order Card
                                                </Link>
                                            </div>
                                            <div className="pricing-line-shape">
                                                <SvgIcon name={"PricingLineShape"} />
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    ) : (
                        <section className="pricing-area">
                            <Container>
                                {!isOrderCard && (
                                    <Row className="justify-content-center">
                                        <Col lg={10}>
                                            <div className="section-title-area text-center">
                                                <h2 className="mb-4">
                                                    {!isCustomPlan ? (
                                                        <>
                                                            Choose the option that suits you best. Begin with a complimentary card for personal use to explore and enjoy our services.
                                                            {/* <span className="heading-design position-relative">
                                                                Pricing
                                                            </span>{" "}
                                                            Plan{" "} */}
                                                        </>
                                                    ) : (
                                                        <>
                                                            Choose the option that suits you best
                                                            Feel free to contact us using the below form for For bespoke enterprise plans.
                                                            {/* 
                                                            Do you need cards in{" "}
                                                            <span className="heading-design position-relative">
                                                                bulk
                                                            </span>
                                                            ? Feel free to contact us using the below form. */}
                                                        </>
                                                    )}
                                                </h2>
                                                {/* <p>
													{!isCustomPlan ? (
														<>
															"Choose the option that suits you best Feel free to contact us using the below form for For bespoke enterprise plans."
														</>
													) : (
														<>
															Choose the option that's right for you.Feel free
															to contact us using the below form for customized
															plan for your enterprice.
														</>
													)}
												</p> */}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {!isCustomPlan && (
                                    <Row className="justify-content-center mb-5">
                                        <Form.Select className="currency-change" aria-label="INR" defaultValue={currencyType} onChange={(e) => handleCurrencyChange(e)}>
                                            <option value="INR">INR</option>
                                            <option value="USD">USD</option>
                                        </Form.Select>
                                    </Row>
                                )}

                                <Row className={!isOrderCard ? "mt-3" : ""}>
                                    {!isOrderCard && (
                                        <Col lg={12}>
                                            <div className="pricing-toggle-btn mb-5 text-center">
                                                <p className="mb-0">Single Cards</p>
                                                <label className="switch">
                                                    <input type="checkbox" />
                                                    <span
                                                        className="slider round"
                                                        onClick={() => handleTogglePlan()}
                                                    ></span>
                                                </label>
                                                <p className="mb-0">Enterprise</p>
                                            </div>
                                        </Col>
                                    )}

                                    {!isCustomPlan ? (
                                        <>
                                            {Pricingplan.map((elem, index) => {
                                                return (
                                                    <Col lg={4} md={6} key={"plan" + index + "type"}>
                                                        <div
                                                            className={`pricing-card ${index === 1 && "bg-light-orange"
                                                                }`}
                                                        >
                                                            <div className="pricing-stamp">
                                                                <SvgIcon name={"PricingShape"} />
                                                                <div className="pricing-price-detail">
                                                                    <span className="price-list">
                                                                        <span className="per-post">
                                                                            {currencyType === "INR"
                                                                                ? `₹ ${elem.inr_price}`
                                                                                : `$ ${elem.price}`}
                                                                        </span>
                                                                    </span>
                                                                    <span className="per-post">/Card</span>
                                                                </div>
                                                            </div>
                                                            {elem?.number_of_sugnatures === "7" && <img src={Price111} alt={""} />}
                                                            {elem?.number_of_sugnatures === "25" && <img src={Price222} alt={""} />}
                                                            {elem?.number_of_sugnatures === "0" && <img src={Price333} alt={""} />}

                                                            <h4>{elem.name}</h4>
                                                            <ul className="offer-list">
                                                                <li>
                                                                    <FaIcons.FaCheck />{" "}
                                                                    <p>
                                                                        {elem?.number_of_sugnatures !== "0" ? 'Up to ' : ''}
                                                                        {elem?.number_of_sugnatures !== "0" ? elem?.number_of_sugnatures : <span>Unlimited</span>}
                                                                        {` `}messages/Signatures
                                                                    </p>
                                                                </li>
                                                                <li>
                                                                    <FaIcons.FaCheck /> <p>{elem.name === "Free Plan" ? "Basic card. Designs & backgrounds" : "Premium card. Designs & backgrounds"}</p>
                                                                </li>
                                                                <li>
                                                                    <FaIcons.FaCheck /> <p>Add photos and GIFs</p>
                                                                </li>
                                                                <li>
                                                                    <FaIcons.FaCheck /> <p>Manage the Scheduling</p>
                                                                </li>
                                                                <li>
                                                                    <FaIcons.FaCheck /> <p>Wishlist View</p>
                                                                </li>
                                                                {/* <li>
																	{elem.name !== "Ultimate Plan" ? (
																		<FaIcons.FaTimes className="xmark" />
																	) : (
																		<FaIcons.FaCheck />
																	)}{" "}
																	<p>Unlimited pages</p>
																</li> */}
                                                                <li>
                                                                    {elem.name !== "Ultimate Plan" && elem.name !== "Essential Plan" ? (
                                                                        <FaIcons.FaTimes className="xmark" />
                                                                    ) : (
                                                                        <FaIcons.FaCheck />
                                                                    )}{" "}
                                                                    <p>Download in PDF and Image format</p>
                                                                </li>

                                                            </ul>
                                                            <>
                                                                {
                                                                    // !location.isLoading && (
                                                                    <>
                                                                        {elem.plan_id !== "1" ? (
                                                                            <QuantityArea
                                                                                id={elem.plan_id}
                                                                                name={elem.name}
                                                                                price={
                                                                                    currencyType === "INR"
                                                                                        ? parseFloat(elem.inr_price)
                                                                                        : parseFloat(elem.price)
                                                                                }
                                                                                isINR={currencyType === "INR"}
                                                                                handleStripePayment={
                                                                                    handleStripePayment
                                                                                }
                                                                                existingPlans={elem.number_of_plan}
                                                                                isOrderCard={isOrderCard}
                                                                                handleOrder={handleOrderCard}
                                                                            />
                                                                        ) : (
                                                                            <div className="text-center mt-4">
                                                                                {isOrderCard && !isPremium ? (
                                                                                    <Button
                                                                                        className="btn btn-lg btn-primary"
                                                                                        onClick={() =>
                                                                                            handleOrderCard(elem.plan_id)
                                                                                        }
                                                                                    >
                                                                                        Proceed with {elem.name}
                                                                                    </Button>
                                                                                ) : null}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                    // )
                                                                }
                                                            </>

                                                            <div className="pricing-line-shape">
                                                                <SvgIcon name={"PricingLineShape"} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <Col lg={12}>
                                            <Row className="justify-content-center">
                                                <Col lg={8}>
                                                    <form
                                                        id="business-form"
                                                        className="d-flex align-items-center flex-wrap account-form"
                                                        onSubmit={handleSubmit(onSubmit)}
                                                    >
                                                        <Row className="g-3 g-md-4">
                                                            <Col md={6}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter Your Name"
                                                                        {...register("name", { required: true })}
                                                                    />
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.name?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>Company Name</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Company Name"
                                                                        {...register("company_name", {
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.company_name?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>Email Address</Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        placeholder="Enter Email"
                                                                        {...register("email", { required: true })}
                                                                    />
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.email?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>Phone Number</Form.Label>
                                                                    <Form.Control
                                                                        type="tel"
                                                                        maxLength={10}
                                                                        minLength={10}
                                                                        placeholder="Enter Mobile"
                                                                        {...register("mobile_number", {
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.mobile_number?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>Number of Employees</Form.Label>
                                                                    <Form.Control
                                                                        type="num"
                                                                        placeholder="Enter Total Employee"
                                                                        {...register("num_of_employees", {
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.num_of_employees?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>
                                                                        Number of Cards Required
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="num"
                                                                        placeholder="Enter Total Cards"
                                                                        {...register("num_of_cards", {
                                                                            required: true,
                                                                        })}
                                                                    />
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.num_of_cards?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Form.Group className="modal-form">
                                                                    <Form.Label>Message</Form.Label>
                                                                    <textarea
                                                                        className="form-control w-100"
                                                                        rows={"5"}
                                                                        cols="50"
                                                                        {...register("message", { required: true })}
                                                                    ></textarea>
                                                                    <p className="form-error-message mt-1">
                                                                        {errors.message?.message}{" "}
                                                                    </p>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className="d-flex justify-content-center">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-lg btn-primary"
                                                                    >
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </form>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                        </section>
                    )}
                    {stripeSecret !== null ? (
                        <>
                            <Modal show={true} centered>
                                <Modal.Body className="section-title-area sign-in-modal">
                                    <Elements
                                        stripe={loadStripe(stripeKey)}
                                        options={{ clientSecret: stripeSecret.clientSecret }}
                                    >
                                        <CheckoutForm
                                            orderid={stripeSecret.order_id}
                                            isOrderCard={isOrderCard}
                                            clearStripe={handleClearStripe}
                                        />
                                    </Elements>
                                </Modal.Body>
                            </Modal>
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default PricingCard;
