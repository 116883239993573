import React, { useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUs } from "../../actions";
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import aboutimg from '../../assets/img/about.jpg'
const AboutUs = () => {
    const dispatch = useDispatch();
    const AboutInfo = useSelector((state) => state.aboutData.data);
    useEffect(() => {
        dispatch(getAboutUs());
    }, []);

    // console.log('About Us', AboutInfo);
    const currentURL = window.location.href;
    return (
        <>
            <Helmet>
                <title>{AboutInfo.title}</title>
                <meta name="title" content={AboutInfo.meta_title} />
                <meta name="description" content={AboutInfo.meta_description} />
                <meta name="keywords" content={AboutInfo.meta_keyword} />
                <meta property="og:title" content={AboutInfo.meta_title} />
                <meta property="og:description" content={AboutInfo.meta_description} />
                <meta property="og:image" content={'https://i.ibb.co/QP6Nmpf/image-1-about.jpg'} />
                <meta property="og:url" content={currentURL} />
            </Helmet>
            <section className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <h2>{AboutInfo.title}</h2>
                                </div>
                                {AboutInfo.description && parse(AboutInfo.description)}
                                {/* <div className="btn-box mt-4">
                                    <a href="#" className="btn btn-primary">Contact Us</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column wow fadeInLeft">
                                <figure className="image-1"><a href="#" className="lightbox-image"><img className="w-100" title="Rahul Kumar Yadav" src={aboutimg} alt="" /></a></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;