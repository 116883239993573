import { AddressElement, PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";

export default function CheckoutForm({orderid,isOrderCard,isUpdatePlan,clearStripe}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    elements.getElement("address")
    elements.getElement("address").getValue().then((value) =>{
      const billingDetails = {
      address:value.value.address,
      name: value.value.name,
      email: "none",
    };
    setIsProcessing(true);
    handlePayment(billingDetails)
    })

    

  };
  const  handlePayment = async (billing_details) =>{
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method:JSON.stringify({
          card: elements.getElement("card"),
          billing_details: billing_details,
        }),
        // Make sure to change this to your payment completion page
        // return_url: `${window.location.origin}${isOrderCard ? "/ordercomplete/":isUpdatePlan ? "/updatecompletion/":"/completion/"}${orderid}`,
        return_url: `${window.location.origin}/completion/${isOrderCard ? "card" :isUpdatePlan ? "update" : "plan"}/${orderid}`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

     setIsProcessing(false);
  }
  const handleClose = () => {
    if (stripe && elements) {
      Swal.fire({
        title: 'Do you really want to exit order will not be placed?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then((result) => {
        
        if (result.isConfirmed) {
          clearStripe()
           // Reset the PaymentElement to its initial state
      elements.getElement("payment-element").clear();

      // Reset the message
      setMessage(null);

      // Set isProcessing to false to stop ongoing payment processing
      setIsProcessing(false);
        } 
      })
  
    }
  };
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
       <AddressElement id="address-element" options={{ mode: 'billing' }} />
      <PaymentElement id="payment-element" />
      <button className="btn btn-lg btn-primary w-100 mt-3" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      <button className="btn btn-lg btn-warning w-100 mt-3" type="button" onClick={()=>handleClose()}>
        <span id="button-text">
         Close
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
