import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isCategoryLoading: false,
    err: null,
    data:[],
};

const categoryReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getCategoryListStarted:
            return {
                ...state,
                isCategoryLoading:true
            }
        case API_ACTIONS.getCategoryListSuccess:
            return {
                ...state,
                isCategoryLoading:false,
                data:action.data.categories
            }
        case API_ACTIONS.getCategoryListFailed:
            return {
                ...state,
                isCategoryLoading:false,
                err:action.data
            }
            default:
                return state;
    }

};
export default categoryReducer;