import { Button, Col, Row, Container, NavLink } from 'react-bootstrap'
import React, { useEffect } from 'react'
import CardText from '../CreateCard/CardText'
import { useState } from 'react'
import Giphy from '../CreateCard/Giphy'
import GiphySticker from '../CreateCard/GiphySticker'
import GiphyAttr from '../../assets/img/powered_by_giphy.gif';
import { handleSignCard, handleUpdateCard } from '../../actions'
import { useDispatch } from 'react-redux'
import * as FaIcons from "react-icons/fa";
import logo from "../../assets/img/logo.svg";
function SignCardUpdate({ show, onHide, code,from,id,data,card_sing_id,isSender,handleSendCard }) {
    const [media, setMedia] = useState("Message");
    // console.log(data)
    const [mediaData, setMediaData] = useState({
        message: "",
        fontsize: "",
        fontfamily: "",
        color: "",
        media: "",
        signerName:"",
        signerEmail: "",
    });
    const dispatch = useDispatch();
    const handleMedia = (type) => {
        if (type !== media) {
            if(mediaData.media !== "none"){
                setMediaData({...mediaData,media:"none"})
            }
            setMedia(type)
        }

    }
    const handleSelect = (type, value) => {
        if (type !== undefined) {
            setMediaData({ ...mediaData, [type]: value })
        }
    }

 
    useEffect(()=>{
        setMediaData(data);
    },[data])
    return (
                <Container className='mt-4'>
                    <Row>
                        <Col lg={8}>
                            <ul className='gif-tab-buttons'>
                                <li>
                                    <button type="button" className={`${media === 'Message' && 'active'}`} onClick={() => handleMedia("Message")}>
                                        Your Message
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={`${media === 'Gif' && 'active'}`} onClick={() => handleMedia("Gif")}>
                                        Add Gif
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={`${media === 'Sticker' && 'active'}`} onClick={() => handleMedia("Sticker")}>
                                        Add Sticker
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={`${media === 'Sticker' && 'active'}`} onClick={() => handleMedia("Image")}>
                                        Add Image
                                    </button>
                                </li>
                            </ul>
                            {/* <NavLink href="https://giphy.com/" target="_blank" className='giphy-attr'><img src={GiphyAttr} alt='Powered By Giphy' /></NavLink> */}
                            {
                                media === "Gif" ? (<Giphy handleSelect={handleSelect} data={mediaData} />) : media === "Sticker" ? (<GiphySticker handleSelect={handleSelect} data={mediaData} />): media === "Message" ? (<CardText isSender={isSender} handleSelect={handleSelect} data={mediaData} />) : (<>
                                <input type="file" onChange={(e)=>handleSelect("media",e.target.files[0])} />
                                </>)
                            }
                        </Col>
                        <Col lg={4}>
                            <h4 className='text-center'>Preview</h4>
                            <div className='image-preview-box'>
                                {mediaData.media !== "none" ? (
                                    <div className='previiew-image-edit'>

                                        <img alt="img" src={media === "Image" ? URL.createObjectURL(mediaData.media) :mediaData.media} />
                                        <button type="button" className="p-image-delete" onClick={() => handleSelect("media", "none")}>
                                        <FaIcons.FaTrashAlt />
                                        </button>
                                    </div>
                                ):  <img  alt="def-img" src={logo} />}
                                <p className='mb-0' style={{ fontFamily: mediaData.fontfamily, fontSize: mediaData.fontsize, color: mediaData.color }}>
                                    {mediaData.message}
                                </p>
                                <p className='text-end mb-0' style={{ fontFamily: mediaData.fontfamily, fontSize: mediaData.fontsize, color: mediaData.color }}>
                                ~{mediaData?.signerName === ""? "Enter your name" : mediaData.signerName}
                                </p>
                            </div>
                        </Col>
                        <Col className='col-12'>
                            <div className='d-flex justify-content-end'>
                                <Button type="button" className="mt-4 btn-danger active" onClick={() => handleSendCard(mediaData,code,card_sing_id,"update",onHide)}>
                                    Save
                                </Button>
                                <Button type="button" className="mt-4 mx-4 btn-danger" onClick={() => onHide()}>
                                    Back
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
    )
}

export default SignCardUpdate