import { API_ACTIONS } from "../constants/actionConstant";

const initialState = {
    isLoading: false,
    err: null,
    iserror:false,
    data:[],
};

const pdfReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getPdfStarted:
            return {
                ...state,
                isLoading:true,
                err:null,
                iserror:false,
            }
        case API_ACTIONS.getPdfSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data,
                err:null,
                iserror:false,
            }
        case API_ACTIONS.getPdfFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data,
                iserror:true,
            }
            case API_ACTIONS.clearPDF:
                return {
                    ...state,
                    isLoading:false,
                    err:null,
                    iserror:false,
                }
            default:
                return state;
    }
};

export default pdfReducer;