import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Img from '../assets/img/thankyou-card.png'
import * as FaIcons from "react-icons/fa";
import EmptyState from "./EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { FetchBgImageList, SubmitBackground } from "../actions";

const ViewBoard = ({ signdata, defaultbg, handleAction, cardtitle, from, code, isCreator = false }) => {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ id: 0, image: defaultbg });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const ImagesList = useSelector((state) => state.bgImage.data);
    // console.log('selected image', selectedImage);
    useEffect(() => {
        dispatch(FetchBgImageList());
    }, []);

    const handleImageClick = (id, image) => {
        setSelectedImage({
            id: id,
            image: image
        });
    };
    const handleImageSubmit = (id, code) => {
        dispatch(SubmitBackground(id, code, handleClose));
    };


    // console.log('bg image list', code, ImagesList);
    return (
        <>
            <section className="create_card_area viewboard-area">
                <Container fluid>
                    <div className="card_bg">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="group_card">
                                        <Button type="button" className="group_btn me-3" onClick={() => handleAction("Card")}><FaIcons.FaArrowLeft className="me-2" /> Back To Group Card</Button>
                                        {
                                            isCreator && (
                                                <Button type="button" className="group_btn" onClick={handleShow}><FaIcons.FaFileImage className="me-2" /> Change Background</Button>
                                            )
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Container>
                <Container fluid>
                    <div className="heading_card_bg">
                        <div className="d-flex justify-content-center">
                            <div className="birthday_name">
                                <h3>{cardtitle}</h3>
                                <p className="text-center m-0">From - {from}</p>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container fluid>
                    <div className="card_bg_img position-relative">
                        <img src={defaultbg} className="card-bg-image-img" alt="background image" />
                        <Container>
                            <Row>
                                {
                                    signdata && signdata.length === 0 ?
                                        (<EmptyState />) : (
                                            <>
                                                {
                                                    signdata.map((signelem) => {
                                                        return (
                                                            <Col lg={3} md={4} sm={6} className="col-6">
                                                                <div className="card_design position-relative">
                                                                    <div className="card_img">
                                                                        {
                                                                            signelem.message?.media !== "none" && (
                                                                                <img src={signelem.message?.media} className="img-fluid" />
                                                                            )
                                                                        }
                                                                        
                                                                    </div>
                                                                    <div className="card_cnt p-2">
                                                                        <h4 className="text-start mt-3" style={{ fontFamily: signelem.message?.fontfamily, fontSize: signelem.message?.fontsize, color: signelem.message?.fontcolor }}>{signelem.message?.message}</h4>
                                                                        <h6 className="text-end mt-2" style={{ fontFamily: signelem.message?.fontfamily, fontSize: signelem.message?.fontsize, color: signelem.message?.fontcolor }}>~ {signelem.message?.signerName}</h6>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }</>
                                        )
                                }
                            </Row>
                        </Container>
                    </div>
                </Container>

            </section>
            <Modal show={show} onHide={handleClose} size="lg" className='pricingmodal' backdrop="static">
                <Modal.Body className="section-title-area sign-in-modal">
                    <Modal.Header closeButton>
                        <h5 className="mb-0">Choose Background</h5>
                    </Modal.Header>
                    <div className="row mt-4">
                        {ImagesList?.length > 0 && (
                            <>
                                {ImagesList.map((elem, index) => {
                                    return (
                                        <div className="col-md-3" key={index}>
                                            <div className={`thumb-box ${selectedImage.id === elem.background_id ? 'active' : ''}`} onClick={() => handleImageClick(elem.background_id, elem.original_image)}>
                                                <img src={elem.thumb} alt="" />
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                        <div className="col-12">
                            <div className="d-flex justify-content-end mt-3">
                                <button class="btn btn-lg btn-primary" onClick={() => handleImageSubmit(selectedImage.id, code)}>Save</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* CSS to remove the backdrop */}
            <style>
                {`.modal-backdrop {
          display: none !important;
        }`}
            </style>
        </>
    )

}

export default ViewBoard;