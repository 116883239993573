import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import quoteImg from '../assets/img/quote-up.png';
import clientImg from '../assets/img/clientimg.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Testimonial = () => {
    SwiperCore.use([Autoplay]);
    const reviewList = [
        {
            id: 1,
            Review: "Love using this online greeting card platform! Saves time and offers a variety of designs. Great for staying connected with friends and family. ",
            Name: "Ananya Sharma",
            Designation: 'Student',
        },
        {
            id: 2,
            Review: "Convenient and affordable online platform for professional occasions. Helps remote teams build stronger bonds and manage team member names.",
            Name: "Senthil Kumar",
            Designation: 'Team manager',
        },
        {
            id: 3,
            Review: "Game-changer for staying organized and thoughtful. Reminder emails and scheduling options make life easier.",
            Name: "Ishita Bandyopadhyay",
            Designation: null,
        },
        {
            id: 4,
            Review: "Impressive selection of designs and unbeatable price. Great for staying connected with loved ones, especially during the pandemic.",
            Name: "Rhea",
            Designation: 'Media professional',
        },
        {
            id: 5,
            Review: "Boosts team morale and collaborative culture in remote work environment. Affordable and easy to use with fun elements like gifs and images.",
            Name: "Shiva Kumar",
            Designation: null,
        },
        {
            id: 6,
            Review: "Online greeting card platform is a lifesaver for busy schedules. Quickly send beautiful cards to family and friends from anywhere. ",
            Name: "William Jones",
            Designation: 'Business professional',
        },
        {
            id: 7,
            Review: "With this online greeting card platform, I can effortlessly discover stunning designs for any event. Sending cards from my computer or phone is incredibly convenient, and the price is unbeatable. I highly recommend it!",
            Name: "Emily",
            Designation: null,
        },
        {
            id: 8,
            Review: "Beautiful designs for all occasion with this affordable and convenient online platform. Highly recommend! ",
            Name: "Sara James",
            Designation: null,
        },
        {
            id: 9,
            Review: "Great way to show appreciation for colleagues and improve company culture in remote work settings. ",
            Name: "Giridhar Bhat",
            Designation: null,
        },
        {
            id: 10,
            Review: "Save time and hassle with this online platform. Send personalized cards in just a few clicks and make loved ones happy! ",
            Name: "Manjunath Reddy",
            Designation: null,
        },
        
    ];
    return (
        <>
            <section className="testimonial-area">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <div className="section-title-area text-center mb-0 mb-md-4 d-flex align-items-center justify-content-center">
                                <h2>Our Solution Speaks for itself, and our clients speak for us</h2>
                            </div>
                        </Col>
                    </Row>
                    <Swiper
                        slidesPerView={4.4}
                        loop={true}
                        autoplay={{
                            delay: 2000, // Specify the delay in milliseconds (3 seconds in this example)
                            disableOnInteraction: false, 
                            pauseOnMouseEnter: true,
                            // Set to true if you want to stop autoplay on user interaction
                        }}
                        spaceBetween={20}
                        centeredSlides={true}
                        className={'testimonial-slider'}
                        breakpoints={{
                            0: {
                                slidesPerView: 1.1,
                                spaceBetween: 20,
                            },
                            320: {
                                slidesPerView: 1.1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 2.1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3.9,
                            },
                        }}
                    >
                        {reviewList.map((elem, index) => {
                            return (
                                <SwiperSlide key={'egrou-testimonial' + index}>
                                    <Card className={'testimonial-card card border-0 mt-2 mb-2'}>
                                        {/* <Card.Img variant="top" src={quoteImg} className="m-3 mb-0" /> */}
                                        <Card.Body className="p-3 p-lg-4">
                                            <Card.Text>{elem.Review}</Card.Text>
                                            <div className="d-flex align-items-center">
                                                {/* <div className="client-img me-2">
                                                        <img src={clientImg} alt="" />
                                                    </div> */}
                                                <div className="client-name d-flex flex-column">
                                                    <h5 className="mb-0">~ {elem.Name}</h5>
                                                    {elem.Designation !== null && <p className="mb-0">{elem.Designation}</p>}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Container>
            </section>
        </>
    );
}

export default Testimonial;