import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React from 'react'
import Homepage from "../pages/Home/Homepage";
import ResetPassword from "../componants/ResetPassword";
import Category from "../pages/Category/Category";
import CategoryListing from "../pages/CategoryListing/CategoryListing";
import Blog from "../pages/Blog/Blog";
import Pricing from "../pages/Pricing/Pricing";
import Contact from "../pages/Contact/Contact";
import CreateCard from "../pages/CreateCard/Createcard";
import NotFound from "../pages/NotFound/Index";
import Profile from "../pages/Profile";
import WishList from "../pages/WishList";
import Activate from "../componants/Activate";
import BlogDetail from "../pages/BlogDetail"
import SignCard from "../pages/SignCard";
import Invites from "../componants/Invites";
import Header from "../componants/Header";
import Footer from "../componants/Footer";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsService from "../pages/TermsService";
import SuccessHandler from "../componants/SuccessHandler";
import OrderSuccessHandler from "../componants/OrderSuccessHandler";
import PaymentSuccess from "../pages/PaymentMessage/PaymentSuccess";
import PaymentFailed from "../pages/PaymentMessage/PaymentFailed";
import Faq from "../pages/Faq/Faq";
import AboutUs from "../pages/AboutUs/AboutUs";
import Business from "../pages/Business/Business";
import UpdateSuccessHandler from "../componants/UpdateSuccessHandler";
import ScrollToTop from "../componants/ScrollToTop";
import CreatorSign from "../pages/SignCard/CreatorSign";
import Billdesk from "../pages/billdesk";
import BilldeskSuccess from "../componants/BilldeskSuccess";
import EditFullCard from "../pages/EditFullCard";

function MyRoutes() {
    const isLoggedin = localStorage.getItem("isLogin");
    const Layout = ({ children }) => {
        return (

            <>
                <Header />
                {children}
                <Footer />
            </>

        );
    };
    // const PrivateRoute = ({ children }) => {
    //     if (isLoggedin) {
    //         return (
    //             <>
    //                 {children}</>
    //         )
    //     } else {
    //         return (
    //             <Route path="/home" exact element={<Homepage />} />
    //         )

    //     }
    // }
    return (

        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Layout><Homepage /></Layout>} />
                <Route path="/home" exact element={<Layout><Homepage /></Layout>} />
                <Route path="/category" exact element={<Layout><Category /></Layout>} />
                <Route key="load" path="/category/listing" exact element={<Layout><CategoryListing /></Layout>} />
                <Route key="clicked" path="/category/listing/:id" exact element={<Layout><CategoryListing /></Layout>} />
                <Route key="clicked" path="/category/listing/:id/:subid" exact element={<Layout><CategoryListing /></Layout>} />
                <Route path="/blog" exact element={<Layout><Blog /></Layout>} />
                <Route path="/blog-details/:blogid" exact element={<Layout><BlogDetail /></Layout>} />
                <Route path="/edit/:id" exact element={<Layout><BlogDetail /></Layout>} />
                <Route path="/pricing" exact element={<Layout><Pricing /></Layout>} />
                <Route path="/contact" exact element={<Layout><Contact /></Layout>} />
                <Route path="/create/:encryptid" exact element={<Layout><CreateCard /></Layout>} />
                <Route path="/resetpassword/auth/:authcode" exact element={<ResetPassword />} />
                <Route path="/register/auth/:authcode" exact element={<Activate />} />
                <Route path="/profile" exact element={isLoggedin ? <Profile /> : <Navigate to={{ pathname: "/home" }} />} />
                <Route path="/wishlist" exact element={<Layout><WishList /></Layout>} />
                <Route path="/invite" exact element={<Layout><Invites /></Layout>} />
                <Route path="/signcard/:code" exact element={<Layout><SignCard isSender={false}/></Layout>} />
                <Route path="/create/signcard/:code" exact element={<Layout><CreatorSign /></Layout>} />
                <Route path="/privacy-policy" exact element={<Layout><PrivacyPolicy /></Layout>} />
                <Route path="/terms-of-service" exact element={<Layout><TermsService /></Layout>} />
                <Route path="/completion/:type/:orderid" exact element={<SuccessHandler />} />
                {/* <Route path="/ordercomplete/:orderid" exact element={<OrderSuccessHandler />} />
                <Route path="/updatecompletion/:orderid" exact element={<UpdateSuccessHandler />} /> */}
                <Route path="/billdeskcompletion/:type/:id" exact element={<BilldeskSuccess />} />
                <Route path="/faq" exact element={<Layout><Faq /></Layout>} />
                <Route path="/success" exact element={<PaymentSuccess />} />
                <Route path="/failed" exact element={<PaymentFailed />} />
                <Route path="/about" exact element={<Layout><AboutUs /></Layout>} />
                <Route path="/business" exact element={<Layout><Business /></Layout>} />
                <Route path="/editfullcard" exact element={<Layout><EditFullCard /></Layout>} />
                <Route path="/billdesk" exact element={<Billdesk />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Router>

    )
}


export default MyRoutes;