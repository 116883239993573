import { API_ACTIONS } from "../constants/actionConstant";



const initialState = {
    isLoading: false,
    err: null,
    data:[],
};

const bgImageReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getBgListListStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getBgListListSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getBgListListFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
            default:
                return state;
    }

};
export default bgImageReducer;