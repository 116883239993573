import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import leftBannerShape from "../assets/img/image2.svg";
import rightBannerShape from "../assets/img/image4.svg";
import sectionImg from '../assets/img/home-wishsec-image.png'

// import BannerSecImage from "../assets/img/banner-img.png";
// import BannerBlackbg from "../assets/img/banner-bg-2.png";
// import bannerLabel from "../assets/img/banner-label.svg";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

const Banner = () => {
    useEffect(() => {
        // Preload the Largest Contentful Paint (LCP) images
        const imagesToPreload = [sectionImg];
        imagesToPreload.forEach((image) => {
            const img = new Image();
            img.src = image;
        });
    }, []);
    return (
        <>
            <section className="banner-area">
                <img src={leftBannerShape} alt="" className="left-banner-shape" />
                <img src={rightBannerShape} alt="" className="right-shade-img" />
                <div className="container">
                    <Row className="align-items-center">
                        <Col sm={6} md={6} lg={5} className="banner-title-design">
                            <div className="slider-title-area">
                                <h1 className="mb-4">Send collaborative Greeting Cards <span className="title-bottom-line">Online</span>  Filled With <span className="title-bottom-line">heartfelt</span>  messages To <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed once, initially
                                        'Friends',
                                        1000,
                                        'Family',
                                        1000,
                                        'Colleagues',
                                        1000,
                                        'Team',
                                        1000,
                                    ]}
                                    speed={20}
                                    style={{ color: '#F75252' }}
                                    repeat={Infinity}
                                /></h1>
                                <Link to="/category" className="btn btn-lg btn-primary mt-4">Start Now</Link>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={7} className="banner-col-design position-relative">
                            <img src={sectionImg} className="img-fluid w-100" />
                            {/* <div className="banner-img position-relative">
                            <img src={BannerSecImage} alt="" className="img-fluid"/>
                            <img src={BannerBlackbg} alt="" className="banner-back-bg img-fluid"/>
                            <img src={bannerLabel} alt="label" className="banner-label img-fluid"/>
                        </div> */}
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
}

export default Banner;