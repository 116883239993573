import React,{ useState,useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from "react-toastify";
import * as FaIcons from "react-icons/fa";
import FormTooltip from "../../componants/FormTooltip";

const StepOne = ({ register, errors, handleStep }) => {
    const [tooltipPlacement, setTooltipPlacement] = useState('right');

    useEffect(() => {
        // Detect screen width and set tooltip placement accordingly
        const handleScreenResize = () => {
            if (window.innerWidth < 768) {
                setTooltipPlacement('top');
            } else {
                setTooltipPlacement('right');
            }
        };

        // Initialize tooltip placement based on initial screen width
        handleScreenResize();

        // Listen for window resize events
        window.addEventListener('resize', handleScreenResize);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('resize', handleScreenResize);
        };
    }, []);
    return (
        <>
            <Form.Group className="mb-3 modal-form">
                <Form.Label>First Name <span className="reqired-star">*</span><FormTooltip tooltipText="Person or Team who is sending the eCard *" placement={tooltipPlacement} className={'me-4 tooltip-plus-btn'}><FaIcons.FaInfo /></FormTooltip></Form.Label>
                <Form.Control type="text" placeholder="Enter Your First Name" {...register("senderfname", { required: true })} />
                <p className='form-error-message mt-1'>{errors.senderfname?.message} </p>
            </Form.Group>
            <Form.Group className="mb-3 modal-form">
                <Form.Label>Last Name <span className="reqired-star">*</span><FormTooltip tooltipText="Person or Team who is sending the eCard *" placement={tooltipPlacement} className={'me-4 tooltip-plus-btn'}><FaIcons.FaInfo /></FormTooltip></Form.Label>
                <Form.Control type="text" placeholder="Enter Your Last Name"  {...register("senderlname", { required: true })} />
                <p className='form-error-message mt-1'>{errors.senderlname?.message} </p>
            </Form.Group>

            <div className="continue-btn text-center mt-4">
                <Button className="btn btn-primary" onClick={() => handleStep("Step1")}>Continue</Button>
            </div>

        </>
    );
}

export default StepOne;