import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { handleBilldesk } from '../actions';

function Billdesk() {
const [Amount,setAmount] = useState(0);
const dispatch  = useDispatch();

const callBilldesk = () =>{
    dispatch(handleBilldesk(Amount));
}
//     var flow_config ={
//         merchantId:"APRECOGRTG",
//         bdOrderId:"64c2a11e3188c",
//         authToken: `eyJjbGllbnRpZCI6ImJkdWF0djJrdGsiLCJhbGciOiJIUzI1NiJ9.eyJtZXJjaWQiOiJCRFVBVFYyS1RLIiwib3JkZXJpZCI6IjY0YzJhMTFlMzE4OGMiLCJhbW91bnQiOiI1MjkuMjMiLCJvcmRlcl9kYXRlIjoiMjAyMy0wNy0yN1QyMjoyMzo1MCswNTozMCIsImN1cnJlbmN5IjoiMzU2IiwicnUiOiJodHRwOlwvXC9sb2NhbGhvc3Q6MzAwMFwvY2FyZGNvbXBsZXRpb25cLyIsIml0ZW1jb2RlIjoiRElSRUNUIiwiZGV2aWNlIjp7ImluaXRfY2hhbm5lbCI6ImludGVybmV0IiwiaXAiOiI0OS4zNC4xMjEuMTk1IiwidXNlcl9hZ2VudCI6IlBvc3RtYW5SdW50aW1lXC83LjMyLjMiLCJhY2NlcHRfaGVhZGVyIjoidGV4dFwvaHRtbCJ9LCJhZGRpdGlvbmFsX2luZm8iOnsiYWRkaXRpb25hbF9pbmZvMSI6MTgzfX0.0YYRReWIS5Hyao-SJTMpz9xWskgW_GJfOv9kDUiTvQc`,
// childWindow: true, //merchant to refer to api documentation for child window behaviour
// returnUrl: "SS", //merchant to refer to api documentation for returnURL behaviour


// }
//     useEffect(()=>{
//         var responseHandler = function (txn) {
            
//              console.log("callback received status:: ", txn.status)
            
//              console.log("callback received response:: ", txn.response)
            
//              } //merchant to implement
            
            
//              var config = {
            
//              responseHandler: responseHandler,
            
//              merchantLogo: "",
            
//              flowConfig: flow_config,
            
//              flowType: "payments"
            
//              }
//  window.loadBillDeskSdk(config);
            
//     },[])


  return (
    <div>
        <input type="number" onChange={(e)=>setAmount(e.target.value)} />
        <button onClick={()=>callBilldesk()}>launch Billdesk</button>
    </div>
  )
}

export default Billdesk