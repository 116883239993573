import { json, useNavigate } from "react-router-dom";
import apiService from "../api/apiService";
import { API_ACTIONS } from "../constants/actionConstant";
import { ACTION_ROUTES, GIPHY_URL } from "../constants/constant";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


export const setCountry = (data) =>{
return {
	type: API_ACTIONS.setCountryCode,
		data,
}
}
//#region category fetch area
export const getCategoryList = () => (dispatch, getState) => {
	dispatch(getCategoryListStarted());
	apiService
		.get(ACTION_ROUTES.categoryList)
		.then((response) => {
			dispatch(getCategoryListSuccess(response));
		})
		.catch((err) => {
			dispatch(getCategoryListFailed(err));
		});
};
export const getCategoryListStarted = (data) => {
	return {
		type: API_ACTIONS.getCategoryListStarted,
		data,
	};
};
export const getCategoryListSuccess = (data) => {
	return {
		type: API_ACTIONS.getCategoryListSuccess,
		data,
	};
};
export const getCategoryListFailed = (data) => {
	return {
		type: API_ACTIONS.getCategoryListFailed,
		data,
	};
};
export const getSubCategoryList = (id) => (dispatch, getState) => {
	// console.log(ACTION_ROUTES.categoryList + `&seo=${id}`);
	dispatch(getSubCategoryListStarted());
	apiService
		.get(ACTION_ROUTES.categoryList + `&seo=${id}`)
		.then((response) => {
			dispatch(getSubCategoryListSuccess(response));
		})
		.catch((err) => {
			dispatch(getSubCategoryListFailed(err));
		});
};
//#endregion category fetch area

//#region  subcategory fetch area
export const getSubCategoryListStarted = (data) => {
	return {
		type: API_ACTIONS.getSubCategoryListStarted,
		data,
	};
};
export const getSubCategoryListSuccess = (data) => {
	return {
		type: API_ACTIONS.getSubCategoryListSuccess,
		data,
	};
};
export const getSubCategoryListFailed = (data) => {
	return {
		type: API_ACTIONS.getSubCategoryListFailed,
		data,
	};
};
//#endregion subcategory fetch area

//#region  login action area
export const loginStarted = (data) => {
	return {
		type: API_ACTIONS.loginStarted,
		data,
	};
};
export const loginSuccess = (data) => {
	return {
		type: API_ACTIONS.loginSuccess,
		data,
	};
};
export const loginFailed = (data) => {
	return {
		type: API_ACTIONS.loginFailed,
		data,
	};
};
export const login = (form, handleSuccess) => (dispatch, getState) => {
	dispatch(loginStarted());
	apiService
		.post(ACTION_ROUTES.authRoute, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				dispatch(loginFailed(response.error));
			} else {
				localStorage.setItem("customer_id", response.customer_id);
				localStorage.setItem("telephone", response.telephone);
				localStorage.setItem("firstname", response.firstname);
				localStorage.setItem("lastname", response.lastname);

				localStorage.setItem("email", response.email);
				localStorage.setItem("isLogin", true);
				toast.success("Login Successfull!");
				dispatch(loginSuccess(response));
				handleSuccess();
			}
		})
		.catch((err) => {
			dispatch(loginFailed(err));
		});
};

export const Logout = () => {
	Swal.fire({
		title: "Are you sure you need to Logout",
		showDenyButton: true,
		showCancelButton: false,
		confirmButtonText: "Yes",
		denyButtonText: `No`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			localStorage.clear();
			window.location.href = process.env.REACT_APP_APP_URL;
		} else if (result.isDenied) {
		}
	});
};
//#endregion login action area

//#region  register action area
export const registerStarted = (data) => {
	return {
		type: API_ACTIONS.registerStarted,
		data,
	};
};
export const registerSuccess = (data) => {
	return {
		type: API_ACTIONS.registerSuccess,
		data,
	};
};
export const registerFailed = (data) => {
	return {
		type: API_ACTIONS.registerFailed,
		data,
	};
};
export const registerAction = (form, handleSuccess) => (dispatch, getState) => {
	dispatch(registerStarted());
	apiService
		.post(ACTION_ROUTES.registerRoute, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.code) {
				toast.success("Email has been sent to your inbox please check");
				dispatch(registerSuccess(response));
				handleSuccess();
			} else {
				if(response.warning === "Warning: E-Mail Address is already registered!"){
					Swal.fire({
						title: response.warning,
						showDenyButton: false,
						showCancelButton: false,
						confirmButtonText: "Login",
						denyButtonText: `Close`,
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							handleSuccess();
						}
					});
				}else{
					Swal.fire({
						title: response.warning,
						showDenyButton: true,
						showCancelButton: false,
						confirmButtonText: "Resend Email",
						denyButtonText: `Close`,
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							// console.log(form.email);
							let formdata = new FormData();
							formdata.append("email", form.email);
							apiService
								.post(
									`${ACTION_ROUTES.registerRoute}/resendcode`,
									formdata,
									{},
									"multipart/form-data"
								)
								.then((response) => {
									if (response.success) {
										toast.success(response.success);
										dispatch(registerSuccess(response));
										handleSuccess();
									} else {
										toast.error(response.error);
									}
								})
								.catch((err) => {
									toast.error(err);
								});
						} else if (result.isDenied) {
							handleSuccess();
						}
					});
				}
				
			}
		})
		.catch((err) => {
			dispatch(registerFailed(err));
		});
};

//#endregion register action area

//#region  googleLogin action area

export const facebookLoginStarted = (data) => {
	return {
		type: API_ACTIONS.facebookLoginStarted,
		data,
	};
};
export const facebookLoginSuccess = (data) => {
	return {
		type: API_ACTIONS.facebookLoginSuccess,
		data,
	};
};
export const facebookLoginFailed = (data) => {
	return {
		type: API_ACTIONS.facebookLoginFailed,
		data,
	};
};
export const facebookLogin = (payload,handleSuccess) => (dispatch, getState) => {
	dispatch(facebookLoginStarted());
	// console.log("Facebook payload Responseeeeeeeee", payload);
	let form = new FormData();
	form.append("google_id", payload.id);
	form.append("id", payload.id);
	form.append("firstname", payload.first_name);
	form.append("lastname", payload.last_name);
	form.append("email", payload.email);
	form.append("telephone", payload.telephone);
	apiService
		.post(
			`${ACTION_ROUTES.registerRoute}/googleRegisterOrLogin`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			if (response.customer_id) {
				toast.success("Login Successfull");
				localStorage.setItem("customer_id", response.customer_id);
				localStorage.setItem("firstname", payload.first_name);
				localStorage.setItem("lastname", payload.last_name);

				localStorage.setItem("email", payload.email);
				localStorage.setItem("isLogin", true);
				dispatch(facebookLoginSuccess(response));
				dispatch(
					loginSuccess({
						id: response.customer_id,
						firstname: payload.first_name,
						lastname: payload.last_name,
						email: payload.email,
						telephone: payload.telephone,
					})
				);
                handleSuccess()
			} else {
				toast.error("Failed  to Complete login please retry");
				dispatch(facebookLoginFailed(response.error));
			}
		})
		.catch((err) => {
			dispatch(facebookLoginFailed(err));
		});
};

export const googleLoginStarted = (data) => {
	return {
		type: API_ACTIONS.googleLoginStarted,
		data,
	};
};
export const googleLoginSuccess = (data) => {
	return {
		type: API_ACTIONS.googleLoginSuccess,
		data,
	};
};
export const googleLoginFailed = (data) => {
	return {
		type: API_ACTIONS.googleLoginFailed,
		data,
	};
};
export const googleLogin = (payload, handleSuccess) => (dispatch, getState) => {
	dispatch(googleLoginStarted());
	let form = new FormData();
	form.append("google_id", payload.id);
	form.append("id", payload.id);
	form.append("firstname", payload.firstname);
	form.append("lastname", payload.lastname);
	form.append("email", payload.email);
	apiService
		.post(
			`${ACTION_ROUTES.authRoute}/googleRegisterOrLogin`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			if (response.customer_id) {
				toast.success("Login Successfull");
				dispatch(googleLoginSuccess(response));
				localStorage.setItem("customer_id", response.customer_id);
				localStorage.setItem("firstname", payload.firstname);
				localStorage.setItem("lastname", payload.lastname);

				localStorage.setItem("email", payload.email);
				localStorage.setItem("isLogin", true);
				dispatch(
					loginSuccess({
						id: response.customer_id,
						firstname: payload.firstname,
						lastname: payload.lastname,
						email: payload.email,
					})
				);
				handleSuccess();
			} else {
				toast.error("Failed  to Complete login please retry");
				dispatch(googleLoginFailed(response.error));
			}
		})
		.catch((err) => {
			dispatch(googleLoginFailed(err));
		});
};

//#endregion  googleLogin action area

//#region reset password action

export const resetPasswordStarted = (data) => {
	return {
		type: API_ACTIONS.resetPasswordStarted,
		data,
	};
};
export const resetPasswordSuccess = (data) => {
	return {
		type: API_ACTIONS.resetPasswordSuccess,
		data,
	};
};
export const resetPasswordFailed = (data) => {
	return {
		type: API_ACTIONS.resetPasswordFailed,
		data,
	};
};
export const resetPassword = (payload) => (dispatch, getState) => {
	dispatch(resetPasswordStarted());
	let form = new FormData();
	apiService
		.get(
			`${ACTION_ROUTES.authRoute}/resetpassword&email=${payload}`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			// console.log(response);
			if (response.success) {
				Swal.fire({
					title: "Email Sent",
					text: "An email with a confirmation link has been sent your email address",
					icon: "success",
					confirmButtonColor: "#3085d6",
					confirmButtonText: "OK",
				}).then((result) => {
					if (result.isConfirmed) {
						localStorage.clear();
						window.location.href = process.env.REACT_APP_APP_URL;
					}
				});
			} else {
				toast.error(response.error);
				dispatch(resetPasswordFailed(response.error));
			}
		})
		.catch((err) => {
			dispatch(resetPasswordFailed(err));
		});
};

export const authenticateReset =
	(code, handleReset) => (dispatch, getState) => {
		const authFailed = (error) => {
			Swal.fire({
				title: "Authentication Failed",
				text: error,
				icon: "warning",
				confirmButtonColor: "#3085d6",
				confirmButtonText: "OK",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = process.env.REACT_APP_APP_URL;
				}
			});
		};
		apiService
			.post(`${ACTION_ROUTES.registerRoute}/reset_auth&code=${code}`)
			.then((response) => {
				if (response.error) {
					authFailed(response.error);
				} else {
					toast.success("Authentication Successfull");
					handleReset(response.customer_id);
				}
			})
			.catch((err) => {
				authFailed(err);
			});
	};

export const PasswordReset = (form) => (dispatch, getState) => {
	// console.log(form);
	apiService
		.post(
			`${ACTION_ROUTES.registerRoute}/changepassword`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			if (response.error) {
				toast.error(response.error);
			} else {
				Swal.fire({
					title: "Changed Password Successfully!",
					text: "Try login with new password!",
					icon: "success",
					confirmButtonColor: "#3085d6",
					confirmButtonText: "OK",
				}).then((result) => {
					if (result.isConfirmed) {
						localStorage.clear();
						window.location.href = process.env.REACT_APP_APP_URL;
					}
				});
			}
		})
		.catch((err) => {
			toast.error("Reset Failed Please Retry");
		});
};

//#endregion reset password action

//#region  changePassword

export const changePasswordStarted = (data) => {
	return {
		type: API_ACTIONS.changePasswordStarted,
		data,
	};
};
export const changePasswordSuccess = (data) => {
	return {
		type: API_ACTIONS.changePasswordSuccess,
		data,
	};
};
export const changePasswordFailed = (data) => {
	return {
		type: API_ACTIONS.changePasswordFailed,
		data,
	};
};
export const changePassword = (form, handleSuccess) => (dispatch, getState) => {
	dispatch(changePasswordStarted());

	apiService
		.post(
			`${ACTION_ROUTES.registerRoute}/changepassword_userlogin`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			if (response.error) {
				dispatch(changePasswordFailed(response.error));
			} else {
				toast.success("changed password successfully");
				dispatch(changePasswordSuccess(response));
				handleSuccess();
			}
		})
		.catch((err) => {
			dispatch(changePasswordFailed(err));
		});
};

//#endregion change password

//#region resend password

export const resendAuthCode = (form, handleSend) => (dispatch, getState) => {
	apiService
		.post(
			`${ACTION_ROUTES.resetRegisterRoute}`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			if (response.error) {
				handleSend("codefailure");
			} else {
				handleSend("codesuccess");
			}
		})
		.catch((err) => {
			handleSend("codefailure");
		});
};

//#endregion resend password

//#region resend password

export const activateMailStarted = (data) => {
	return {
		type: API_ACTIONS.activateMailStarted,
		data,
	};
};
export const activateMailSuccess = (data) => {
	return {
		type: API_ACTIONS.activateMailSuccess,
		data,
	};
};
export const activateMailFailed = (data) => {
	return {
		type: API_ACTIONS.activateMailFailed,
		data,
	};
};
export const activateMail = (code, handleSend) => (dispatch, getState) => {
	dispatch(activateMailStarted());
	apiService
		.post(`${ACTION_ROUTES.registerRoute}/auth&code=${code}`)
		.then((response) => {
			if (response.error) {
				dispatch(activateMailFailed(response));
				handleSend("failure");
			} else {
				dispatch(activateMailSuccess(response));
				handleSend("success");
			}
		})
		.catch((err) => {
			dispatch(activateMailFailed(err));
		});
};

//#endregion resend password

//#region Blog Post list fetch area
export const getBlogList = (id) => (dispatch, getState) => {
	dispatch(getBlogListStarted());
	apiService
		.get(
			id !== undefined
				? `${ACTION_ROUTES.BlogPost}&category_id=${id}&page=1`
				: `${ACTION_ROUTES.BlogPost}&&page=1`
		)
		.then((response) => {
			if (response.error) {
				dispatch(getBlogListFailed(response.error));
			} else {
				dispatch(getBlogListSuccess({ ...response, pageno: 1 }));
			}
		})
		.catch((err) => {
			dispatch(getBlogListFailed(err));
		});
};

export const updateBlogList = (id, page) => (dispatch, getState) => {
	// console.log("id,type", id, page);
	dispatch(updateBlogListStarted());
	apiService
		.get(
			id !== undefined
				? `${ACTION_ROUTES.BlogPost}&category_id=${id}&page=${page}`
				: `${ACTION_ROUTES.BlogPost}&page=${page}`
		)
		.then((response) => {
			if (response.error) {
				dispatch(updateBlogListFailed(response.error));
			} else {
				let state = getState();
				// console.log(",here", response);
				let existingBlogs = state.blogdata.blogpostData.data;
				// console.log("exblogs".existingBlogs);
				let arraydata = response.data;
				let finalData = existingBlogs.concat(arraydata);
				// console.log("finaldata", finalData);
				dispatch(
					updateBlogListSuccess({
						total: response.total,
						limit: response.limit,
						data: finalData,
						pageno: page,
					})
				);
			}
		})
		.catch((err) => {
			dispatch(updateBlogListFailed(err));
		});
};

export const getBlogListStarted = (data) => {
	return {
		type: API_ACTIONS.getBlogListStarted,
		data,
	};
};
export const getBlogListSuccess = (data) => {
	return {
		type: API_ACTIONS.getBlogListSuccess,
		data,
	};
};
export const getBlogListFailed = (data) => {
	return {
		type: API_ACTIONS.getBlogListFailed,
		data,
	};
};

export const updateBlogListStarted = (data) => {
	return {
		type: API_ACTIONS.updateBlogListStarted,
		data,
	};
};
export const updateBlogListSuccess = (data) => {
	return {
		type: API_ACTIONS.updateBlogListSuccess,
		data,
	};
};
export const updateBlogListFailed = (data) => {
	return {
		type: API_ACTIONS.updateBlogListFailed,
		data,
	};
};
//#endregion Blog Post list fetch area

//#region blog details fetch

export const getBlogDetails = (id) => (dispatch, getState) => {
	dispatch(getBlogDetailsStarted());
	apiService
		.get(`${ACTION_ROUTES.BlogDetails}/post_details&seo=${id}`)
		.then((response) => {
			dispatch(getBlogDetailsSuccess(response));
		})
		.catch((err) => {
			dispatch(getBlogDetailsFailed(err));
		});
};
export const getBlogDetailsStarted = (data) => {
	return {
		type: API_ACTIONS.getBlogDetailsStarted,
		data,
	};
};
export const getBlogDetailsSuccess = (data) => {
	return {
		type: API_ACTIONS.getBlogDetailsSuccess,
		data,
	};
};
export const getBlogDetailsFailed = (data) => {
	return {
		type: API_ACTIONS.getBlogDetailsFailed,
		data,
	};
};

//#endregion

//#region Wishlist Post fetch area
export const getWishList = (page) => (dispatch, getState) => {
	dispatch(getWishlistPostStarted());
	let form = new FormData();
	form.append("customer_id", localStorage.getItem("customer_id"));
	form.append("page", page);
	apiService
		.post(ACTION_ROUTES.WishlistPost, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				dispatch(getWishlistPostFailed(response.error));
			} else {
				if (page === 1) {
					dispatch(getWishlistPostSuccess({ ...response, pageno: page }));
				} else {
					let state = getState();
					let existingList = state.wishlistpost.data.data;
					let arraydata = response.data;
					let finalData = existingList.concat(arraydata);
					dispatch(
						getWishlistPostSuccess({
							total: response.total,
							limit: response.limit,
							data: finalData,
							pageno: page,
						})
					);
				}
			}
		})
		.catch((err) => {
			dispatch(getWishlistPostFailed(err));
		});
};
export const getWishlistPostStarted = (data) => {
	return {
		type: API_ACTIONS.getWishlistPostStarted,
		data,
	};
};
export const getWishlistPostSuccess = (data) => {
	return {
		type: API_ACTIONS.getWishlistPostSuccess,
		data,
	};
};
export const getWishlistPostFailed = (data) => {
	return {
		type: API_ACTIONS.getWishlistPostFailed,
		data,
	};
};
export const updateWishList = (card_id) => (dispatch, getState) => {
	let form = new FormData();
	form.append("customer_id", localStorage.getItem("customer_id"));
	form.append("card_id", card_id);
	apiService
		.post(ACTION_ROUTES.WishlistDelete, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.success) {
				toast.error("Removed Card from wishlist");
				dispatch(getWishList(1));
			} else {
				toast.error("Failed to add to to wishlist");
			}
		});
};

export const addWishList = (card_id) => (dispatch, getState) => {
	const isLogin = localStorage.getItem("isLogin");
	if (isLogin) {
		let form = new FormData();
		form.append("customer_id", localStorage.getItem("customer_id"));
		form.append("card_id", card_id);
		apiService
			.post(ACTION_ROUTES.WishlistAdd, form, {}, "multipart/form-data")
			.then((response) => {
				if (response.success) {
					toast.success("Added to wishlist");
					dispatch(getWishList(1));
				} else {
					toast.error("Failed to add to to wishlist");
				}
			});
	} else {
		toast.error("Please login before you add a card to Wishlist");
	}
};

//#endregion Wishlist Post fetch area

//#region Pricing Pan fetch area
export const getPricePlan = () => (dispatch, getState) => {
	const isLogin = localStorage.getItem("isLogin");
	dispatch(getPricingPlanStarted());
	apiService
		.get(
			isLogin
				? `${ACTION_ROUTES.pricingPlan}&customer_id=${localStorage.getItem(
						"customer_id"
				  )}`
				: ACTION_ROUTES.pricingPlan
		)
		.then((response) => {
			dispatch(getPricingPlanSuccess(response));
		})
		.catch((err) => {
			dispatch(getPricingPlanFailed(err));
		});
};
export const getPricingPlanStarted = (data) => {
	return {
		type: API_ACTIONS.getPricingPlanStarted,
		data,
	};
};
export const getPricingPlanSuccess = (data) => {
	return {
		type: API_ACTIONS.getPricingPlanSuccess,
		data,
	};
};
export const getPricingPlanFailed = (data) => {
	return {
		type: API_ACTIONS.getPricingPlanFailed,
		data,
	};
};
//#endregion Pricing Pan fetch area

//#region Blog Post list fetch area
export const getCardsList = (id, page) => (dispatch, getState) => {
	if (page === 1) {
		dispatch(getCardsListStarted());
		apiService
			.get(`${ACTION_ROUTES.cardUrl}&category_id=${id}&page=${page}`)
			.then((response) => {
				if (response.error) {
					dispatch(getCardsListFailed(response.error));
				} else {
					dispatch(getCardsListSuccess({ ...response, pageno: page }));
				}
			})
			.catch((err) => {
				dispatch(getCardsListFailed(err));
			});
	} else {
		dispatch(updateCardsListStarted());
		apiService
			.get(`${ACTION_ROUTES.cardUrl}&category_id=${id}&page=${page}`)
			.then((response) => {
				if (response.error) {
					dispatch(updateCardsListFailed(response.error));
				} else {
					let state = getState();
					let existingCards = state.subcategory.cardsData.data;
					let arraydata = response.data;
					let finalData = existingCards.concat(arraydata);
					dispatch(
						updateCardsListSuccess({
							total: response.total,
							limit: response.limit,
							data: finalData,
							pageno: page,
						})
					);
				}
			})
			.catch((err) => {
				dispatch(updateCardsListFailed(err));
			});
	}
};
export const getCardsListStarted = (data) => {
	return {
		type: API_ACTIONS.getCardsListStarted,
		data,
	};
};
export const getCardsListSuccess = (data) => {
	return {
		type: API_ACTIONS.getCardsListSuccess,
		data,
	};
};
export const getCardsListFailed = (data) => {
	return {
		type: API_ACTIONS.getCardsListFailed,
		data,
	};
};
//#endregion

//#region Blog Post list fetch area
export const searchandFilterCards = (term, page) => (dispatch, getState) => {
	// console.log(term, page, "thispage");
	if (page === 1) {
		dispatch(searchandFilterCardsStarted());
		apiService
			.get(
				`${ACTION_ROUTES.cardUrl}&page=${page}${
					term.search !== undefined && term.filter !== undefined
						? `&search=${term.search}&filter=${term.filter}`
						: term.filter !== undefined
						? `&filter=${term.filter}`
						: `&search=${term.search}`
				}`
			)
			.then((response) => {
				if (response.error) {
					dispatch(searchandFilterCardsFailed(response.error));
				} else {
					dispatch(searchandFilterCardsSuccess({ ...response, pageno: page }));
				}
			})
			.catch((err) => {
				dispatch(searchandFilterCardsFailed(err));
			});
	} else {
		dispatch(updateSearchCardsListStarted());
		apiService
			.get(
				`${ACTION_ROUTES.cardUrl}&page=${page}${
					term.search && term.filter
						? `&search=${term.search}&filter=${term.filter}`
						: term.filter
						? `&filter=${term.filter}`
						: `&search=${term.search}`
				}`
			)
			.then((response) => {
				if (response.error) {
					dispatch(updateSearchCardsListFailed(response.error));
				} else {
					let state = getState();
					let existingCards = state.subcategory.searchData.data;
					let arraydata = response.data;
					let finalData = existingCards.concat(arraydata);
					dispatch(
						updateSearchCardsListSuccess({
							total: response.total,
							limit: response.limit,
							data: finalData,
							pageno: page,
						})
					);
				}
			})
			.catch((err) => {
				dispatch(updateSearchCardsListFailed(err));
			});
	}
};
export const searchandFilterCardsStarted = (data) => {
	return {
		type: API_ACTIONS.searchandFilterCardsStarted,
		data,
	};
};
export const searchandFilterCardsSuccess = (data) => {
	return {
		type: API_ACTIONS.searchandFilterCardsSuccess,
		data,
	};
};
export const searchandFilterCardsFailed = (data) => {
	return {
		type: API_ACTIONS.searchandFilterCardsFailed,
		data,
	};
};
export const clearSearchList = () => {
	return {
		type: API_ACTIONS.clearSearchList,
	};
};
//#endregion

//#region Blog Post list fetch area
export const getCardDetailsList = (seo) => (dispatch, getState) => {
	dispatch(getCardDetailsListStarted());

	apiService
		.get(`${ACTION_ROUTES.cardDetails}&seo=${seo}`)
		.then((response) => {
			if (response.error) {
				dispatch(getCardDetailsListFailed(response.error));
			} else {
				dispatch(getCardDetailsListSuccess({ data: [{ ...response }] }));
			}
		})
		.catch((err) => {
			dispatch(getCardDetailsListFailed(err));
		});
};

export const getCardDetailsListStarted = (data) => {
	return {
		type: API_ACTIONS.getCardDetailsListStarted,
		data,
	};
};
export const getCardDetailsListSuccess = (data) => {
	return {
		type: API_ACTIONS.getCardDetailsListSuccess,
		data,
	};
};
export const getCardDetailsListFailed = (data) => {
	return {
		type: API_ACTIONS.getCardDetailsListFailed,
		data,
	};
};

export const updateCardsListStarted = (data) => {
	return {
		type: API_ACTIONS.updateCardsListStarted,
		data,
	};
};
export const updateCardsListSuccess = (data) => {
	return {
		type: API_ACTIONS.updateCardsListSuccess,
		data,
	};
};
export const updateCardsListFailed = (data) => {
	return {
		type: API_ACTIONS.updateCardsListFailed,
		data,
	};
};

export const updateSearchCardsListStarted = (data) => {
	return {
		type: API_ACTIONS.updateSearchCardsListStarted,
		data,
	};
};
export const updateSearchCardsListSuccess = (data) => {
	return {
		type: API_ACTIONS.updateSearchCardsListSuccess,
		data,
	};
};
export const updateSearchCardsListFailed = (data) => {
	return {
		type: API_ACTIONS.updateSearchCardsListFailed,
		data,
	};
};
//#endregion Blog Post list fetch area

//#region fetch edit card data

//#region Blog Post list fetch area
export const getEditCardList = (code, customer_id) => (dispatch, getState) => {
	dispatch(getEditCardListStarted());

	apiService
		.get(`${ACTION_ROUTES.editcard}&code=${code}&customer_id=${customer_id}`)
		.then((response) => {
			if (response.error) {
				dispatch(getEditCardListFailed(response.error));
			} else {
				dispatch(getEditCardListSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(getEditCardListFailed(err));
		});
};

export const EditCard = (payload, handleSuccess) => (dispatch, getState) => {
	apiService
		.post(ACTION_ROUTES.updatecard, payload, {}, "multipart/form-data")
		.then((response) => {
			if (response.success) {
				toast.success("Edited card successfully");
				dispatch(FetchOrderCard());
				handleSuccess();
			} else {
				toast.error("Failed to update card");
			}
		})
		.catch((err) => {
			toast.error(err);
		});
};

export const getEditCardListStarted = (data) => {
	return {
		type: API_ACTIONS.getEditCardListStarted,
		data,
	};
};
export const getEditCardListSuccess = (data) => {
	return {
		type: API_ACTIONS.getEditCardListSuccess,
		data,
	};
};
export const getEditCardListFailed = (data) => {
	return {
		type: API_ACTIONS.getEditCardListFailed,
		data,
	};
};

//#endregion

//#region Gif list fetch area
export const getGif = (term) => (dispatch, getState) => {
	dispatch(getGifStarted());
	apiService
		.get(
			`${ACTION_ROUTES.giphyFetch}&q=${term}&limit=25&offset=0&rating=g&lang=en`
		)
		.then((response) => {
			dispatch(getGifSuccess(response.data));
		})
		.catch((err) => {
			dispatch(getGifFailed(err));
		});
};
export const getGifStarted = (data) => {
	return {
		type: API_ACTIONS.getGifStarted,
		data,
	};
};
export const getGifSuccess = (data) => {
	return {
		type: API_ACTIONS.getGifSuccess,
		data,
	};
};
export const getGifFailed = (data) => {
	return {
		type: API_ACTIONS.getGifFailed,
		data,
	};
};
//#endregion Gif fetch area

//#region Sticker fetch area
export const getSticker = (term) => (dispatch, getState) => {
	dispatch(getStickerStarted());
	apiService
		.get(
			`${ACTION_ROUTES.stickerFetch}&q=${term}&limit=25&offset=0&rating=g&lang=en`
		)
		.then((response) => {
			dispatch(getStickerSuccess(response.data));
		})
		.catch((err) => {
			dispatch(getStickerFailed(err));
		});
};
export const getStickerStarted = (data) => {
	return {
		type: API_ACTIONS.getStickerStarted,
		data,
	};
};
export const getStickerSuccess = (data) => {
	return {
		type: API_ACTIONS.getStickerSuccess,
		data,
	};
};
export const getStickerFailed = (data) => {
	return {
		type: API_ACTIONS.getStickerFailed,
		data,
	};
};
//#endregion Sticker fetch area

//#region Blog category fetch area
export const getBlogCategoryList = () => (dispatch, getState) => {
	dispatch(getBlogCategoryListStarted());
	apiService
		.get(ACTION_ROUTES.blogCategory)
		.then((response) => {
			dispatch(getBlogCategoryListSuccess(response));
		})
		.catch((err) => {
			dispatch(getBlogCategoryListFailed(err));
		});
};
export const getBlogCategoryListStarted = (data) => {
	return {
		type: API_ACTIONS.getBlogCategoryListStarted,
		data,
	};
};
export const getBlogCategoryListSuccess = (data) => {
	return {
		type: API_ACTIONS.getBlogCategoryListSuccess,
		data,
	};
};
export const getBlogCategoryListFailed = (data) => {
	return {
		type: API_ACTIONS.getBlogCategoryListFailed,
		data,
	};
};
//#endregion Blog Category fetch area

//#region  Business Plan area
export const B2BStarted = (data) => {
	return {
		type: API_ACTIONS.B2BStarted,
		data,
	};
};
export const B2BSuccess = (data) => {
	return {
		type: API_ACTIONS.B2BSuccess,
		data,
	};
};
export const B2BFailed = (data) => {
	return {
		type: API_ACTIONS.B2BFailed,
		data,
	};
};
export const B2BProcess = (form, handleReset) => (dispatch, getState) => {
	dispatch(B2BStarted());

	apiService
		.post(ACTION_ROUTES.b2bPlan, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				dispatch(B2BFailed(response.error));
			} else {
				toast.success(response.mess);
				handleReset();
				dispatch(B2BSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(B2BFailed(err));
		});
};
//#endregion

//#region order cards
export const OrderCardStarted = (data) => {
	return {
		type: API_ACTIONS.OrderCardStarted,
		data,
	};
};
export const OrderCardSuccess = (data) => {
	return {
		type: API_ACTIONS.OrderCardSuccess,
		data,
	};
};
export const OrderCardFailed = (data) => {
	return {
		type: API_ACTIONS.OrderCardFailed,
		data,
	};
};
export const OrderCard = (form, handleReset) => (dispatch, getState) => {
	dispatch(OrderCardStarted());
	// let form = new FormData();
	// form.append("customer_id", payload.customer_id);
	// form.append("plan_id", payload.plan_id);
	// form.append("card_id", payload.card_id);
	// form.append("senderfname", payload.senderfname);
	// form.append("senderlname", payload.senderlname);
	// form.append("recieverfname", payload.recieverfname);
	// form.append("recieverlname", payload.recieverlname);
	// form.append("deliverytimetype", payload.deliverytimetype);
	// form.append("cardname", payload.cardname);
	// form.append("date", payload.date);
	// form.append("time", payload.time);
	// form.append("timezone", payload.timezone);

	apiService
		.post(ACTION_ROUTES.orderCard, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				// console.log(response.error.recieveremail);
				toast.error(`${response.error.recieveremail}`);
				dispatch(OrderCardFailed(response.error));
			} else {
				// Swal.fire({
				//     title: 'Card Ordered Successfully',
				//     showDenyButton: true,
				//     showCancelButton: false,
				//     confirmButtonText: 'View Card',
				//     denyButtonText: `Close`,
				//   }).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				// if (result.isConfirmed) {
				handleReset();
				window.location.href = `${process.env.REACT_APP_APP_URL}/create/signcard/${response.code}`;
				// } else if (result.isDenied) {
				//     handleReset();
				// }
				//   })
				// dispatch(OrderCardSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(OrderCardFailed(err));
		});
};
//#endregion

//#region Fetch order card
export const FetchOrderCardStarted = (data) => {
	return {
		type: API_ACTIONS.FetchOrderCardStarted,
		data,
	};
};
export const FetchOrderCardSuccess = (data) => {
	return {
		type: API_ACTIONS.FetchOrderCardSuccess,
		data,
	};
};
export const FetchOrderCardFailed = (data) => {
	return {
		type: API_ACTIONS.FetchOrderCardFailed,
		data,
	};
};
export const FetchOrderCard = () => (dispatch, getState) => {
	// let customer_id = localStorage.getItem("id")
	let customer_id = localStorage.getItem("customer_id");
	// console.log("called this");
	dispatch(FetchOrderCardStarted());
	apiService
		.get(`${ACTION_ROUTES.fetchorderCard}&customer_id=${customer_id}`)
		.then((response) => {
			if (response.error) {
				dispatch(FetchOrderCardFailed(response.error));
			} else {
				dispatch(FetchOrderCardSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(FetchOrderCardFailed(err));
		});
};
export const sendOrderCard = (code) => (dispatch, getState) => {
	let customer_id = localStorage.getItem("customer_id");
	apiService
		.get(
			`${ACTION_ROUTES.orderApi}/send_card&customer_id=${customer_id}&code=${code}`
		)
		.then((response) => {
			if (response.error) {
				toast.error("Failed to send Card");
			} else {
				toast.success("Sent Card Successfully");
				dispatch(FetchWishCard(code));
				dispatch(FetchOrderCard())
			}
		})
		.catch((err) => {
			toast.error("Failed please retry");
		});
};
export const orderCardUpdateTime = (code, planid) => {
	let customer_id = localStorage.getItem("customer_id");
	apiService
		.get(
			`${ACTION_ROUTES.orderApi}/getcard_list&code=${code}&customer_id=${customer_id}&plan_id=${planid}`
		)
		.then((response) => {
			if (response.error) {
				toast.error("Failed to Update Card");
			} else {
				toast.success("Updated time Successfully");
			}
		})
		.catch((err) => {
			toast.error("Failed please retry");
		});
};
export const OrdercardPlanUpdate =
	(
		planid,
		code,
		payment_method,
		price,
		currencyType,
		handleSuccess,
		gst,
		handleClose,
		isUpgrade
	) =>
	(dispatch, getState) => {
		let customer_id = localStorage.getItem("customer_id");
		let striperoute = `${ACTION_ROUTES.orderApi}/update_plan&plan_id=${planid}&code=${code}&customer_id=${customer_id}&payment_method=${payment_method}&price=${price}&currency_code=${currencyType}`;
		let billdeskroute = `${ACTION_ROUTES.orderApi}/update_plan&plan_id=${planid}&code=${code}&customer_id=${customer_id}&payment_method=${payment_method}&price=${price}&currency_code=${currencyType}&redirect_url=${process.env.REACT_APP_BILLDESK_REDIRECT}`;
		// console.log(
		// 	planid,
		// 	code,
		// 	payment_method,
		// 	price,
		// 	currencyType,
		// 	handleSuccess
		// );

		if (gst !== "") {
			if (payment_method === "billDesk") {
				billdeskroute = `${billdeskroute}&gst_number=${gst}`;
			} else {
				striperoute = `${striperoute}&gst_number=${gst}`;
			}
		}
		apiService
			.get(payment_method === "billDesk" ? billdeskroute : striperoute)
			.then((response) => {
				if (response.error) {
					toast.error("Failed to Update Plan of Card");
				} else {
					if (isUpgrade) {
						if (response.success) {
							dispatch(FetchOrderCard());
							toast.success("Upgraded Card Successfully");
							handleClose();
						} else {
							toast.error("Failed to Upgrade card please retry!");
						}
					} else {
						if (payment_method === "billDesk") {
							var flow_config = {
								merchantId: "APRECOGRTG",
								bdOrderId: response.bdorderid,
								authToken: response.token,
								childWindow: true, //merchant to refer to api documentation for child window behaviour
							};
							function responseHandler(txn) {
								 console.log(txn);
								if (txn.status === 200) {
									 window.location.href = `${process.env.REACT_APP_APP_URL}/billdeskcompletion/updatecompletion/${txn.txnResponse.transaction_response}`;
								} else {
									toast.error("Payment failed please reorder");
								}
								// handleSuccess();
							}
							var config = {
								responseHandler: responseHandler,
								retryCount: 1,
								merchantLogo: "",
								flowConfig: flow_config,
								flowType: "payments",
							};
							window.loadBillDeskSdk(config);
						} else {
							handleSuccess(response);
						}
					}
				}
			})
			.catch((err) => {
				toast.error("Failed please retry");
			});
	};
//#endregion

//#region Plan History
export const FetchPlanHistoryStarted = (data) => {
	return {
		type: API_ACTIONS.FetchPlanHistoryStarted,
		data,
	};
};
export const FetchPlanHistorySuccess = (data) => {
	return {
		type: API_ACTIONS.FetchPlanHistorySuccess,
		data,
	};
};
export const FetchPlanHistoryFailed = (data) => {
	return {
		type: API_ACTIONS.FetchPlanHistoryFailed,
		data,
	};
};
export const FetchPlanHistory = () => (dispatch, getState) => {
	// let customer_id = localStorage.getItem("id")
	let customer_id = localStorage.getItem("customer_id");
	// console.log("called this");
	dispatch(FetchPlanHistoryStarted());
	apiService
		.get(
			`${ACTION_ROUTES.pricingPlan}/customer_plan_history&customer_id=${customer_id}`
		)
		.then((response) => {
			if (response.error) {
				dispatch(FetchPlanHistoryFailed(response.error));
			} else {
				dispatch(FetchPlanHistorySuccess(response));
			}
		})
		.catch((err) => {
			dispatch(FetchPlanHistoryFailed(err));
		});
};
//#endregion

//#region UserProcess
export const UserStarted = (data) => {
	return {
		type: API_ACTIONS.UserStarted,
		data,
	};
};
export const UserSuccess = (data) => {
	return {
		type: API_ACTIONS.UserSuccess,
		data,
	};
};
export const UserFailed = (data) => {
	return {
		type: API_ACTIONS.UserFailed,
		data,
	};
};
export const UserProcess = (form, handleReset) => (dispatch, getState) => {
	// console.log("User Added Called");
	dispatch(UserStarted());
    console.log('formssssssssss',form);
	apiService
		.post(ACTION_ROUTES.userAdd, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				dispatch(UserFailed(response.error));
			} else {
				toast.success("User Added Successfully!");
				handleReset();
				dispatch(UserSuccess(response));
				dispatch(getUsers(form.customer_id));
			}
		})
		.catch((err) => {
			dispatch(UserFailed(err));
		});
};

export const UpdateUser = (form, handleReset) => (dispatch, getState) => {
	// console.log(form, "data");
	apiService
		.post(ACTION_ROUTES.updateUser, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				toast.error("Failed to add user");
			} else {
				toast.success("User Added Successfully!");
				handleReset();
				dispatch(getUsers(form.customer_id));
			}
		})
		.catch((err) => {
			dispatch(UserFailed(err));
		});
};
//#endregion

//#region All Users fetch area
export const getUsers = (customer_id) => (dispatch, getState) => {
	dispatch(getUsersStarted());
	apiService
		.get(`${ACTION_ROUTES.userList}&customer_id=${customer_id}`)
		.then((response) => {
			dispatch(getUsersSuccess(response));
		})
		.catch((err) => {
			dispatch(getUsersFailed(err));
		});
};

export const DeleteUsers = (user_id) => (dispatch, getState) => {
	apiService
		.get(`${ACTION_ROUTES.userDelete}&user_id=${user_id}`)
		.then((response) => {
			toast.success("User Removed");
			const customer_id = JSON.parse(localStorage.getItem("customer_id"));
			dispatch(getUsers(customer_id));
		})
		.catch((err) => {
			toast.error("Failed to Remove User");
		});
};

export const getUsersStarted = (data) => {
	return {
		type: API_ACTIONS.getUsersStarted,
		data,
	};
};
export const getUsersSuccess = (data) => {
	return {
		type: API_ACTIONS.getUsersSuccess,
		data,
	};
};
export const getUsersFailed = (data) => {
	return {
		type: API_ACTIONS.getUsersFailed,
		data,
	};
};
//#endregion All Users fetch area

//#region fetch wish cards

export const FetchWishCardStarted = (data) => {
	return {
		type: API_ACTIONS.FetchWishCardStarted,
		data,
	};
};
export const FetchWishCardSuccess = (data) => {
	return {
		type: API_ACTIONS.FetchWishCardSuccess,
		data,
	};
};
export const FetchWishCardFailed = (data) => {
	return {
		type: API_ACTIONS.FetchWishCardFailed,
		data,
	};
};
export const FetchWishCard = (code) => (dispatch, getState) => {
	dispatch(FetchWishCardStarted());
	let form = new FormData();
	form.append("code", code);

	apiService
		.post(
			`${ACTION_ROUTES.orderApi}/sing_list_with_card_details`,
			form,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			if (response.error) {
				dispatch(FetchWishCardFailed(response.error));
			} else {
				dispatch(
					FetchWishCardSuccess({
						card_details: response.card_details,
						card: {
							...response.card,
							signatures_left: response.signatures_left,
						},
					})
				);
			}
		})
		.catch((err) => {
			dispatch(FetchWishCardFailed(err));
		});
};

//#endregion

//#region add signature to card

export const SignCardStarted = (data) => {
	return {
		type: API_ACTIONS.SignCardStarted,
		data,
	};
};
export const SignCardSuccess = (data) => {
	return {
		type: API_ACTIONS.SignCardSuccess,
		data,
	};
};
export const SignCardFailed = (data) => {
	return {
		type: API_ACTIONS.FetchWishCardFailed,
		data,
	};
};
export const handleSignCard = (params, handleReset) => (dispatch, getState) => {
	dispatch(SignCardStarted())
	let form = new FormData();
	form.append("code", params.code);
	form.append("message", params.message);
	form.append("fontcolor", params.color);
	form.append("fontsize", params.fontsize);
	form.append("fontfamily", params.fontfamily);
	form.append("media", params.media);
	form.append("signerEmail", params.signerEmail);
	form.append("signerName", params.signerName);
	form.append("logo", "");
	apiService
		.post(`${ACTION_ROUTES.orderApi}/sing_add`, form, {}, "multipart/form-data")
		.then((response) => {
			dispatch(SignCardSuccess())
			if (response.error) {
				toast.error("Failed to sign card please retry!");
			} else {
				toast.success("Card Updated Successfully!");
				localStorage.setItem(
					"signedCard",
					JSON.stringify({ code: params.code, id: response.card_sing_id })
				);
				dispatch(FetchWishCard(params.code));
				setTimeout(() => {
					handleReset();
				}, 1000);
			}
		})
		.catch((err) => {
			dispatch(SignCardFailed);
		});
};

export const handleUpdateCard =
	(params, handleReset) => (dispatch, getState) => {

		let form = new FormData();
		form.append("code", params.code);
		form.append("card_sing_id", params.card_sing_id);
		form.append("message", params.message);
		form.append("fontcolor", params.color);
		form.append("fontsize", params.fontsize);
		form.append("fontfamily", params.fontfamily);
		form.append("media", params.media);
		form.append("signerEmail", params.signerEmail);
		form.append("signerName", params.signerName);
		form.append("logo", "");
		apiService
			.post(
				`${ACTION_ROUTES.orderApi}/sing_update`,
				form,
				{},
				"multipart/form-data"
			)
			.then((response) => {
				if (response.error) {
					toast.error("Failed to Update signature please retry!");
				} else {
					toast.success("Card successfully signed");
					dispatch(FetchWishCard(params.code));
					setTimeout(() => {
						handleReset();
					}, 1000);
				}
			})
			.catch((err) => {
				dispatch(UserFailed(err));
			});
	};

export const DeleteSignature = (form) => (dispatch, getState) => {
	Swal.fire({
		title: "Are you sure you want to delete Signature",
		showDenyButton: true,
		showCancelButton: false,
		confirmButtonText: "Yes",
		denyButtonText: `No`,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			apiService
				.post(ACTION_ROUTES.deleteSign, form, {}, "multipart/form-data")
				.then((response) => {
					if (response.error) {
						toast.error("Failed to delete signature retry");
					} else {
						toast.success("Signature Deleted Successfully");
						localStorage.removeItem("signedCard");
						dispatch(FetchWishCard(form.code));
					}
				})
				.catch((err) => {
					toast.error("Failed to delete signature retry");
				});
		} else if (result.isDenied) {
		}
	});
};

export const handleUser = () => (dispatch, getState) => {
	let isLogin = localStorage.getItem("isLogin");

	if (isLogin) {
		let loginObject = {};

		loginObject["customer_id"] = localStorage.getItem("customer_id");
		loginObject["telephone"] = localStorage.getItem("telephone");
		loginObject["firstname"] = localStorage.getItem("firstname");
		loginObject["lastname"] = localStorage.getItem("lastname");
		loginObject["email"] = localStorage.getItem("email");
		dispatch(loginSuccess(loginObject));
		dispatch(getWishList(1));
	}
};

//#endregion

//#region Timezone fetch area
export const getTimeZoneList = () => (dispatch, getState) => {
	dispatch(getTimeZoneListStarted());
	apiService
		.get(ACTION_ROUTES.timeZoneList)
		.then((response) => {
			dispatch(getTimeZoneListSuccess(response));
		})
		.catch((err) => {
			dispatch(getTimeZoneListFailed(err));
		});
};
export const getTimeZoneListStarted = (data) => {
	return {
		type: API_ACTIONS.getTimeZoneListStarted,
		data,
	};
};
export const getTimeZoneListSuccess = (data) => {
	return {
		type: API_ACTIONS.getTimeZoneListSuccess,
		data,
	};
};
export const getTimeZoneListFailed = (data) => {
	return {
		type: API_ACTIONS.getTimeZoneListFailed,
		data,
	};
};
//#endregion

//#region update user section

export const UpdateProfile = (form) => (dispatch, getState) => {
	apiService
		.post(ACTION_ROUTES.updateProfile, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error || response.warning) {
				toast.error("Failed to Update try again");
			} else {
				toast.success("Profile Updated Successfully!");
				dispatch(loginSuccess(form));
				localStorage.setItem("firstname", form.firstname);
				localStorage.setItem("lastname", form.lastname);
				localStorage.setItem("email", form.email);
				localStorage.setItem("telephone", form.telephone);
			}
		})
		.catch((err) => {
			dispatch(UserFailed(err));
		});
};

//#endregion

//#region contact
export const contactEgroup = (form, handleReset) => (dispatch, getState) => {
	apiService
		.post(ACTION_ROUTES.contactRoute, form, {}, "multipart/form-data")
		.then((response) => {
			if (response.error) {
				toast.error("Failed to send Message please retry");
			} else {
				toast.success(response.mess);
				handleReset();
			}
		})
		.catch((err) => {
			toast.error("Failed to send Message please retry");
		});
};

//#endregion

//#region delete Account

export const deleteAccount = (id) => (dispatch, getState) => {
	var FirstName = localStorage.getItem("firstname");

	Swal.fire({
		title: `Are you sure you want to delete the record "${FirstName}"`,
		text: "All your data will be permanently lost",
		showDenyButton: true,
		showCancelButton: false,
		confirmButtonText: "Yes",
		denyButtonText: `No`,
		showCloseButton: true,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			apiService
				.get(
					`${ACTION_ROUTES.registerRoute}/deleteaccount` + `&customer_id=${id}`
				)
				.then((response) => {
					if (response.error) {
						toast.error("Failed to delete Account");
					} else {
						toast.success("Deleted Account Successfully");
						localStorage.clear();
						window.location.href = process.env.REACT_APP_APP_URL;
					}
				})
				.catch((err) => {
					toast.error("Failed to delete Account");
				});
		} else if (result.isDenied) {
		}
	});
};

export const FetchReceivedCardStarted = (data) => {
	return {
		type: API_ACTIONS.FetchReceivedCardStarted,
		data,
	};
};
export const FetchReceivedCardSuccess = (data) => {
	return {
		type: API_ACTIONS.FetchReceivedCardSuccess,
		data,
	};
};
export const FetchReceivedCardFailed = (data) => {
	return {
		type: API_ACTIONS.FetchReceivedCardFailed,
		data,
	};
};
export const FetchReceivedOrderCard = () => (dispatch, getState) => {
	// let customer_id = localStorage.getItem("id")
	let customer_id = localStorage.getItem("customer_id");
	// console.log("called this");
	dispatch(FetchOrderCardStarted());
	apiService
		.get(
			`${
				ACTION_ROUTES.fetchreceivedCard
			}&customer_id=${customer_id}&page=${"1"}`
		)
		.then((response) => {
			if (response.error) {
				dispatch(FetchOrderCardFailed(response.error));
			} else {
				dispatch(FetchOrderCardSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(FetchOrderCardFailed(err));
		});
};

//#endregion

//#region faq

export const FetchFaqCategoriesStarted = (data) => {
	return {
		type: API_ACTIONS.FetchFaqCategoriesStarted,
		data,
	};
};
export const FetchFaqCategoriesSuccess = (data) => {
	return {
		type: API_ACTIONS.FetchFaqCategoriesSuccess,
		data,
	};
};
export const FetchFaqCategoriesFailed = (data) => {
	return {
		type: API_ACTIONS.FetchFaqCategoriesFailed,
		data,
	};
};
export const FetchFaqCategories = () => (dispatch, getState) => {
	dispatch(FetchFaqCategoriesStarted());
	apiService
		.get(`${ACTION_ROUTES.faqRoute}/category`)
		.then((response) => {
			if (response.error) {
				dispatch(FetchFaqCategoriesFailed(response.error));
			} else {
				dispatch(FetchFaqCategoriesSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(FetchOrderCardFailed(err));
		});
};
//#endregion

//#region faq

export const FetchFaqListStarted = (data) => {
	return {
		type: API_ACTIONS.FetchFaqListStarted,
		data,
	};
};
export const FetchFaqListSuccess = (data) => {
	return {
		type: API_ACTIONS.FetchFaqListSuccess,
		data,
	};
};
export const FetchFaqListFailed = (data) => {
	return {
		type: API_ACTIONS.FetchFaqListFailed,
		data,
	};
};
export const FetchFaqSearchStarted = (data) => {
	return {
		type: API_ACTIONS.FetchFaqSearchStarted,
		data,
	};
};
export const FetchFaqSearchSuccess = (data) => {
	return {
		type: API_ACTIONS.FetchFaqSearchSuccess,
		data,
	};
};
export const FetchFaqSearchFailed = (data) => {
	return {
		type: API_ACTIONS.FetchFaqSearchFailed,
		data,
	};
};

export const ClearFaqList = () => {
	return {
		type: API_ACTIONS.ClearFaqList,
	};
};
export const FetchFaqList = (type, value) => (dispatch, getState) => {
	// let customer_id = localStorage.getItem("id")
	let customer_id = localStorage.getItem("customer_id");
	// console.log("called this");
	if (type === "search") {
		dispatch(FetchFaqSearchStarted());
		apiService
			.get(`${ACTION_ROUTES.faqRoute}&search=${value}`)
			.then((response) => {
				if (response.error) {
					dispatch(FetchFaqSearchFailed(response.error));
				} else {
					dispatch(FetchFaqSearchSuccess(response));
				}
			})
			.catch((err) => {
				dispatch(FetchOrderCardFailed(err));
			});
	} else {
		dispatch(FetchFaqListStarted());
		apiService
			.get(`${ACTION_ROUTES.faqRoute}&category_id=${value}`)
			.then((response) => {
				if (response.error) {
					dispatch(FetchFaqListFailed(response.error));
				} else {
					dispatch(FetchFaqListSuccess(response));
				}
			})
			.catch((err) => {
				dispatch(FetchOrderCardFailed(err));
			});
	}
};
//#endregion

//#region stripe

export const createStripeId =
	(planid, amount, quantity, currencyType, paymentType, gst, handleStripe) =>
	(dispatch, getState) => {
		const isLogin = localStorage.getItem("isLogin");
		if (isLogin) {
			let form = new FormData();
			form.append("customer_id", localStorage.getItem("customer_id"));
			form.append("plan_id", planid);
			form.append("qty", quantity);
			form.append("price", amount);
			form.append("currency_code", currencyType);
			form.append("payment_method", paymentType);
			if (gst !== "") {
				form.append("gst_number", gst);
			}
			apiService
				.post(ACTION_ROUTES.stripeRoute, form, {}, "multipart/form-data")
				.then((response) => {
					if (response.error) {
						toast.error("Failed to initiate Payment");
					} else {
						handleStripe(response);
					}
				})
				.catch((err) => {
					toast.error("Failed to initiate Payment");
				});
		} else {
			toast.error("please login to pay");
		}
	};

export const handleConfirmOrder =
	(orderid, handleSuccess, type, paymentType) => (dispatch, getState) => {
		const isLogin = localStorage.getItem("isLogin");
		if (isLogin) {
			// console.log("order_id", orderid);
			let form = new FormData();
			form.append("order_id", orderid);

			apiService
				.post(
					`${ACTION_ROUTES.pricingPlan}/confirm_order`,
					form,
					{},
					"multipart/form-data"
				)
				.then((response) => {
					if (response.error) {
						toast.error("Failed to Complete Payment");
					} else {
						if (type === "ordercard") {
							let orderdetails = localStorage.getItem("orderdetails");
							let parsedData = JSON.parse(orderdetails);
							if (parsedData.card_image) {
								const storedData = localStorage.getItem("fileData");

								if (storedData) {
									const { base64String, fileType, name } =
										JSON.parse(storedData);

									const byteCharacters = atob(base64String.split(",")[1]);
									const byteArrays = new Uint8Array(byteCharacters.length);

									for (let i = 0; i < byteCharacters.length; i++) {
										byteArrays[i] = byteCharacters.charCodeAt(i);
									}

									const retrievedBlob = new Blob([byteArrays], {
										type: fileType,
									});

									// Create a new File object with a name
									const retrievedFile = new File([retrievedBlob], name, {
										lastModified: new Date().getTime(),
										type: fileType,
									});

									// console.log(retrievedFile); // This should be a new File object
									parsedData["card_image"] = retrievedFile;
								} else {
									delete parsedData["card_image"];
								}
							}
							// console.log(parsedData);
							apiService
								.post(
									ACTION_ROUTES.orderCard,
									parsedData,
									{},
									"multipart/form-data"
								)
								.then((response) => {
									if (response.error) {
										toast.error(`${response.error}`);
									} else {
										// Swal.fire({
										//     title: 'Card Ordered Successfully',
										//     showDenyButton: true,
										//     showCancelButton: false,
										//     confirmButtonText: 'View Card',
										//     denyButtonText: `Close`,
										//   }).then((result) => {
										//     /* Read more about isConfirmed, isDenied below */
										//     if (result.isConfirmed) {
										// console.log(response)
										// if(paymentType === "billdesk"){
										handleSuccess(response.code);
										// }else{
										// 	window.location.href = `${process.env.REACT_APP_APP_URL}/create/signcard/${response.code}`;
										// }

										localStorage.removeItem("orderdetails");
									}
								});
						} else {
							toast.success("Payment Successfull");
							setTimeout(() => {
								handleSuccess();
							}, 1000);
						}
					}
				})
				.catch((err) => {
					toast.error(
						type === "ordercard" ? "Failed to Order Card" : "Failed to add Plan"
					);
				});
		} else {
			toast.error("Unauthorized Access");
		}
	};

export const handleConfirmUpdate =
	(orderid, handleSuccess) => (dispatch, getState) => {
		const isLogin = localStorage.getItem("isLogin");
		if (isLogin) {
			// console.log("order_id", orderid);
			let form = new FormData();
			form.append("order_id", orderid);
			form.append("customer_id",localStorage.getItem("customer_id"))
			apiService
				.post(
					`${ACTION_ROUTES.orderApi}/confirm_update_order`,
					form,
					{},
					"multipart/form-data"
				)
				.then((response) => {
					if (response.error) {
						toast.error("Failed to Complete Payment");
					} else {
						toast.success("Payment Successfull");
						setTimeout(() => {
							handleSuccess();
						}, 1000);
					}
				})
				.catch((err) => {
					toast.error("Failed with unexpected error");
				});
		} else {
			toast.error("Unauthorized Access");
		}
	};

//#endregion

export const FetchBgImageList = () => (dispatch, getState) => {
	dispatch(getBgListListStarted());
	apiService
		.get(`${ACTION_ROUTES.bgImageAPI}`)
		.then((response) => {
			if (response.error) {
				dispatch(getBgListListFailed(response.error));
			} else {
				dispatch(getBgListListSuccess(response));
			}
		})
		.catch((err) => {
			dispatch(getBgListListFailed(err));
		});
};
export const getBgListListStarted = (data) => {
	return {
		type: API_ACTIONS.getBgListListStarted,
		data,
	};
};
export const getBgListListSuccess = (data) => {
	return {
		type: API_ACTIONS.getBgListListSuccess,
		data,
	};
};
export const getBgListListFailed = (data) => {
	return {
		type: API_ACTIONS.getBgListListFailed,
		data,
	};
};
export const SubmitBackground =
	(id, code, handleClose) => (dispatch, getState) => {
		apiService
			.get(`${ACTION_ROUTES.SetImageAPI}&code=${code}&background_id=${id}`)
			.then((response) => {
				if (response.error) {
					toast.error("Failed to set Background Try Again");
				} else {
					toast.success("Background Updated");
					dispatch(FetchWishCard(code));
					handleClose();
				}
			})
			.catch((err) => {
				toast.error("Failed to send Message please retry");
			});
	};
//#region Fetch Privacy Policy

export const getPrivacyPolicy = () => (dispatch, getState) => {
	dispatch(getPrivacyPolicyStarted());
	apiService
		.get(`${ACTION_ROUTES.InformationAPI}privacy`)
		.then((response) => {
			dispatch(getPrivacyPolicySuccess(response));
		})
		.catch((err) => {
			dispatch(getPrivacyPolicyFailed(err));
		});
};

export const getPrivacyPolicyStarted = (data) => {
	return {
		type: API_ACTIONS.getPrivacyPolicyStarted,
		data,
	};
};
export const getPrivacyPolicySuccess = (data) => {
	return {
		type: API_ACTIONS.getPrivacyPolicySuccess,
		data,
	};
};
export const getPrivacyPolicyFailed = (data) => {
	return {
		type: API_ACTIONS.getPrivacyPolicyFailed,
		data,
	};
};

//#endregion

//#region Fetch Privacy Policy

export const getTerms = () => (dispatch, getState) => {
	dispatch(getTermsConditionStarted());
	apiService
		.get(`${ACTION_ROUTES.InformationAPI}terms`)
		.then((response) => {
			dispatch(getTermsConditionSuccess(response));
		})
		.catch((err) => {
			dispatch(getTermsConditionFailed(err));
		});
};

export const getTermsConditionStarted = (data) => {
	return {
		type: API_ACTIONS.getTermsConditionStarted,
		data,
	};
};
export const getTermsConditionSuccess = (data) => {
	return {
		type: API_ACTIONS.getTermsConditionSuccess,
		data,
	};
};
export const getTermsConditionFailed = (data) => {
	return {
		type: API_ACTIONS.getTermsConditionFailed,
		data,
	};
};

//#endregion

//#region Fetch About Us

export const getAboutUs = () => (dispatch, getState) => {
	dispatch(getAboutUsStarted());
	apiService
		.get(`${ACTION_ROUTES.InformationAPI}about_us`)
		.then((response) => {
			dispatch(getAboutUsSuccess(response));
		})
		.catch((err) => {
			dispatch(getAboutUsFailed(err));
		});
};
export const getAboutUsStarted = (data) => {
	return {
		type: API_ACTIONS.getAboutUsStarted,
		data,
	};
};
export const getAboutUsSuccess = (data) => {
	return {
		type: API_ACTIONS.getAboutUsSuccess,
		data,
	};
};
export const getAboutUsFailed = (data) => {
	return {
		type: API_ACTIONS.getAboutUsFailed,
		data,
	};
};
export const handleBilldesk =
	(
		planid,
		amount,
		quantity,
		currencyType,
		paymentType,
		redirect_url,
		cancel_url,
		gst,
		handleSuccess
	) =>
	(dispatch, getState) => {
		const isLogin = localStorage.getItem("isLogin");
		if (isLogin) {
			let form = new FormData();
			form.append("customer_id", localStorage.getItem("customer_id"));
			form.append("plan_id", planid);
			form.append("qty", quantity);
			form.append("price", amount);
			form.append("currency_code", currencyType);
			form.append("payment_method", paymentType);
			form.append("redirect_url", process.env.REACT_APP_BILLDESK_REDIRECT);
			if (gst !== "") {
				form.append("gst_number", gst);
			}
			apiService
				.post(
					`https://oc.egroupgreeting.com/index.php?route=api/plan/add_plan`,
					form,
					{},
					"multipart/form-data"
				)
				.then((response) => {
					if (response.error) {
						toast.error("Failed to initiate Payment");
					} else {
						// console.log(response);

						var flow_config = {
							merchantId: "APRECOGRTG",
							bdOrderId: response.bdorderid,
							authToken: response.token,
							childWindow: true, //merchant to refer to api documentation for child window behaviour
						};
						function responseHandler(txn) {
							// console.log(txn);

							if (txn.status === 200) {
								window.location.href = `${process.env.REACT_APP_APP_URL}/billdeskcompletion/${redirect_url}/${txn.txnResponse.transaction_response}`;
							} else {
								toast.error("Payment failed please reorder");
							}
						}
						var config = {
							responseHandler: responseHandler,
							retryCount: 1,
							merchantLogo: "",

							flowConfig: flow_config,

							flowType: "payments",
						};
						window.loadBillDeskSdk(config);
					}
				})
				.catch((err) => {
					toast.error("Failed to initiate Payment");
				});
		} else {
			toast.error("Please login and Retry");
		}
	};
export const getPaymentStarted = (data) => {
	return {
		type: API_ACTIONS.getPaymentStarted,
		data,
	};
};
export const getPaymentSuccess = (data) => {
	return {
		type: API_ACTIONS.getPaymentSuccess,
		data,
	};
};
export const getPaymentFailed = (data) => {
	return {
		type: API_ACTIONS.getPaymentFailed,
		data,
	};
};
export const handlePaymentDecode = (id) => (dispatch, getState) => {
	dispatch(getPaymentStarted());
	let bdform = new FormData();
	bdform.append("res_code", id);
	apiService
		.post(
			`https://oc.egroupgreeting.com/index.php?route=api/plan/billdesk_decode`,
			bdform,
			{},
			"multipart/form-data"
		)
		.then((response) => {
			dispatch(getPaymentSuccess(response));
		})
		.catch((err) => {
			dispatch(getPaymentFailed(err));
		});
};

export const fetchStripeStarted = (data) => {
	return {
		type: API_ACTIONS.getStripeDetailsStarted,
		data,
	};
};
export const fetchStripeSuccess = (data) => {
	return {
		type: API_ACTIONS.getStripeDetailsSuccess,
		data,
	};
};
export const fetchStripeFailed = (data) => {
	return {
		type: API_ACTIONS.getStripeDetailsFailed,
		data,
	};
};

export const fetchStripeOrder =
	(id, type, handleSuccess) => (dispatch, getState) => {
		dispatch(fetchStripeStarted());
		apiService
			.get(`${ACTION_ROUTES.orderDetails}&order_id=${id}`)
			.then((response) => {
				if (response.error) {
					toast.error("Failed to fetch ordeR Details");
				} else {
					if (type === "plan") {
						dispatch(handleConfirmOrder(id, () => {}, "plancard", ""));
					} else if (type === "card") {
						dispatch(handleConfirmOrder(id, handleSuccess, "ordercard", ""));
					} else {
						dispatch(handleConfirmUpdate(id, () => {}));
					}
					dispatch(fetchStripeSuccess(response));
				}
			})
			.catch((err) => {
				dispatch(fetchStripeFailed(err));
				toast.error("Failed to send Message please retry");
			});
	};

export const MultiInvite =
	(commaSeparatedEmails, code, customer_id, nameslist) =>
	(dispatch, getState) => {
		let form = new FormData();
		form.append("emails", commaSeparatedEmails);
		form.append("code", code);
		form.append("customer_id", customer_id);
		form.append("names", nameslist);

		apiService
			.post(ACTION_ROUTES.MultiInvite, form, {}, "multipart/form-data")
			.then((response) => {
				if (response.error || response.warning) {
					toast.error("Failed Please Try Again");
				} else {
					toast.success("Invitation Sent Successfully!");
				}
			})
			.catch((err) => {
				// console.log("Invitation Error");
			});
	};

	export const getPdf = (code) => (dispatch, getState) => {
        dispatch(getPdfStarted());
        apiService.get(ACTION_ROUTES.pdfUrl + '&code=' + code)
            .then((response) => {
                console.log('response.success', response)
                if (response.success === 1) {
                    // Redirect to a new tab when response.success is 1
                    window.open(response.file_link, '_blank');
                    // window.location.href = `https://oc.egroupgreeting.com/image/card_pdf/${code}-code.pdf`;
                } else {
                    // Reload the current page when response.success is not 1
                    window.location.reload();
                }
            })
            .catch((err) => {
                dispatch(getPdfFailed("Failed to create PDF"));
            });
    };

	export const getPdfStarted = (data) => {
		return {
			type: API_ACTIONS.getPdfStarted,
			data,
		};
	};
	export const getPdfSuccess = (data) => {
		return {
			type: API_ACTIONS.getPdfSuccess,
			data,
		};
	};
	export const getPdfFailed = (data) => {
		return {
			type: API_ACTIONS.getPdfFailed,
			data,
		};
	};
	export const clearPDF = (data) => {
		return {
			type: API_ACTIONS.clearPDF,
			data,
		};
	};