import React, { useState } from 'react'
import ImageCrop from '../../componants/ImageCropper';
import defaultimage from '../../assets/img/default-image.jpg';

function ImageUpload({ selectedImage, setSelectedImage }) {
    return (

        <div>
            {selectedImage ? (
                <div>
                    <img
                        alt="not found"
                        width={"250px"}
                        src={URL.createObjectURL(selectedImage)}
                    />
                    <br />
                    {/* <button onClick={() => setSelectedImage(null)}>Remove</button> */}
                </div>
            ): (
                <img
                    alt="not found"
                    width={"250px"}
                    src={defaultimage}
                />
            )}
            <ImageCrop cropData={selectedImage} setCropData={setSelectedImage} id="bannerImage" />


            {/*       
     

      <br />
      <br />
      
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          console.log(event.target.files[0]);
          setSelectedImage(event.target.files[0]);
        }}
      /> */}
        </div>
    )
}

export default ImageUpload