import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchStripeOrder, fetchStripeSuccess, handleConfirmOrder, handleConfirmUpdate } from '../actions';
import LoadingSpinner from './LoadingSpinner';
import PaymentSuccess from '../pages/PaymentMessage/PaymentSuccess';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';

function SuccessHandler() {
    const {orderid,type} = useParams();
    const [cardId, setcardId] = useState(null);
	const [secondsRemaining, setSecondsRemaining] = useState(10);
    const dispatch = useDispatch();
    const paymentData = useSelector((state) => state.billdeskReducer);
    const handleSuccess = (id) => {
      setcardId(id);
    };
    useEffect(()=>{
        if(orderid !== undefined ){
          if(type ==="card"){
            dispatch(fetchStripeOrder(orderid,"card",handleSuccess));
          }
          else if(type === "plan"){
            dispatch(fetchStripeOrder(orderid,"plan",()=>{}));
          }else{
			dispatch(fetchStripeOrder(orderid,"update",()=>{}))
          }
        }
    },[orderid])

	useEffect(() => {
		if(cardId !== null){
			if (secondsRemaining > 0) {
				const timerId = setInterval(() => {
				  setSecondsRemaining(prevSeconds => prevSeconds - 1);
				}, 1000);
		  
				return () => {
				  clearInterval(timerId);
				};
			  } else {
				window.location.href = `${process.env.REACT_APP_APP_URL}/create/signcard/${cardId}`
			  }
		}
		
	  }, [secondsRemaining,cardId]);

  return (
    <>
    {
      paymentData.isLoadingStripe?(
      <LoadingSpinner />
      ):(
        <>
        {paymentData.stripeData !== null ? (<div className="payment-reciept-parent">
						<div className="reciept">
							<Row className="mb-4">
								<PaymentSuccess />
							</Row>
							<Row>
								<Col>Purpose of payment</Col>
								<Col>{"Online Payment"}</Col>
							</Row>
							<Row>
								<Col>Payment Mode</Col>
								<Col>{paymentData.stripeData.payment_method}</Col>
							</Row>
							<Row>
								<Col>Amount</Col>
								<Col>{paymentData.stripeData.price}</Col>
							</Row>
							<Row>
								<Col>Date</Col>
								<Col>{paymentData.stripeData.date_added}</Col>
							</Row>
							<Row>
								<Col>Plan Id</Col>
								<Col>{paymentData.stripeData.plan_id}</Col>
							</Row>

							<Row>
								<Col>Order Id</Col>
								<Col>{paymentData.stripeData.id}</Col>
							</Row>

							<Row>
              {
									type === "update" ? (<NavLink exact="true" to="/profile#dashboard">
									Go to Profile
								</NavLink>):(<NavLink exact="true" to="/">
									Go to Home
								</NavLink>)
								}
                {
									cardId!==null ?(
										<NavLink exact="true" to={`/create/signcard/${cardId}`}>
									Go to Card
								</NavLink>
									):null
								}
							</Row>
							{
								cardId!== null && (
<Row>
							<p>Redirecting in {secondsRemaining} seconds...</p>
							</Row>
								)
							}
						</div>
					</div>):<LoadingSpinner />
}
        </>

      )
    }
    </>
   
  )
}

export default SuccessHandler