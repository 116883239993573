import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleConfirmUpdate } from '../actions';
import LoadingSpinner from './LoadingSpinner';

function UpdateSuccessHandler() {
    const {orderid} = useParams();
    const dispatch = useDispatch();
    const handleSuccess = () =>{
    
      window.location.href = `${process.env.REACT_APP_APP_URL}/profile`;

}
    useEffect(()=>{
        if(orderid !== undefined){

           dispatch(handleConfirmUpdate(orderid,handleSuccess))
        
        }
    },[orderid])
  return (
    <LoadingSpinner />
  )
}

export default UpdateSuccessHandler