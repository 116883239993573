import { API_ACTIONS } from "../constants/actionConstant";

const initialState = {
    isLoading: false,
    err: null,
    data:[],
};

const userReducer = (state = initialState,action = {}) => {
    switch(action.type){
        case API_ACTIONS.getUsersStarted:
            return {
                ...state,
                isLoading:true
            }
        case API_ACTIONS.getUsersSuccess:
            return {
                ...state,
                isLoading:false,
                data:action.data
            }
        case API_ACTIONS.getUsersFailed:
            return {
                ...state,
                isLoading:false,
                err:action.data
            }
            default:
                return state;
    }
};

export default userReducer;