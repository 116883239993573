import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { Col, Row } from 'react-bootstrap';
import SvgIcon from './SvgIcon';
import { useEffect, useState } from 'react';
import { facebookLogin, googleLogin, registerAction } from '../actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GoogleLogin } from "react-google-login";
import * as FaIcons from "react-icons/fa";
import { useRef } from 'react';

const Register = ({ show, onHide, handleSignIn, handleLogin, isCard = false }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const authData = useSelector((state) => state.authentication)
    const dispatch = useDispatch();
    const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

    const signupSchema = yup.object().shape({
        firstname: yup
            .string()
            .min(3, "First name should contain at least 3 characters")
            .required("Please Enter your First Name"),
        lastname: yup
            .string()
            .required("Please Enter your Last Name"),
        email: yup
            .string().email()
            .required("Please Enter Email"),
        password: yup.string()
            .required('No password provided.')
            .min(6, 'Password is too short - should be 6 chars minimum.')
            .matches(/[a-zA-Z0-9]/, 'Password can only contain Latin letters.'),
        confirm: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        telephone: yup.string().nullable().optional().matches(rePhoneNumber, 'Phone number must be exactly 10 digits and contain only numbers')

    });

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        mode: "all",
        resolver: yupResolver(signupSchema)
    });

    function getUserData() {
        window.FB.api('/me', { fields: 'first_name,last_name,email' }, (response) => {
            responseFacebook(response);
        });
    }

    const FacebookButton = () => {
        const onLoginClick = () => {
            window.FB.login((response) => {
                if (response.authResponse) {
                    document.getElementById('loginBtn').style.display = 'none';
                    getUserData();
                }
            }, { scope: 'email,public_profile', return_scopes: true });
        };

        useEffect(() => {
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: facebookAppId,
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v2.2'
                });
            };
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }, []);

        return (
            <>
                <div className="col-12">
                    <button onClick={onLoginClick} id="loginBtn" className="facebook-login-btn"><FaIcons.FaFacebookF /> Facebook</button>
                </div>
            </>
        );
    };

    const handleSuccess = () => {
        reset();
        handleLogin();
    }
    const handleClose = () =>{
        reset();
        onHide();
    }
    let facebookAppId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const responseMessage = (response) => {
        // console.log(response);
        let id = response.profileObj.googleId;
        let firstname = response.profileObj.givenName;
        let lastname = response.profileObj.familyName;
        let email = response.profileObj.email;
        dispatch(
            googleLogin({
                id: id,
                firstname: firstname,
                lastname: lastname,
                email: email,
            },handleClose)
        );
    };
    const errorMessage = (error) => {
        // console.log(error);
    };
    const responseFacebook = (response) => {
        if (response) {
            dispatch(facebookLogin(response,handleClose));
        }
    };


    const onSubmit = (data) => {
        dispatch(registerAction(data, handleSuccess))

    };

    const labelHtml = 'Accept <a target="_blank" href="/terms-of-service">Terms & Conditions</a>';
    const phoneInputRef = useRef();

    // Check if the phone number input field has a value before validating it
    const validateTelephone = () => {
      if (phoneInputRef.current.value) {
        // Validate the phone number using the yup validation schema
        yup.string().matches(rePhoneNumber, "Phone number must be exactly 10 digits and contain only numbers").validate(phoneInputRef.current.value);
      }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <Modal show={show} size="md" onHide={onHide} centered className='auth-model'>
            <Modal.Body className="section-title-area sign-in-modal sign-up-modal">
                <button type="button" class="popup-btn-close btn-close" onClick={onHide} aria-label="Close"></button>

                <h2 className="text-center mb-5"><span className="title-design position-relative">Sign</span> Up</h2>
                {
                    !isCard && (
                        <>

                            <Form className="sign-up-form" onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control aria-invalid={errors.firstname ? "true" : "false"} type="text" placeholder="Enter Name" {...register("firstname", { required: true })} />
                                            <p className='form-error-message mt-1'>{errors.firstname?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 modal-form" controlId="formBasicEmail">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control aria-invalid={errors.lastname ? "true" : "false"} type="text" placeholder="Enter Name" {...register("lastname", { required: true })} />
                                            <p className='form-error-message mt-1'>{errors.lastname?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control aria-invalid={errors.email ? "true" : "false"} type="email" placeholder="Enter Email" {...register("email", { required: true })} />
                                            <p className='form-error-message mt-1'>{errors.email?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                                            <Form.Label>Telephone</Form.Label>
                                            <input
                                                className='form-control'
                                                aria-invalid={errors.telephone ? "true" : "false"}
                                                type="tel"
                                                maxLength={10}
                                                minLength={10}
                                                placeholder="Phone number"
                                                {...register("telephone")}
                                                ref={phoneInputRef}
                                                onBlur={validateTelephone}
                                            />
                                            <p className='form-error-message mt-1'>{errors.telephone?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <div className='position-relative'>
                                            <Form.Control aria-invalid={errors.password ? "true" : "false"} type={passwordVisible ? 'text' : 'password'} placeholder="Enter Password" {...register("password", { required: true })} />
                                            {!passwordVisible ? (
                                                <FaIcons.FaEyeSlash
                                                    className='eyeview'
                                                    onClick={togglePasswordVisibility}
                                                />
                                            ) : (
                                                <FaIcons.FaEye
                                                    className='eyeview'
                                                    onClick={togglePasswordVisibility}
                                                />
                                            )}
                                            </div>
                                            <p className='form-error-message mt-1'>{errors.password?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                                            <Form.Label>Confirm Password</Form.Label>
                                            <Form.Control aria-invalid={errors.confirm ? "true" : "false"} type={passwordVisible ? 'text' : 'password'}  placeholder="Confirm Password" {...register("confirm", { required: true })} />
                                            <p className='form-error-message mt-1'>{errors.confirm?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form>
                                            <div key={`default-checkbox`} className="checkbox-label">
                                                <Form.Check // prettier-ignore
                                                    type={'checkbox'}
                                                    id={`default-checkbox`}
                                                    label={<span dangerouslySetInnerHTML={{ __html: labelHtml }} />}
                                                    checked
                                                />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" className="w-100 mt-3" >
                                    Signup now
                                </Button>
                            </Form>
                            <div className="sign-up-sec mt-4 d-flex align-items-center justify-content-between">
                                <p className="account-link mb-0">Already have an account?</p>
                                <button className="btn outline-btn" onClick={handleSignIn}>Login</button>
                            </div> </>
                    )
                }
                <Row className="mt-4">
                    <div className="col-12">
                        <div class="centered-text-line">
                            <p>or signup with</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Google"
                            className="google-login-btn"
                            onSuccess={responseMessage}
                            onFailure={errorMessage}
                            cookiePolicy={"single_host_origin"}
                        />
                    </div>
                    <div className="col-md-6">
                        <FacebookButton />
                    </div>
                </Row>
            </Modal.Body>
        </Modal>

    );
}

export default Register;