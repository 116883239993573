export const API_ACTIONS = {
    getCategoryListStarted:"GET_CATEGORY_LIST_STARTED",
    getCategoryListSuccess:"GET_CATEGORY_LIST_SUCCESS",
    getCategoryListFailed:"GET_CATEGORY_LIST_FAILED",
    
    getSubCategoryListStarted:"GET_SUBCATEGORY_LIST_STARTED",
    getSubCategoryListSuccess:"GET_SUBCATEGORY_LIST_SUCCESS",
    getSubCategoryListFailed:"GET_SUBCATEGORY_LIST_FAILED",

    getBlogListStarted:"GET_BLOGPOST_LIST_STARTED",
    getBlogListSuccess:"GET_BLOGPOST_LIST_SUCCESS",
    getBlogListFailed:"GET_BLOGPOST_LIST_FAILED",

    updateBlogListStarted:"UPDATE_BLOGPOST_LIST_STARTED",
    updateBlogListSuccess:"UPDATE_BLOGPOST_LIST_SUCCESS",
    updateBlogListFailed:"UPDATE_BLOGPOST_LIST_FAILED",

    searchandFilterCardsStarted:"SEARCH_FILTER_CARDS_STARTED",
    searchandFilterCardsSuccess:"SEARCH_FILTER_CARDS_SUCCESS",
    searchandFilterCardsFailed:"SEARCH_FILTER_CARDS_FAILED",

    getBlogDetailsStarted:"GET_BLOG_DETAILS_STARTED",
    getBlogDetailsSuccess:"GET_BLOG_DETAILS_SUCCESS",
    getBlogDetailsFailed:"GET_BLOG_DETAILS_FAILED",

    getEditCardListStarted:"GET_EDIT_CARD_STARTED",
    getEditCardListSuccess:"GET_EDIT_CARD_SUCCESS",
    getEditCardListFailed:"GET_EDIT_CARD_FAILED",

    loginStarted:"LOGIN_STARTED",
    loginSuccess:"LOGIN_SUCCESS",
    loginLoad:"LOGIN_LOAD",
    loginFailed:"LOGIN_FAILED",

    facebookLoginStarted:"facebookLogin_STARTED",
    facebookLoginSuccess:"facebookLogin_SUCCESS",
    facebookLoginFailed:"facebookLogin_FAILED",
    
    googleLoginStarted:"googleLogin_STARTED",
    googleLoginSuccess:"googleLogin_SUCCESS",
    googleLoginFailed:"googleLogin_FAILED",
    

    registerStarted:"REGISTER_STARTED",
    registerSuccess:"REGISTER_SUCCESS",
    registerFailed:"REGISTER_FAILED",

    changePasswordStarted:"CHANGEPASSWORD_STARTED",
    changePasswordSuccess:"CHANGEPASSWORD_SUCCESS",
    changePasswordFailed:"CHANGEPASSWORD_FAILED",

    resetPasswordStarted:"RESETPASSWORD_STARTED",
    resetPasswordSuccess:"RESETPASSWORD_SUCCESS",
    resetPasswordFailed:"RESETPASSWORD_FAILED",
    
    activateMailStarted:"ACTIVATEMAIL_STARTED",
    activateMailSuccess:"ACTIVATEMAIL_SUCCESS",
    activateMailFailed:"ACTIVATEMAIL_FAILED",

    getWishlistPostStarted:"GET_WISHLISTPOST_LIST_STARTED",
    getWishlistPostSuccess:"GET_WISHLISTPOST_LIST_SUCCESS",
    getWishlistPostFailed:"GET_WISHLISTPOST_LIST_FAILED",
    updateWishList:"GET_WISHLISTPOST_UPDATE",

    getPricingPlanStarted:"GET_PRICING_PLAN_STARTED",
    getPricingPlanSuccess:"GET_PRICING_PLAN_SUCCESS",
    getPricingPlanFailed:"GET_PRICING_PLAN_FAILED",

    getUsersStarted:"GET_ALL_USERS_STARTED",
    getUsersSuccess:"GET_ALL_USERS_SUCCESS",
    getUsersFailed:"GET_ALL_USERS_FAILED",

    getCardsListStarted:"GET_CARDS_LIST_STARTED",
    getCardsListSuccess:"GET_CARDS_LIST_SUCCESS",
    getCardsListFailed:"GET_CARDS_LIST_FAILED",

    getCardDetailsListStarted:"GET_CARDS_DETAILS_LIST_STARTED",
    getCardDetailsListSuccess:"GET_CARDS_DETAILS_LIST_SUCCESS",
    getCardDetailsListFailed:"GET_CARDS_DETAILS_LIST_FAILED",

    updateCardsListStarted:"UPDATE_CARDS_LIST_STARTED",
    updateCardsListSuccess:"UPDATE_CARDS_LIST_SUCCESS",
    updateCardsListFailed:"UPDATE_CARDS_LIST_FAILED",

    updateSearchCardsListStarted:"UPDATE_CARDS_SEARCH_LIST_STARTED",
    updateSearchCardsListSuccess:"UPDATE_CARDS_SEARCH_LIST_SUCCESS",
    updateSearchCardsListFailed:"UPDATE_CARDS_SEARCH_LIST_FAILED",

    clearSearchList:'CLEAR_SEARCH_LIST',

    getGifStarted:"GET_GIF_STARTED",
    getGifSuccess:"GET_GIF_SUCCESS",
    getGifFailed:"GET_GIF_FAILED",

    getStickerStarted:"GET_STICKER_STARTED",
    getStickerSuccess:"GET_STICKER_SUCCESS",
    getStickerFailed:"GET_STICKER_FAILED",
    
    getBlogCategoryListStarted:"GET_BLOG_CATEGORY_LIST_STARTED",
    getBlogCategoryListSuccess:"GET_BLOG_CATEGORY_LIST_SUCCESS",
    getBlogCategoryListFailed:"GET_BLOG_CATEGORY_LIST_FAILED",

    B2BStarted:"B2B_STARTED",
    B2BSuccess:"B2B_SUCCESS",
    B2BFailed:"B2B_FAILED",

    UserStarted:"USER_STARTED",
    UserSuccess:"USER_SUCCESS",
    UserFailed:"USER_FAILED",

    OrderCardStarted:"ORDER_CARD_STARTED",
    OrderCardSuccess:"ORDER_CARD_SUCCESS",
    OrderCardFailed:"ORDER_CARD_FAILED",

    FetchOrderCardStarted:"FETCH_ORDER_CARD_STARTED",
    FetchOrderCardSuccess:"FETCH_ORDER_CARD_SUCCESS",
    FetchOrderCardFailed:"FETCH_ORDER_CARD_FAILED",

    FetchReceivedCardStarted:"FETCH_ORDER_CARD_STARTED",
    FetchReceivedCardSuccess:"FETCH_ORDER_CARD_SUCCESS",
    FetchReceivedCardFailed:"FETCH_ORDER_CARD_FAILED",

    FetchPlanHistoryStarted:"FETCH_PLAN_HISTORY_STARTED",
    FetchPlanHistorySuccess:"FETCH_PLAN_HISTORY_SUCCESS",
    FetchPlanHistoryFailed:"FETCH_PLAN_HISTORY_FAILED",

    
    FetchWishCardStarted:"FETCH_WISH_CARD_STARTED",
    FetchWishCardSuccess:"FETCH_WISH_CARD_SUCCESS",
    FetchWishCardFailed:"FETCH_WISH_CARD_FAILED",

    FetchFaqCategoriesStarted:"FETCH_FAQ_CATEGORY_STARTED",
    FetchFaqCategoriesSuccess:"FETCH_FAQ_CATEGORY_SUCCESS",
    FetchFaqCategoriesFailed:"FETCH_FAQ_CATEGORY_FAILED",

    FetchFaqListStarted:"FETCH_FAQ_LIST_STARTED",
    FetchFaqListSuccess:"FETCH_FAQ_LIST_SUCCESS",
    FetchFaqListFailed:"FETCH_FAQ_LIST_FAILED",
    ClearFaqList:"CLEAR_FAQ_LIST",
    
    FetchFaqSearchStarted:"FETCH_FAQ_SEARCH_STARTED",
    FetchFaqSearchSuccess:"FETCH_FAQ_SEARCH_SUCCESS",
    FetchFaqSearchFailed:"FETCH_FAQ_SEARCH_FAILED",

    
    getTimeZoneListStarted:"GET_TIMEZONE_LIST_STARTED",
    getTimeZoneListSuccess:"GET_TIMEZONE_LIST_SUCCESS",
    getTimeZoneListFailed:"GET_TIMEZONE_LIST_FAILED",


    getBgListListStarted:"GET_BG_LIST_STARTED",
    getBgListListSuccess:"GET_BG_LIST_SUCCESS",
    getBgListListFailed:"GET_BG_LIST_FAILED",

    getPrivacyPolicyStarted:"GET_PRIVACY_LIST_STARTED",
    getPrivacyPolicySuccess:"GET_PRIVACY_LIST_SUCCESS",
    getPrivacyPolicyFailed:"GET_PRIVACY_LIST_FAILED",

    getTermsConditionStarted:"GET_TERMS_CONDITION_STARTED",
    getTermsConditionSuccess:"GET_TERMS_CONDITION_SUCCESS",
    getTermsConditionFailed:"GET_TERMS_CONDITION_FAILED",
    
    getAboutUsStarted:"GET_ABOUT_US_STARTED",
    getAboutUsSuccess:"GET_ABOUT_US_SUCCESS",
    getAboutUsFailed:"GET_ABOUT_US_FAILED",

    getPaymentStarted:"GET_PAYMENT_STARTED",
    getPaymentSuccess:"GET_PAYMENT_SUCCESS",
    getPaymentFailed:"GET_PAYMENT_FAILED",

    getStripeDetailsStarted:"GET_STRIPE_DETAILS_STARTED",
    getStripeDetailsSuccess:"GET_STRIPE_DETAILS_SUCCESS",
    getStripeDetailsFailed:"GET_STRIPE_DETAILS_FAILED",

    setCountryCode: "SET_COUNTRY_CODE",

    SignCardStarted:"SIGN_CARD_STARTED",
    SignCardSuccess:"SIGN_CARD__SUCCESS",
    SignCardFailed:"SIGN_CARD__FAILED",

    getPdfStarted:"GET_PDF_STARTED",
    getPdfSuccess:"GET_PDF_SUCCESS",
    getPdfFailed:"GET_PDF_FAILED",
    clearPDF:'CLEAR_PDF'

}