import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchStripeOrder, handleConfirmOrder } from '../actions';
import LoadingSpinner from './LoadingSpinner';

function OrderSuccessHandler() {
    const {orderid} = useParams();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(orderid !== undefined){

          //  dispatch(handleConfirmOrder(orderid,null,"ordercard"))
          dispatch(fetchStripeOrder(orderid,"card"));
        }
    },[orderid])
  return (
    <LoadingSpinner />
  )
}

export default OrderSuccessHandler