import React, { useEffect, useState } from "react";
import { createPortal } from 'react-dom';
import { Container, Nav, Navbar, Dropdown } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { NavLink } from 'react-router-dom';
import Login from "./Login";
import Register from "./Register";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./ChangePassword";
import { Logout, handleUser } from "../actions";
import ForgotPassword from "./ForgotPassword";
import * as FaIcons from "react-icons/fa";
import ResendEmail from "./ResendEmail";

const Header = () => {
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showRegisterPopup, setShowRegisterPopup] = useState(false);
    const [authcardtype, setAuthCardType] = useState("none")
    const authData = useSelector((state) => state.authentication);
    const dispatch = useDispatch();
 let isLogin = useSelector(state => state.authentication.isAuthenticated);
    useEffect(() => {
        function HeaderScroll() {
            const sticky = document.querySelector('.header-menu > .navbar');
            const scroll = window.scrollY;

            if (scroll >= 100) {
                sticky.classList.add('bg-white');
            } else {
                sticky.classList.remove('bg-white');
            }
        }

        window.addEventListener('scroll', HeaderScroll);

        return () => {
            window.removeEventListener('scroll', HeaderScroll);
        };
    }, []);

    const handleAuthCard = (type) => {
        if (type === authcardtype) {
            setAuthCardType("none");
        } else {
            setAuthCardType(type)
        }
    }

    const handleLogout = () => {
        dispatch(Logout());
    }

    const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
        setIsActive(current => !current);
    };

    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    return (
        <>
            <header className="header-menu">
                <Navbar expand="lg" fixed="top" className="px-4">
                    <Container>
                        <div className="logo_sec">
                            <Navbar.Brand href="/"><img src={logo} alt="Egroup Greetings" /></Navbar.Brand>
                            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                            <div className="icon-area d-lg-none d-block" onClick={handleClick}><FaIcons.FaBars  /></div>
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
                            <Nav className="m-auto">
                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/">Home</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/category">Greet occasions</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/business">Business</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/pricing">Pricing</NavLink>
                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/blog">Blog</NavLink>
                                {/* {authData.isAuthenticated && (<NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/contact">Contact Us</NavLink>)} */}
                            </Nav>
                        </Navbar.Collapse>
                        <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block right-header-menu">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
                                {
                                    authData.isAuthenticated ?
                                        (
                                            <>
                                                <Dropdown 
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                                show={showDropdown}
                                                className="menu-profile-dropdown">
                                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                    <FaIcons.FaUser className="me-1" />  My Account
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="/profile#dashboard">Dashboard</Dropdown.Item>
                                                        <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                                                        <Dropdown.Item href="/wishlist">Favourite cards</Dropdown.Item>
                                                        <Dropdown.Item href="/contact">Contact Us</Dropdown.Item>
                                                        {/* <Dropdown.Item aria-current="page" onClick={() => handleAuthCard("changepassword")}>Change Password</Dropdown.Item> */}
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => handleLogout()}>Log Out</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                        ) : (<>
                                            <li className="nav-item">
                                                <button className="btn btn-sm header-btn me-3" aria-current="page" onClick={() => handleAuthCard("signin")}>
                                                    Sign In
                                                </button>
                                            </li>

                                            <li className="nav-item">
                                                <button className="btn btn-sm header-btn outline" aria-current="page" onClick={() => handleAuthCard("signup")}>
                                                    Sign Up
                                                </button>
                                            </li>
                                        </>)
                                }

                            </ul>
                        </Navbar.Collapse>
                        <div className="d-lg-none d-block">
                            <div className={isActive ? 'nav-bar show' : 'nav-bar'}>
                                                                
                                <div className="close-area d-lg-none d-block" onClick={handleClick}><FaIcons.FaTimes /></div>
                                <Nav className="me-auto">
                                    <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" to="/" onClick={handleClick}>Home</NavLink>
                                    {authData.isAuthenticated && <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} onClick={handleClick} exact="true" to="/wishlist">Favourite cards</NavLink>}
                                    <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" onClick={handleClick} to="/">Create</NavLink>
                                    <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" onClick={handleClick} to="/blog">Blog</NavLink>
                                    <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" onClick={handleClick} to="/pricing">Pricing</NavLink>
                                    <NavLink className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')} exact="true" onClick={handleClick} to="/contact">Contact Us</NavLink>
                                </Nav>
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 text-center responsive-nav">
                                    {
                                        authData.isAuthenticated ?
                                            (
                                                <>
                                                    <Dropdown className="menu-profile-dropdown">
                                                        <Dropdown.Toggle variant="link" id="dropdown-basic" className="responsive_dropdown">
                                                            <div className="account-name">
                                                                <FaIcons.FaUser className="me-1 d-none d-lg-block" />
                                                                <p className="m-0">My Account</p>
                                                                <FaIcons.FaChevronDown className="me-1 d-lg-none d-block" />
                                                            </div>

                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                        <Dropdown.Item onClick={handleClick} href="/profile#dashboard">Dashboard</Dropdown.Item>
                                                        <Dropdown.Item onClick={handleClick} href="/profile">Profile</Dropdown.Item>
                                                        <Dropdown.Item onClick={handleClick} href="/wishlist">Favourite cards</Dropdown.Item>
                                                        <Dropdown.Item onClick={handleClick} href="/contact">Contact Us</Dropdown.Item>
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item onClick={() => handleLogout()}>Log Out</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </>
                                            ) : (<>
                                                <li className="nav-item">
                                                    <button className="btn btn-sm header-btn me-3" aria-current="page" onClick={() => { handleAuthCard("signin"); handleClick(); }}>
                                                        Sign In
                                                    </button>
                                                </li>

                                                <li className="nav-item">
                                                    <button className="btn btn-sm header-btn outline" aria-current="page" onClick={() => { handleAuthCard("signup"); handleClick(); }}>
                                                        Sign Up
                                                    </button>
                                                </li>
                                            </>)
                                    }

                                </ul>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </header>
            {
                authcardtype === "signin" && (
                    <>
                        {createPortal(
                            <Login show={true} onHide={() => handleAuthCard("signin")} handleForgot={() => handleAuthCard("Forgot")} handleSignUp={() => handleAuthCard("signup")} handleReset={()=>handleAuthCard("resendemail")} />,
                            document.getElementById("modal-root")

                        )}
                    </>
                )
            }
            {
                authcardtype === "Forgot" && (
                    <ForgotPassword show={true} onHide={() => handleAuthCard("Forgot")} />
                )
            }
            {
                authcardtype === "signup" && (
                    <Register show={true} onHide={() => handleAuthCard("signup")} handleLogin={() => handleAuthCard("signin")} handleSignIn={() => handleAuthCard("signin")} />
                )
            }
            {
                authcardtype === "changepassword" && (
                    <ChangePassword show={true} onHide={() => handleAuthCard("changepassword")} />
                )
            }
            {
                authcardtype === "resendemail" && (
                    <ResendEmail show={true} onHide={()=>handleAuthCard("resendemail")}/>
                )
            }

        </>
    );
}

export default Header;