import React from 'react';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import SvgIcon from './SvgIcon';
import { PasswordReset, authenticateReset, changePassword, login, resetPassword } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const { authcode } = useParams();
    const dispatch = useDispatch();
    const authData = useSelector((state) => state.authentication)
    const [customerid,setCustomerId] = useState("none")
    const [password, setPassword] = useState('');
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    // console.log("inresetpassword")
    useEffect(()=>{
        // console.log(authcode)
        if(authcode !== undefined && customerid === "none"){
           dispatch(authenticateReset(authcode,handleReset))
        }
    },[authcode,customerid])
    const handleReset = (id) =>{
        setCustomerId(id)
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const passwordRegex = /[a-zA-Z0-9]/;
        
        if(password === ""){
           toast.error("please fill password field")
        }else{
            if(!password.match(passwordRegex)){
                toast.error("not a valid password")
            }else{
                let confirmPassword = document.getElementById("confirm").value;
                if(confirmPassword === password){
                     dispatch(PasswordReset({customer_id:customerid,password:password,confirm:password}))
                }else{
                    toast.error("Passwords doesnt match")
                }
            }
            
        } 
        // handle login logic here
    };

//     useEffect(() => {
//    if(authData.isResetPassword){
//     onHide();
//    }
//     }, [authData.isResetPassword])
    

    return (
        <Modal show={customerid !== "none"}  centered>
            <Modal.Body className="section-title-area sign-in-modal">
                <h2 className="text-center mb-5"><span className="title-design position-relative">Change</span>Password</h2>
                <Form >
                    <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Your Password" value={password} onChange={handlePasswordChange} required/>
                    </Form.Group>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control id="confirm" type="password" placeholder="Enter Your Password"   required/>
                    </Form.Group>
                    <Form.Group className="mb-3 modal-form" controlId="formBasicCheckbox">
                   {authData.loginError !== "none" && (<Form.Label>{authData.loginError}</Form.Label>) } 
                    </Form.Group>
                    
                    <Button variant="primary" type="button" className="w-100 mt-3" onClick={(e)=>handleSubmit(e)}>
                        Submit
                    </Button>
                </Form>

            </Modal.Body>
        </Modal>
    );
}

export default ResetPassword;